import './index.css';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {
    cloneObject,
    generateTag,
    getFormData,
    inputChangedHandler,
    prepareFormData,
    validateForm,
} from '../../shared/utility'
import ColorQuestion from "../../components/ui/ColorQuestion";
import Question from "../../components/ui/Question";
import Option from "../../components/ui/Option";
import ColorHelper from "../../shared/ColorHelper";
import VehicleHelper from "../../shared/VehicleHelper";
import CircularProgressButton from "../../components/ui/CircularProgress";
import useLocalState from '../../hooks/useLocalState'
import {HeapTagClassName, Steps, TitleStatuses} from '../../constants'
import {prepareAnswersFromState} from '../../store/actions/qualifyVehicleQuestions'

let formData = {
    controls: {
        titleStatus: {
            label: 'What type of title does the vehicle have?',
            value: '',
            values: [
                {key: TitleStatuses.Clean, value: TitleStatuses.Clean},
                {key: TitleStatuses.Salvage, value: TitleStatuses.Salvage},
                {key: TitleStatuses.Rebuilt, value: TitleStatuses.Rebuilt},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            valid: false,
            touched: false,
            helperText: null,
            type: "dropdown",
            group: 'vehicle',
        },
        styleId: {
            label: 'Trim',
            value: '',
            validation: {
                presence: {allowEmpty: false}
            },
            values: [],
            valid: true,
            touched: false,
            visible: false,
            group: 'vehicle',
        },
        exteriorColor: {
            label: 'Exterior Color',
            value: '',
            validation: {
                presence: {allowEmpty: false}
            },
            valid: true,
            touched: false,
            visible: false,
            group: 'vehicle',
        },
        interiorColor: {
            label: 'Interior Color',
            value: '',
            validation: {
                presence: {allowEmpty: false}
            },
            isLeather: false,
            valid: true,
            touched: false,
            visible: false,
            group: 'vehicle',
        },
        options: {
            value: [],
            valid: true,
            touched: false,
            visible: false,
            group: 'vehicle',
        },
        engine: {
            label: 'Engine',
            value: '',
            validation: {
                presence: {allowEmpty: true}
            },
            valid: true,
            touched: false,
            group: 'vehicle',
        },
        transmission: {
            label: 'Transmission',
            value: '',
            validation: {
                presence: {allowEmpty: false}
            },
            values: [],
            valid: true,
            touched: false,
            group: 'vehicle',
        },
        drivetrain: {
            label: 'Drivetrain',
            value: '',
            validation: {
                presence: {allowEmpty: true}
            },
            valid: true,
            touched: false,
            group: 'vehicle',
        },
        style: {
            label: 'Style',
            value: '',
            validation: {
                presence: {allowEmpty: true}
            },
            valid: true,
            touched: false,
            group: 'vehicle',
        },
        series: {
            label: 'Series',
            value: '',
            validation: {
                presence: {allowEmpty: true}
            },
            valid: true,
            touched: false,
            group: 'vehicle',
        },

        owner: {
            label: 'Is the vehicle owned outright?',
            value: null,
            defaultValue: null,
            values: [
                {key: "yes", value: "Yes"},
                {key: "no", value: "No"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            valid: false,
            touched: false,
            helperText: null,
            group: 'ownership',
        },
        ownerNo: {
            label: 'What best describes the vehicle\'s ownership?',
            value: null,
            defaultValue: null,
            values: [
                {key: "loan", value: "Loan"},
                {key: "lien", value: "Lien"},
                {key: "lease", value: "Lease"}],
            validation: {
                presence: {allowEmpty: false}
            },
            valid: false,
            touched: false,
            helperText: null,
            group: 'ownership',
            skip: true,
        },
        sellerPossession: {
            label: 'Is the title in the seller\'s possession?',
            value: null,
            defaultValue: null,
            values: [
                {key: "yes", value: "Yes"},
                {key: "no", value: "No"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            valid: false,
            touched: false,
            helperText: null,
            group: 'ownership',
        },
        anyAccidents: {
            label: 'Has the vehicle ever been in an accident?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            valid: false,
            touched: false,
            helperText: null,
            group: 'accidents',
        },
        minorAccidents: {
            label: 'How many minor accidents?',
            value: null,
            defaultValue: null,
            values: [
                {key: "0", value: "0"},
                {key: "1", value: "1"},
                {key: "2", value: "2"},
                {key: "3", value: "3+"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            valid: false,
            touched: false,
            defaultHelperText: "Fender benders, bumper damage, etc. to exterior panels only.",
            helperText: null,
            group: 'accidents',
        },
        majorAccidents: {
            label: 'How many major accidents?',
            value: null,
            defaultValue: null,
            values: [
                {key: "0", value: "0"},
                {key: "1", value: "1"},
                {key: "2", value: "2"},
                {key: "3", value: "3+"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            valid: false,
            touched: false,
            defaultHelperText: "Multiple exterior panels damaged, engine or suspension damage, airbags deployed, etc.",
            helperText: null,
            group: 'accidents',
        },
        frameDamage: {
            label: 'Was the frame damaged?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            valid: false,
            touched: false,
            helperText: null,
        },
    },
    loading: false,
    valid: false,
    touched: false,
    generalError: null
}

const VehicleDetails = (props) => {
    formData.controls.styleId.values = props.vehicle.trims.map(el => {
        return {key: el.styleId, value: el.trim}
    })

    const initialData = prepareFormData(formData, {})
    // const [formState, setFormState] = useState(initialData);
    const [formState, setFormState] = useLocalState(Steps.History, initialData);

    const [isSubmitEnabled, setSubmitEnabled] = useState(false)
    const [exteriorColors, setExteriorColors] = useState([])
    const [interiorColors, setInteriorColors] = useState([])
    const [images, setImages] = useState([])

    const checkOption = (selectedOption) => {
        const optionsData = cloneObject(optionsField.value)
        const recursiveCheck = (optionsData, selectedOption) => {

            for (const option of optionsData) {
                if (option.id === selectedOption.id) {
                    if (!option.disabled) {
                        option.checked = !selectedOption.checked
                        return true
                    }
                }
                if (option.children.length) {
                    const result = recursiveCheck(option.children, selectedOption)
                    if (result) {
                        const hasCheckedItem = option.children.find(child => child.checked === true)
                        option.checked = hasCheckedItem ? true : false
                    }
                }
            }
            return false
        }
        recursiveCheck(optionsData, selectedOption)
        inputChangedHandler(formState, setFormState, optionsData, optionsField.key)
    }

    const prefillOptions = (trimData, chromeOptions) => {
        const optionsData = cloneObject(trimData.options)
        if (!chromeOptions) {
            return optionsData
        }
        const recursiveCheck = (optionsData, chromeOptions) => {
            for (const option of optionsData) {
                if (chromeOptions.find(el => el.id === option.id)) {
                    option.checked = true
                }
                if (option.children.length) {
                    const result = recursiveCheck(option.children, chromeOptions)
                    if (result) {
                        const hasCheckedItem = option.children.find(child => child.checked === true)
                        option.checked = hasCheckedItem ? true : false
                    }
                }
            }
            return false
        }
        recursiveCheck(optionsData, chromeOptions)
        return optionsData
    }

    const getExteriorColors = (styleId) => {
        if (styleId) {
            const trim = props.vehicle.trims.find(el => el.styleId === styleId)
            if (trim) return cloneObject(trim.exteriorColors)
        }
        return []
    }
    const getInteriorColors = (styleId) => {
        if (styleId) {
            const trim = props.vehicle.trims.find(el => el.styleId === styleId)
            if (trim) return cloneObject(trim.interiorColors)
        }
        return []
    }

    const getColor = (colors, color) => {
        if (!color) return null
        if (colors.length === 0 && color) return color.name
        if (colors.length === 1) return colors[0].name
        const colorData = colors.find(el => el.name === color.name)
        return colorData ? colorData.name : null
    }

    useEffect(() => {
        const styleId = props.vehicle?.styleId ?? styleIdField.value
        const setData = () => {
            setDataForStyleId(styleId)
        }
        setData() //.catch(console.error)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.vehicle.styleId])

    useEffect(() => {
        styleIdField.value && setDataForStyleId(styleIdField.value)
    }, [props.vehicle])

    const getExcludeFields = () => {
        const excludeFields = []
        if (formState.controls.transmission.values.length < 2) {
            excludeFields.push('transmission')
        }
        excludeFields.push('engine')
        excludeFields.push('series')
        if (formState.controls.drivetrain.value === null) {
            excludeFields.push('drivetrain')
        }
        if (formState.controls.owner.value !== 'yes') {
            excludeFields.push('sellerPossession')
        }
        if (formState.controls.owner.value !== 'no') {
            excludeFields.push('ownerNo')
        }
        if (formState.controls.anyAccidents.value !== 'yes') {
            excludeFields.push('minorAccidents')
            excludeFields.push('majorAccidents')
            excludeFields.push('frameDamage')
        }
        if (+formState.controls.majorAccidents.value < 1) {
            excludeFields.push('frameDamage')
        }
        if (exteriorColors.length < 2){
            excludeFields.push('exteriorColor')
        }
        if (interiorColors.length < 2){
            excludeFields.push('interiorColor')
        }

        return excludeFields
    }

    const checkForm = () => {
        const excludeFields = getExcludeFields()

        return validateForm(formState, setFormState, excludeFields, false)
    }

    useEffect(() => {
        const progress = countProgress()
        props.setStep(prev => ({...prev, ...progress}))
    }, [formState])

    const countProgress = () => {
        let totalSteps = 1
        let steps = 0
        let fields = []

        const isValid = (field) => {
            return (!field.touched || (field.touched && field.valid))
        }

        fields.push(styleIdField.key)
        if (styleIdField.value !== '' && isValid(styleIdField)) {
            steps++
        }

        if (!styleIdField.value) {
            return {totalSteps, steps, fields, isValid: checkForm()}
        }

        totalSteps++
        fields.push(engineField.key)
        if (engineField.value !== '' && isValid(engineField)) {
            steps++
        }
        if (transmissionField.values.length > 0) {
            totalSteps++
            fields.push(transmissionField.key)
            if (transmissionField.value !== '' && isValid(transmissionField)) {
                steps++
            }
        }
        if (drivetrainField.value !== '' && isValid(drivetrainField)) {
            totalSteps++
            fields.push(drivetrainField.key)
            steps++
        }

        if (exteriorColors.length > 0) {
            totalSteps++
            fields.push(exteriorColorField.key)
            if ((exteriorColorField.value !== '' || exteriorColors.length === 1) && isValid(exteriorColorField)) {
                steps++
            }
        }
        if (interiorColors.length > 0) {
            totalSteps++
            fields.push(interiorColorField.key)
            if ((interiorColorField.value !== '' || interiorColors.length === 1) && isValid(interiorColorField)) {
                steps++
            }
        }

        totalSteps++
        fields.push(titleStatusField.key)
        if (titleStatusField.value !== null && titleStatusField.value !== '') {
            steps++
        }

        totalSteps++
        fields.push(ownerField.key)
        if (ownerField.value) {
            steps++
        }
        if (ownerField.value === 'yes') {
            totalSteps++
            fields.push(sellerPossessionField.key)
            if (sellerPossessionField.value !== null && sellerPossessionField.value !== '') {
                steps++
            }
        }
        if (ownerField.value === 'no') {
            totalSteps++
            fields.push(ownerNoField.key)
            if (ownerNoField.value !== null && ownerNoField.value !== '') {
                steps++
            }
        }

        totalSteps++
        fields.push(anyAccidentsField.key)
        if (anyAccidentsField !== null && anyAccidentsField.value !== null && anyAccidentsField.value !== '' && isValid(anyAccidentsField)) {
            steps++
        }
        if (anyAccidentsField.value === 'yes') {
            totalSteps += 2

            fields.push(majorAccidentsField.key)
            if (majorAccidentsField.value !== null && majorAccidentsField.value !== '' && isValid(majorAccidentsField)) {
                steps++
            }

            fields.push(minorAccidentsField.key)
            if (minorAccidentsField.value !== null && minorAccidentsField.value !== '' && isValid(minorAccidentsField)) {
                steps++
            }

            if(majorAccidentsField.value >= 1) {
                totalSteps++
                fields.push(frameDamageField.key)
                if (frameDamageField.value !== null && frameDamageField.value !== '' && isValid(frameDamageField)) {
                    steps++
                }
            }
        }

        return {totalSteps, steps, fields, isValid: checkForm()}
    }

    useEffect(() => {
        setSubmitEnabled(checkForm())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState])

    const getTitleStatus = (value) => {
        if (!value) {
            return value
        }

        if (['Salvage/Rebuilt', 'Rebuilt/Salvage'].includes(value)) {
            return TitleStatuses.Salvage
        }

        if ([TitleStatuses.Clean, TitleStatuses.PartsOnly, TitleStatuses.Salvage, TitleStatuses.Rebuilt].includes(value)) {
            return value
        }

        return TitleStatuses.Unknown
    }

    const getDefaultImageUrl = () => {
        const minStyleId = Math.min(...props.vehicle.trims.map(el => {
            return el.styleId;
        }));
        let images = [];
        if (minStyleId) {
            let exteriorColor = null
            const trimData = props.vehicle.trims.find(el => Number(el.styleId) === Number(minStyleId));
            if (trimData.exteriorColors.length === 1) {
                exteriorColor = trimData.exteriorColors[0]
            }
            images = VehicleHelper.getVehicleImages(props.vehicle.images, exteriorColor);
        }

        return images.length ? images[0]['@href']?.replace(/http/i, 'https') : null;
    }

    const setDataForStyleId = (styleId) => {
        let state = {exteriorColor: '', interiorColor: ''}
        state.mileage = props.vehicle.mileage

        if (styleId) {
            const exteriorColors = getExteriorColors(styleId)
            const interiorColors = getInteriorColors(styleId)

            state.styleId = styleId

            state.exteriorColor = getColor(exteriorColors, props.vehicle.exteriorColor) ?? formState.controls.exteriorColor.value
            state.interiorColor = getColor(interiorColors, props.vehicle.interiorColor) ?? formState.controls.interiorColor.value

            state.chromeDataOptions = props.vehicle.chromeDataOptions

            state.titleStatus = getTitleStatus(props.vehicle.titleStatus) ?? formState.controls.titleStatus.value
            state.listedPrice = props.vehicle.listedPrice

            let exteriorColor = props.vehicle.exteriorColor

            const trimData = props.vehicle.trims.find(el => el.styleId === state.styleId)
            if (trimData.exteriorColors.length === 1) {
                state.exteriorColor = trimData.exteriorColors[0].name
                exteriorColor = trimData.exteriorColors[0]
            }
            if (trimData.interiorColors.length === 1) {
                state.interiorColor = trimData.interiorColors[0].name
            }
           const getTrimStyle = () => {
                return props.vehicle.trims.find(el => el.styleId === styleId)
            }
            const selectedTrimData = getTrimStyle()

            const engine = selectedTrimData.engine ? selectedTrimData.engine.split(/,|;/) : null
            state.engine = engine ? engine[0] : null
            state.transmission = selectedTrimData.transmission ? selectedTrimData.transmission : null
            state.drivetrain = selectedTrimData.drivetrain ? selectedTrimData.drivetrain : null
            state.style = selectedTrimData.style ? selectedTrimData.style : null
            state.series = selectedTrimData.series ? selectedTrimData.series : null

            let transmissions = []
            if (selectedTrimData.transmissionData) {
                if (Array.isArray(selectedTrimData.transmissionData)) {
                    selectedTrimData.transmissionData.forEach(el => transmissions.push({key: el.name, value: el.name}))
                }
                else {
                    transmissions.push({key: selectedTrimData.transmissionData.name, value: selectedTrimData.transmissionData.name})
                }
            }
            state.transmission = !transmissions.length && selectedTrimData.transmission ? selectedTrimData.transmission : formState.controls.transmission.value ?? null
            if(transmissions.length === 1 && selectedTrimData.transmission) {
                state.transmission = selectedTrimData.transmission
            }
            formState.controls.transmission.values = transmissions

            setImages(VehicleHelper.getVehicleImages(props.vehicle.images, exteriorColor))
            state.options = prefillOptions(trimData, state.chromeDataOptions)

            setExteriorColors(exteriorColors)
            setInteriorColors(interiorColors)
        } else {
            if (props.vehicle.trims) {
                setImages([])
            }
        }
        setFormState(prepareFormData(formState, state));
    }

    const exteriorColorField = formState.controls.exteriorColor;
    const interiorColorField = formState.controls.interiorColor;
    const optionsField = formState.controls.options;
    const titleStatusField = formState.controls.titleStatus;
    const styleIdField = formState.controls.styleId;
    const engineField = formState.controls.engine;
    const transmissionField = formState.controls.transmission;
    const drivetrainField = formState.controls.drivetrain;

    const ownerField = formState.controls.owner;
    const ownerNoField = formState.controls.ownerNo;
    const anyAccidentsField = formState.controls.anyAccidents;
    const minorAccidentsField = formState.controls.minorAccidents;
    const majorAccidentsField = formState.controls.majorAccidents;
    const sellerPossessionField = formState.controls.sellerPossession;
    const frameDamageField = formState.controls.frameDamage

    const onChangeTrim = (value) => {
        inputChangedHandler(formState, setFormState, value, styleIdField.key)
        if (value) {
            props.onChangeTrim(value)
        }
    }
    const getSelectedOptions = (options) => {
        const selectedOptions = []
        const recursiveFilter = (options) => {
            options.forEach(option => {
                if (option.checked) {
                    selectedOptions.push({
                        id: option.id,
                        name: option.name,
                        disabled: option.disabled,
                        hidden: option.hidden
                    })
                    if (option.children.length) {
                        recursiveFilter(option.children)
                    }
                }
            })
        }
        recursiveFilter(options)
        return selectedOptions
    }

    const onChangeAnyAccidents = async (value) => {
        inputChangedHandler(formState, setFormState, value, anyAccidentsField.key, [majorAccidentsField.key, minorAccidentsField.key, frameDamageField.key])
    }

    const onChangeOwner = async (value) => {
        inputChangedHandler(formState, setFormState, value, ownerField.key, [sellerPossessionField.key, ownerNoField.key])
    }

    const submitHandler = async (event) => {
        event.preventDefault();

        const excludedFields = getExcludeFields(formState)
        if (validateForm(formState, setFormState, excludedFields)) {
            const data = getFormData(formState)
            const selectedTrim = props.vehicle.trims.find((item) => item.styleId === data.styleId)
            const getColorFromHelper = (name) => {
                return {"name": name, "swatch_list": [ColorHelper.getCVDColorByName({description: name})]}
            }
            data.exteriorColor = selectedTrim.exteriorColors.find(el => el.name === data.exteriorColor)
            if (!data.exteriorColor) {
                data.exteriorColor = getColorFromHelper(formData.exteriorColor)
            }

            data.interiorColor = selectedTrim.interiorColors.find(el => el.name === data.interiorColor)
            if (!data.interiorColor) {
                data.interiorColor = getColorFromHelper(data.interiorColor)
            }
            data.trim = selectedTrim.trim
            data.chromeDataOptions = getSelectedOptions(optionsField.value)

            data.xchangeData = {
                answers: prepareAnswersFromState(formData, data, (answersData, answers) => {
                    answers['sellerPossession'] = null
                    answers['owner'] = null
                    if (answersData['owner'] === 'yes') {
                        answers['sellerPossession'] = answersData['sellerPossession']
                        answers['owner'] = answersData['owner']
                    }
                    if (answersData['owner'] === 'no') {
                        answers['owner'] = answersData['ownerNo'] !== null && answersData['ownerNo'] !== '' ? answersData['ownerNo'] : answersData['owner']
                    }

                    return answers
                })
            }

            delete data.minorAccidents
            delete data.majorAccidents
            delete data.owner
            delete data.ownerNo
            delete data.sellerPossession
            delete data.anyAccidents
            delete data.frameDamage

            delete data.options

            props.onNext(data)
        }
    }

    useEffect(() => {
        const setData = async () => {
            const trimData = props.vehicle.trims.find(el => el.styleId === formState.controls.styleId.value)
            if (trimData) {
                let color = trimData.exteriorColors.find(el => el.name === formState.controls.exteriorColor.value)
                if (!color) {
                    color = trimData.exteriorColors[0]
                }
                setImages(VehicleHelper.getVehicleImages(props.vehicle.images, color))
            }
        }
        setData().catch(console.error)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.controls.exteriorColor])

    const imageUrl = images.length ? images[0]['@href']?.replace(/http/i, 'https') : getDefaultImageUrl();
    const carNameStyle = !imageUrl ? {marginTop: "30px"} : null

    const heapTagClassNameOptions = {mainPrefix: HeapTagClassName, prefix: props.currentStep}

    return (
        <div className="vehicle-details-wrapper">
            {imageUrl &&
                <img className="car-img" src={imageUrl} alt="car"/>
            }
            <div className="car-name" style={carNameStyle}>{[props.vehicle.model_year, props.vehicle.make, props.vehicle.model].join(' ')}</div>
            <Question
                id={generateTag(styleIdField.key, {snippet: '{%option%}', suffix: 'btn'})}
                className={generateTag(styleIdField.key, heapTagClassNameOptions)}
                isUpperCase field={styleIdField} formState={formState} setFormState={setFormState} onChange={(value) => {onChangeTrim(value)}}
            />
            {styleIdField.value &&
                <>
                    {engineField.value &&
                        <Question
                            id={generateTag(engineField.key, {snippet: '{%option%}', suffix: 'btn'})}
                            className={generateTag(engineField.key, heapTagClassNameOptions)}
                            isUpperCase field={engineField} formState={formState} setFormState={setFormState}
                        />
                    }
                    {transmissionField.values.length >0 &&
                        <Question
                            id={generateTag(transmissionField.key, {snippet: '{%option%}', suffix: 'btn'})}
                            className={generateTag(transmissionField.key, heapTagClassNameOptions)}
                            isUpperCase field={transmissionField} formState={formState} setFormState={setFormState}
                        />
                    }
                    {drivetrainField.value &&
                        <Question
                            id={generateTag(drivetrainField.key, {snippet: '{%option%}', suffix: 'btn'})}
                            className={generateTag(drivetrainField.key, heapTagClassNameOptions)}
                            isUpperCase field={drivetrainField} formState={formState} setFormState={setFormState}
                        />
                    }
                    {exteriorColors.length > 0 &&
                        <ColorQuestion
                            id={generateTag(exteriorColorField.key, {snippet: '{%option%}', suffix: 'btn'})}
                            className={generateTag(exteriorColorField.key, heapTagClassNameOptions)}
                            questionTitle="EXTERIOR COLOR" answers={exteriorColors} field={exteriorColorField} formState={formState} setFormState={setFormState}
                        />
                    }
                    {interiorColors.length > 0 &&
                        <ColorQuestion
                            id={generateTag(interiorColorField.key, {snippet: '{%option%}', suffix: 'btn'})}
                            className={generateTag(interiorColorField.key, heapTagClassNameOptions)}
                            questionTitle="INTERIOR COLOR" answers={interiorColors} field={interiorColorField} formState={formState} setFormState={setFormState}
                        />
                    }

                    {optionsField.value.filter(el => el.disabled === false).length > 0 &&
                        <>
                            <div className='question-title'>OPTIONS</div>
                            <div className='question-subtitle'>Select all that apply</div>
                            {optionsField.value.filter(el => el.disabled === false).map((option, index) => {
                                return (
                                    <Option
                                        id={generateTag(option.name.toLowerCase(), {suffix: 'btn'})}
                                        className={generateTag(optionsField.key.toLowerCase(), heapTagClassNameOptions)}
                                        key={`optionKey_${index}_${option.id}`}
                                        option={option}
                                        onCheck={(option) => checkOption(option)}
                                    />
                                )
                            })}
                        </>
                    }

                    <Question
                        id={generateTag(titleStatusField.key, {snippet: '{%option%}', suffix: 'btn'})}
                        className={generateTag(titleStatusField.key, heapTagClassNameOptions)}
                        field={titleStatusField} formState={formState} setFormState={setFormState}
                    />

                    <Question
                        id={generateTag(ownerField.key, {snippet: '{%option%}', suffix: 'btn'})}
                        className={generateTag(ownerField.key, heapTagClassNameOptions)}
                        field={ownerField} formState={formState} setFormState={setFormState}
                        onChange={async (value) => {
                            await onChangeOwner(value)
                        }}
                        isInOneLine={true}
                    />

                    {ownerField.value === 'yes' &&
                        <Question
                            id={generateTag(sellerPossessionField.key, {snippet: '{%option%}', suffix: 'btn'})}
                            className={generateTag(sellerPossessionField.key, heapTagClassNameOptions)}
                            field={sellerPossessionField} formState={formState} setFormState={setFormState}
                            isInOneLine={true}
                        />
                    }

                    {ownerField.value === 'no' &&
                        <Question
                            id={generateTag(ownerNoField.key, {snippet: '{%option%}', suffix: 'btn'})}
                            className={generateTag(ownerNoField.key, heapTagClassNameOptions)}
                            field={ownerNoField} formState={formState} setFormState={setFormState}
                        />
                    }

                    <Question
                        id={generateTag(anyAccidentsField.key, {snippet: '{%option%}', suffix: 'btn'})}
                        className={generateTag(anyAccidentsField.key, heapTagClassNameOptions)}
                        field={anyAccidentsField} isInOneLine formState={formState}
                        setFormState={setFormState} onChange={async (value) => {
                        await onChangeAnyAccidents(value)
                    }}
                    />
                    {anyAccidentsField.value === 'yes' &&
                        <>
                            <Question
                                id={generateTag(minorAccidentsField.key, {snippet: '{%option%}', suffix: 'btn'})}
                                className={generateTag(minorAccidentsField.key, heapTagClassNameOptions)}
                                field={minorAccidentsField}
                                formState={formState}
                                setFormState={setFormState}
                            />
                            <Question
                                id={generateTag(majorAccidentsField.key, {snippet: '{%option%}', suffix: 'btn'})}
                                className={generateTag(majorAccidentsField.key, heapTagClassNameOptions)}
                                field={majorAccidentsField} formState={formState}
                                setFormState={setFormState}
                            />
                            {majorAccidentsField.value >= 1 &&
                                <Question
                                    id={generateTag(frameDamageField.key, {snippet: '{%option%}', suffix: 'btn'})}
                                    className={generateTag(frameDamageField.key, heapTagClassNameOptions)}
                                    field={frameDamageField} formState={formState} setFormState={setFormState}
                                    isInOneLine/>
                            }
                        </>
                    }
                </>
            }
            <div className="save-button-wrapper">
                <CircularProgressButton
                    id={generateTag('continue', {prefix: props.currentStep, suffix: 'btn'})}
                    text="SAVE & CONTINUE"
                    isDisabled={!isSubmitEnabled}
                    isLoading={props.loading}
                    onClick={(event) => submitHandler(event)}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        vehicle: state.qualifyVehicle.vehicle,
        currentStep: state.qualifyVehicle.currentStep,
        loading: state.app.qualifyVehicle.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onNext: (data, cbOnError, cbOnSuccess) => dispatch(actions.saveQuestions(data, cbOnError, cbOnSuccess)),
        onGetMakesByYear: (year) => dispatch(actions.getMakesByYear(year)),
        onGetModels: (makeId, year) => dispatch(actions.getModels(makeId, year)),
        onGetTrims: (makeId, modelId, year) => dispatch(actions.getTrimsByMMY(makeId, modelId, year)),
        onChangeTrim: (styleId) => dispatch(actions.getStyleDescription(styleId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetails);