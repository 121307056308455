import { cloneObject, updateObject } from '../../shared/utility'
import * as actionTypes from '../actions/actionTypes'

export const initialState = {
	carData: {},
	sellerData: {},
	otherData: {},
}

const setData = (state, action) => {
	const updatedState = cloneObject(action.data)
	return updateObject(state, updatedState);
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_QUERY_PARAMS:
			return setData(state, action);
		case actionTypes.RESET_QUERY_PARAMS:
			return initialState
		default:
			return state;
	}
}

export const getInitialState = () => initialState
