import axios from 'axios';

let axiosToken
let axiosOrigin
let axiosCampaignId
let instance

const create = () => {
    instance =  axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        'Content-Type': 'application/json',
        headers: {'Authorization': 'Bearer ' + axiosToken}
    })
    instance.cancelToken = axios.CancelToken.source();

    instance.isCancel = axios.isCancel;

    instance.changeCancelToken = () => {
        instance.cancelToken = axios.CancelToken.source();
    }

    instance.interceptors.request.use((cfg) => {
        cfg.headers.Authorization = 'Bearer ' + axiosToken;
        axiosOrigin && (cfg.headers['x-origin'] = axiosOrigin);
        axiosCampaignId && (cfg.headers['x-campaign-id'] = axiosCampaignId);
        return cfg;
    });

    return instance
}

export const setToken = (token, origin, campaignId) => {
    axiosToken = token
    axiosOrigin = origin
    axiosCampaignId = campaignId
}

export default instance ? instance : create();