import { useEffect } from 'react'
import { initialState as initialStateApp } from '../store/reducers/app'
import { initialState as initialStateQualifyVehicle } from '../store/reducers/qualifyVehicle'
import { Steps } from '../constants'
import { cloneObject } from '../shared/utility'
import moment from 'moment'
import { isEqual } from 'underscore'
import {DATA_VERSION} from "../shared/dataVersion";

export const keyLocalStorageGlobalState = 'globalState'
export const keyLocalStorageLastUpdate = 'lastUpdate'

const unitOfTime = process.env.REACT_APP_SESSION_TRACKING_UNIT_OF_TIME
const threshold = process.env.REACT_APP_SESSION_TRACKING_THRESHOLD

const clearLocalStorageOnTheseSteps = [
	Steps.OffersRangeThankYou,
	Steps.CreateAccountThankYou,
	Steps.AccountExistThankYou,
	Steps.ExitNoClientsThankYou,
	Steps.AllDQd,
]

const storeGlobalStateOnTheseSteps = [
	Steps.History,
	Steps.Exterior,
	Steps.Mechanical,
	Steps.Additional,
]

export const getItem = (itemName, itemDefault = null) => {
	return JSON.parse(localStorage.getItem(itemName)) ?? itemDefault
}

export const setItem = (itemName, itemValue) => {
	localStorage.setItem(itemName, JSON.stringify(itemValue))
}

export const removeItem = (itemName) => {
	localStorage.removeItem(itemName)
}

export const removeLocalStateForStep = (currentStepName, newStepName) => {
	removeItem(currentStepName)

	const dataGlobalState = getItem(keyLocalStorageGlobalState)
	dataGlobalState.currentStep = newStepName
	setItem(keyLocalStorageGlobalState, dataGlobalState)
}

export const updateSub2Item = (itemName, subItem, subSubItem, value) => {
	let itemValue = getItem(itemName);
	if (itemValue && itemValue[subItem]) {
		itemValue[subItem][subSubItem] = value;
		setItem(itemName, itemValue);
	}
}

export const clearAllItems = () => {
	localStorage.removeItem(keyLocalStorageGlobalState)
	localStorage.removeItem(keyLocalStorageLastUpdate)
	localStorage.removeItem(Steps.History)
	localStorage.removeItem(Steps.Exterior)
	localStorage.removeItem(Steps.Mechanical)
	localStorage.removeItem(Steps.Additional)
}

const useGlobalState = (globalState, recoveryAppData, recoveryQualifyVehicleData) => {
	const initialGlobalState = cloneObject({
		qualifyVehicle: initialStateQualifyVehicle,
		app: initialStateApp,
	})

	useEffect(() => {
		const trackedGlobalState = getItem(keyLocalStorageGlobalState)
		if (
			storeGlobalStateOnTheseSteps.includes(globalState.qualifyVehicle.currentStep) &&
			!isEqual(trackedGlobalState?.qualifyVehicle, globalState.qualifyVehicle) &&
			!isEqual(initialGlobalState.qualifyVehicle, globalState.qualifyVehicle) &&
			!isEqual(globalState.qualifyVehicle.vehicle, {})
		) {
			// console.log('store globalState')
			setItem(keyLocalStorageGlobalState, {
				qualifyVehicle: globalState.qualifyVehicle,
				app: globalState.app,
				dataVersion: DATA_VERSION
			})
		}
	}, [globalState.qualifyVehicle])

	useEffect(() => {
		if (
			clearLocalStorageOnTheseSteps.includes(globalState.qualifyVehicle.currentStep)
		) {
			// console.log('clear localStorage by success step')
			clearAllItems()
		}
	}, [globalState.qualifyVehicle.currentStep])

	useEffect(() => {
		const trackedGlobalState = getItem(keyLocalStorageGlobalState)
		const dataVersionState = trackedGlobalState?.dataVersion
		// console.log(trackedGlobalState, dataVersionState, DATA_VERSION)
		if(dataVersionState !== DATA_VERSION) {
			clearAllItems()
			return
		}

		const lastUpdate = getItem(keyLocalStorageLastUpdate)
		if (lastUpdate) {
			// console.log('check expires')
			// console.log(moment.utc(lastUpdate).format())
			// console.log(moment.utc().format())

			const delta = moment.utc().diff(moment.utc(lastUpdate), unitOfTime)
			if (delta >= threshold) {
				clearAllItems()
			}
		}

		if (
			trackedGlobalState !== null &&
			!isEqual(trackedGlobalState.qualifyVehicle, initialGlobalState.qualifyVehicle)
		) {
			// console.log('recovery globalState to show welcome back screen', trackedGlobalState);
			trackedGlobalState.qualifyVehicle.currentStep = Steps.WelcomeBack
			recoveryQualifyVehicleData(trackedGlobalState.qualifyVehicle)
			recoveryAppData({
				...initialGlobalState.app,
				growSurfToken: trackedGlobalState.app.growSurfToken,
			})
		}
	}, [])
}

export default useGlobalState