import './index.css'
import Button from '@mui/material/Button'

function ButtonComponent(props) {
	const {href = null, id = null, text, isDisabled, size, onClick = null, sx, ...rest} = props

	//size can be small, medium and large, medium is default
	return (
		<div className="button-wrapper">
			<Button
				href={href ?? null}
				id={id ?? null}
                disabled={isDisabled}
                size={size ? size : 'medium'}
                onClick={onClick ?? null}
                sx={sx ?? null}
				{...rest}
            >
				{text}
			</Button>
		</div>
	)
}

export default ButtonComponent