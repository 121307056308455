import './index.css';
import HeaderWithTitle from '../../components/ui/HeaderWithTitle';
import Logo from '../../components/ui/Logo';
import Footer from "../../components/ui/Footer";
import { generateTag, getFormData, inputChangedHandler, prepareFormData, sanitizeString, validateForm } from '../../shared/utility'
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import InputField from "../../components/ui/InputField";
import React, {useEffect, useState} from "react";
import InputMask from "react-input-mask";
import CircularProgressButton from "../../components/ui/CircularProgress";
import TextField from "@mui/material/TextField";
import { ReactComponent as CheckImg } from "../../img/check-circle.svg";
import { ReactComponent as ErrorImg } from "../../img/error-circle.svg";
import {validationName, validationLastName, validationEmail, validationPhone, validationAmericanPhone} from '../../shared/validationRules'
import { Alert, Box } from '@mui/material'
import useQueryParams from '../../hooks/useQueryParams'
import useLocalState from '../../hooks/useLocalState'
import { MainWrapperIdName, Steps } from '../../constants'
import {ReactComponent as Money} from "../../img/money.svg";
import {ReactComponent as Save} from "../../img/save.svg";
import {ReactComponent as Run} from "../../img/run.svg";
import {ReactComponent as Chat} from "../../img/chat.svg";

let formData = {
    controls: {
        firstName: {
            label: 'First Name',
            value: '',
            validation: validationName,
            valid: false,
            touched: false,

        },
        lastName: {
            label: 'Last Name',
            value: '',
            validation: validationLastName,
            valid: false,
            touched: false,
        },
        email: {
            label: 'Email',
            value: '',
            validation: validationEmail,
            valid: true,
            touched: false,
        },
        phone: {
            label: 'Phone Number',
            value: '',
            mask: '+1 (999) 999-9999',
            validation: validationAmericanPhone,
            valid: false,
            touched: false,
            helperText: null,
        },
        password: {
            label: 'Password',
            value: '',
            validation: {
                presence: {allowEmpty: false},
                length: {minimum: 8, allowEmpty: false}
            },
            valid: false,
            touched: false,

        },
    },
    loading: false,
    valid: false,
    touched: false,
    generalError: null
}

const CreateAccount = (props) => {
    const initialData = prepareFormData(formData, {email: props.seller?.email ?? props.queryParams?.sellerData?.email ?? ''})
    const [formState, setFormState] = useState(initialData);
    // const [formState, setFormState] = useLocalState(Steps.CreateAccount, initialData);
    const [isSubmitEnabled, setSubmitEnabled] = useState(false)
    const [emailDisabled, setEmailDisabled] = useState(true)

    const [errorMessage, setErrorMessage] = useState('')

    const isLocked = false

    const firstNameField = formState.controls.firstName;
    const lastNameField = formState.controls.lastName
    const emailField = formState.controls.email
    //const agreeField = formState.controls.agree
    const passwordField = formState.controls.password
    const phoneField = formState.controls.phone

    useQueryParams(formState, setFormState, props.queryParams, 'sellerData')

    const checkForm = () => {
        //if (!agreeField.value) return false
        return validateForm(formState, setFormState, [], false)
    }

    useEffect(() => {
        setSubmitEnabled(checkForm())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState])


    const submitHandler = async (event) => {
        event.preventDefault();
        if (validateForm(formState, setFormState)) {
            let data = getFormData(formState)
            // delete data.agree
            data = {seller: data}
            const onError = (errorMsg) => {
                setErrorMessage(errorMsg)
            }
            props.onNext(data, onError)
        }
    }

    return (
        <div className="create-account-wrapper">
            <HeaderWithTitle />
            <div className="logo-wrapper"><Logo/></div>
            {isLocked
                ? <div className="title-wrapper">Create a Carwiser Account to <br/> Save &amp; unclock these Offers</div>
                : <div className="title-wrapper">Take the next steps to...</div>
            }
            <div className="create-account-marketing-wrapper">
                <div className="information-block">
                    <div className="information-block-line">
                        <Save className="information-img save-logo"  alt="monetization img"/>
                        <div className="information-text">Save your offers</div>
                    </div>
                    <div className="information-block-line">
                        <Money className="information-img money-logo"  alt="monetization img"/>
                        <div className="information-text">Receive additional offers</div>
                    </div>
                    <div className="information-block-line">
                        <Run className="information-img run-logo"  alt="monetization img"/>
                        <div className="information-text">Run new offers when they expire</div>
                    </div>
                    <div className="information-block-line">
                        <Chat className="information-img chat-logo"  alt="monetization img"/>
                        <div className="information-text">Get support on demand</div>
                    </div>
                </div>
            </div>

            <InputField
                id={generateTag(firstNameField.key, {suffix: 'field'})}
                field={firstNameField} formState={formState} setFormState={setFormState}
            />
            <InputField
                id={generateTag(lastNameField.key, {suffix: 'field'})}
                field={lastNameField} formState={formState} setFormState={setFormState}
            />

            <InputField
                id={generateTag(emailField.key, {suffix: 'field'})}
                field={emailField} formState={formState} setFormState={setFormState}
            />

            <InputMask
                mask={phoneField.mask}
                value={phoneField.value}
                onChange={event => inputChangedHandler(formState, setFormState, event.target.value, phoneField.key)}
            >
                {() => (
                    <div className="input-wrapper">
                        <TextField
                            id={generateTag(phoneField.key, {suffix: 'field'})}
                            name={phoneField.key}
                            value={phoneField.value}
                            type={'input'}
                            label={phoneField.label}
                            variant="filled"
                            size={'small'}
                            fullWidth
                            helperText={phoneField.helperText}
                            error={!phoneField.valid && phoneField.touched}
                        />
                        {!phoneField.valid && phoneField.touched &&
                        <ErrorImg className="errorImg" alt="error img"/>}
                        {phoneField.touched && phoneField.valid &&
                        <CheckImg className="errorImg" alt="check img"/>}
                    </div>
                )}
            </InputMask>

            <InputField
                id={generateTag(passwordField.key, {suffix: 'field'})}
                field={passwordField} formState={formState} setFormState={setFormState} isPassword
            />

            {/*
            <div className="CheckboxComponent-wrapper">
                <CheckBoxField
                    label={(
                        <>
                            I agree to Carwiser’s&nbsp;
                            <a className="linkLabel" href={'https://carwiser.com/terms.html'} target="_blank" rel="noreferrer">Terms of Service</a>
                            &nbsp;&&nbsp;
                            <a className="linkLabel" href={'https://carwiser.com/privacy.html'} target="_blank" rel="noreferrer">Privacy Policy</a>
                        </>
                    )}
                    field={agreeField}
                    formState={formState}
                    setFormState={setFormState}
                />
            </div>
            */}

            <div className="save-button-wrapper">

                {errorMessage && (
                    <Alert severity="error" onClose={() => setErrorMessage('')} sx={{
                        mt: 1,
                        mb: 1,
                        '&.MuiPaper-root': {
                            borderRadius: '4px !important',
                        }
                    }}>
                        {errorMessage}
                    </Alert>
                )}

                <CircularProgressButton
                    id={generateTag('continue', {prefix: props.currentStep, suffix: 'btn'})}
                    text="Continue"
                    isLoading={props.loading}
                    isDisabled={!isSubmitEnabled}
                    onClick={(event) => submitHandler(event)}
                />
            </div>
            <Box className="terms-wrapper">
                <span>By clicking on the “Continue” button</span> you certify that you have read and agree to Carwiser’s  <a className="link" href={'https://carwiser.com/terms.html'} target="_blank" rel="noreferrer">Terms of Use</a> &nbsp;&&nbsp; <a className="link" href={'https://carwiser.com/privacy.html'} target="_blank" rel="noreferrer">Privacy Policy</a>. You also agree that Carwiser or its authorized representatives may call and/or send text messages (including by using equipment to automatically dial telephone numbers) about your interest in selling your vehicle, for marketing & sales purposes, or for any other servicing or informational purpose related to your account.
            </Box>
            <div className="footer-wrapper">
                <Footer/>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        vehicle: state.qualifyVehicle.vehicle,
        seller: state.qualifyVehicle.seller,
        queryParams: state.queryParams,
        error: state.app.qualifyVehicle.error,
        currentStep: state.qualifyVehicle.currentStep,
        loading: state.app.qualifyVehicle.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onNext: (data, cbOnError, cbOnSuccess) => dispatch(actions.saveQuestions(data, cbOnError, cbOnSuccess)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);