import React from "react";
import './index.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from "../Button";
import {getProviderLogo} from "../../../shared/providerHelper";
import {connect} from "react-redux";
import {NumberFormatter} from "../../../shared/formatter";
import * as actions from "../../../store/actions";
import {ReactComponent as DollarSign} from "../../../img/DollarSign.svg";
import {ReactComponent as Timer} from "../../../img/timer.svg";
import {Box, Icon, Stack} from '@mui/material'
import { generateTag } from '../../../shared/utility'
import moment from 'moment'
import {OfferHelper} from '../../../utils/offer_helper'
import {PrimaryNamesType} from "../../../shared/MatchingClientsHelper";
import {OfferTypes} from '../../../constants';

const orderLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

const AccordionWithResult = (props) => {
    const {provider, isLocked} = props;

    const providerData = props.providers.find(el => el.key === provider.primaryName)
    if (!providerData) {
        return null
    }

    if (providerData.hasOwnProperty('card') && provider?.offer?.badges) {
        providerData.card = {...providerData.card, badges: provider.offer.badges}
    }

    const price = provider.offer?.price ? NumberFormatter.format(provider.offer?.price) : null

    const submitHandler = async (event, providerId = null) => {
        event.preventDefault();
        if (providerId !== null) {
            props.onNext({providerId})
        } else {
            props.onNext({})
        }
    }

    const details = provider.status === 'open' ? providerData.in_progress.concat(providerData.details) : providerData.details

    const offerDetails = providerData.card.offerDetails

    const isProviderLogoHidden = provider.offer?.isLogoHidden ?? false
    const srcProvider = providerData?.card?.logo ?? provider.address?.logoURL ?? getProviderLogo(provider)
    const isLocalDealer = (provider.primaryName === PrimaryNamesType.Vincue && !provider.address?.logoURL) || (provider.offerType === OfferTypes.InstantOfferConfigurator && !providerData?.card?.logo) ? true : false

    const LogoBlock = ({isLocalDealer, isProviderLogoHidden, srcProvider}) => {
        if (isLocalDealer) {
            return (
                <Box className="provider-logo" sx={{
                    color: '#2A2F31',
                    fontSize: '30px',
                    lineHeight: '36px',
                    fontWeight: 500,
                    textAlign: 'center'
                }}>{'Local Dealer'}</Box>
            )
        }
        if (isProviderLogoHidden) {
            return (
                <Box className="provider-logo" sx={{
                    color: '#2A2F31',
                    fontSize: '30px',
                    lineHeight: '36px',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    textAlign: 'center'
                }}>{`Offer A`}</Box>
            )
        }
        if (srcProvider) {
            return (
                <Box component={'img'} src={srcProvider} className={`provider-logo logo-img provider-logo-${provider.primaryName}`}/>
            )
        }

        return <></>
    }

    const SvgIconFromUrl = ({icon, width = 28, height = 28}) => {
        // const [iconSrc, setIconSrc] = useState(icon)
        // useEffect(() => {
        // 	setIconSrc(icon)
        // }, [icon])

        return (
            <Icon style={{textAlign: 'center', width: `${width}px`, height: `${height}px`}}>
                <img src={icon} style={{display: 'flex', width: 'inherit', height: 'inherit'}}/>
            </Icon>
        )
    }

    const Badges = ({badges}) => {
        let iconBadge
        let textBadge

        for (let key in badges) {
            if (['icon'].includes(key)) {
                continue
            }

            const badge = badges[key]

            if (badge.status) {
                iconBadge = badges.icon
                textBadge = badge.caption
                break
            }
        }

        if (iconBadge === undefined) {
            return null
        }

        if (textBadge === undefined) {
            return null
        }

        return (
            <Box className="toggle-badge">
                <SvgIconFromUrl icon={iconBadge}/>
                <span className={"text"}>{textBadge}</span>
            </Box>
        )
    }

    const getExpiresDate = (offer) => {
        if (offer.expirationDate) {
            return `Expires ${moment(offer.expirationDate).format('M/D/Y')}`
        }
        return null;
    }

    const expiresDate = getExpiresDate(provider.offer);

    const FactElement = (benefit, index) => {
        return (
            <div className="fact-block-line" key={index}>
                <SvgIconFromUrl icon={benefit.icon}/>
                <div className="fact-text">{benefit.text}</div>
            </div>
        )
    }

    const isAffiliateProgram = provider.isAffiliateProgram ?? false

    const CarvanaAffiliateProgramBlock = () => {
        const headerTitle = 'Carvana lets you sell or trade<br/> your car in just a few steps.';
        const headerDescription = 'Get an offer from Carvana in 2 minutes.';

        return (
            <>
                <Box sx={{
                    textAlign: 'center',
                    color: '#2A2F31',
                }}>
                    <Box
                        sx={{
                            fontWeight: 700,
                            fontSize: '22px',
                            lineHeight: 1,
                            mb: '10px',
                            paddingTop: '8px',
                            paddingBottom: '8px'
                        }}
                        dangerouslySetInnerHTML={{__html: headerTitle}}
                    />
                    <Box
                        sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: 1,
                        }}
                    >{headerDescription}</Box>
                </Box>
            </>
        )
    }

    const HeaderBlock = ({status, price, badges, expiresDate, isAffiliateProgram = false}) => {
        if (isAffiliateProgram) {
            return <CarvanaAffiliateProgramBlock/>
        }

        const headerDescription = (
            <Box className="toggle-info">
                {
                    status === 'open'
                        ? (
                            <Box className="toggle-text">
                                <span>Create your Carwiser account to receive this offer</span>
                            </Box>
                        )
                        : (
                            <>
                                <Badges badges={badges}/>
                                {
                                    expiresDate && (
                                        <Box className="toggle-expire information-block-line">
                                            <Timer className="monetization-img timer-logo" alt="monetization img"/>
                                            <div className="information-text red">{expiresDate}</div>
                                        </Box>
                                    )
                                }
                            </>
                        )
                }
            </Box>
        )

        if (status === 'open') {
            return (
                <>
                    <div className="locked-price-block">
                        <div className="locked-price">PENDING</div>
                    </div>
                    {headerDescription}
                </>
            )
        } else {
            return (
                <>
                    <div className="price-block">
                        <DollarSign className="dollar-sign-img" alt="dollar sign img"/>
                        <div className="price">{price}</div>
                    </div>
                    {headerDescription}
                </>
            )
        }
    }

    const unLocked = isAffiliateProgram ? 'GET CARVANA OFFER' : (price ? "LET’S GO!" : 'SAVE & GET OFFER');
    const ButtonSubmitText = isLocked ? "UNLOCK OFFER" : unLocked;
    const ButtonIdSelector = isAffiliateProgram ? `${provider.case.idSelector}` : generateTag('save-offer', {prefix: props.currentStep, suffix: 'btn'})
    const ButtonSubmit = isAffiliateProgram
        ? (
            <Button
                id={ButtonIdSelector}
                text={ButtonSubmitText}
                href={provider.case.url}
                target={'_blank'}
            />
        )
        : (
            <Button
                id={ButtonIdSelector}
                text={ButtonSubmitText}
                onClick={(event) => submitHandler(event, (price ? provider.id : null))}
            />
        )

    return (
        <div className="accordion-with-result-wrapper">
            <Accordion className={`${OfferHelper.generateClass(providerData)}`}>
                <AccordionSummary
                    expandIcon={isLocked || details.length === 0 ? false : (
                        <div className="details">
                            <div className="text">DETAILS</div>
                            <div className="icon"><ExpandMoreIcon/></div>
                        </div>
                    )}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography component={'div'}>
                        <Stack gap={'10px'}>
                            <LogoBlock isLocalDealer={isLocalDealer} isProviderLogoHidden={isProviderLogoHidden} srcProvider={srcProvider}/>
                            <HeaderBlock
                                status={provider.status}
                                price={price}
                                badges={providerData.card.badges}
                                expiresDate={expiresDate}
                                isAffiliateProgram={isAffiliateProgram}
                            />
                        </Stack>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography component={'div'}>
                        <Box className={'main-details' + OfferHelper.generateClass(providerData, 'main-details')}>
                            <Box className={'description-block' + OfferHelper.generateClass(providerData, 'benefits')}>
                                <Box className="description-header">BENEFITS</Box>
                                <Box className="facts-block">
                                    <Box className="facts-block-cont">
                                        {offerDetails.benefits.map((el, index) => FactElement(el, index))}
                                    </Box>
                                </Box>
                            </Box>
                            <Box className={'description-block' + OfferHelper.generateClass(providerData, 'next-steps')}>
                                <Box className="description-header">NEXT STEPS</Box>
                                <Box className="icon-cols">
                                    {offerDetails.nextSteps.map((el, index) => {
                                        const number = index + 1
                                        return (
                                            <Box className="icon-col" key={index}>
                                                <Box className="icon">
                                                    <SvgIconFromUrl icon={el.icon.src} width={40} height={40}/>
                                                </Box>
                                                <Box className="text">{`${number}. ${el.icon.caption}`}</Box>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                            <Box className={'description-block' + OfferHelper.generateClass(providerData, 'documents')}>
                                <Box className="description-header">DOCUMENTS YOU’LL NEED</Box>
                                <Box className="icon-cols">
                                    {offerDetails.documents.map((el, index) => {
                                        return (
                                            <Box className="icon-col" key={index}>
                                                <Box className="icon">
                                                    <SvgIconFromUrl icon={el.icon.src} width={40} height={40}/>
                                                </Box>
                                                <Box className="text">{el.icon.caption}</Box>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </Box>
                        <div className="accordion-button-wrapper">
                            {ButtonSubmit}
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        providers: state.qualifyVehicle.providersData,
        currentStep: state.qualifyVehicle.currentStep,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onNext: (data, cbOnError, cbOnSuccess) => dispatch(actions.saveQuestions(data, cbOnError, cbOnSuccess)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccordionWithResult);
