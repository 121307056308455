export const colors = [
    {code: "#f0f8ff", name: "Alice Blue"},
    {code: "#faebd7", name: "Antique White"},
    {code: "#7fffd4", name: "Aquamarine"},
    {code: "#71d9e2", name: "Aquamarine Blue"},
    {code: "#007fff", name: "Azure"},
    {code: "#f5f5dc", name: "Beige"},
    {code: "#ffe4c4", name: "Bisque"},
    {code: "#000000", name: "Black"},
    {code: "#ffebcd", name: "Blanched Almond"},
    {code: "#0000ff", name: "Blue"},
    {code: "#8a2be2", name: "Blue Violet"},
    {code: "#964b00", name: "Brown"},
    {code: "#deb887", name: "Burlywood"},
    {code: "#5f9ea0", name: "Cadet Blue"},
    {code: "#7fff00", name: "Chartreuse"},
    {code: "#d2691e", name: "Chocolate"},
    {code: "#ff7f50", name: "Coral"},
    {code: "#6495ed", name: "Cornflower Blue"},
    {code: "#fff8dc", name: "Cornsilk"},
    {code: "#dc143c", name: "Crimson"},
    {code: "#00ffff", name: "Cyan"},
    {code: "#00008b", name: "Dark Blue"},
    {code: "#008b8b", name: "Dark Cyan"},
    {code: "#b8860b", name: "Dark Goldenrod"},
    {code: "#a9a9a9", name: "Dark Gray"},
    {code: "#006400", name: "Dark Green"},
    {code: "#bdb76b", name: "Dark Khaki"},
    {code: "#8b008b", name: "Dark Magenta"},
    {code: "#556b2f", name: "Dark Olive Green"},
    {code: "#ff8c00", name: "Dark Orange"},
    {code: "#9932cc", name: "Dark Orchid"},
    {code: "#8b0000", name: "Dark Red"},
    {code: "#e9967a", name: "Dark Salmon"},
    {code: "#8fbc8f", name: "Dark Sea Green"},
    {code: "#483d8b", name: "Dark Slate Blue"},
    {code: "#2f4f4f", name: "Dark Slate Gray"},
    {code: "#00ced1", name: "Dark Turquoise"},
    {code: "#9400d3", name: "Dark Violet"},
    {code: "#ff1493", name: "Deep Pink"},
    {code: "#00bfff", name: "Deep Sky Blue"},
    {code: "#696969", name: "Dim Gray"},
    {code: "#1e90ff", name: "Dodger Blue"},
    {code: "#b22222", name: "Firebrick"},
    {code: "#fffaf0", name: "Floral White"},
    {code: "#228b22", name: "Forest Green"},
    {code: "#ff00ff", name: "Fuchsia"},
    {code: "#dcdcdc", name: "Gainsboro"},
    {code: "#f8f8ff", name: "Ghost White"},
    {code: "#daa520", name: "Goldenrod"},
    {code: "#ffd700", name: "Golden"},
    {code: "#808080", name: "Gray"},
    {code: "#008000", name: "Green"},
    {code: "#adff2f", name: "Green Yellow"},
    {code: "#f0fff0", name: "Honeydew"},
    {code: "#ff69b4", name: "Hot Pink"},
    {code: "#cd5c5c", name: "Indian Red"},
    {code: "#4b0082", name: "Indigo"},
    {code: "#fffff0", name: "Ivory"},
    {code: "#f0e68c", name: "Khaki"},
    {code: "#b57edc", name: "Lavender"},
    {code: "#fff0f5", name: "Lavender Blush"},
    {code: "#e6e6fa", name: "Lavender Mist"},
    {code: "#7cfc00", name: "Lawn Green"},
    {code: "#fff700", name: "Lemon"},
    {code: "#fffacd", name: "Lemon Chiffon"},
    {code: "#add8e6", name: "Light Blue"},
    {code: "#f08080", name: "Light Coral"},
    {code: "#e0ffff", name: "Light Cyan"},
    {code: "#fafad2", name: "Light Goldenrod Yellow"},
    {code: "#d3d3d3", name: "Light Gray"},
    {code: "#90ee90", name: "Light Green"},
    {code: "#ffb6c1", name: "Light Pink"},
    {code: "#ffa07a", name: "Light Salmon"},
    {code: "#20b2aa", name: "Light Sea Green"},
    {code: "#87cefa", name: "Light Sky Blue"},
    {code: "#778899", name: "Light Slate Gray"},
    {code: "#b0c4de", name: "Light Steel Blue"},
    {code: "#ffffe0", name: "Light Yellow"},
    {code: "#00ff00", name: "Lime"},
    {code: "#32cd32", name: "Lime Green"},
    {code: "#faf0e6", name: "Linen"},
    {code: "#ff00ff", name: "Magenta"},
    {code: "#800000", name: "Maroon"},
    {code: "#66ddaa", name: "Medium Aquamarine"},
    {code: "#0000cd", name: "Medium Blue"},
    {code: "#ba55d3", name: "Medium Orchid"},
    {code: "#9370db", name: "Medium Purple"},
    {code: "#3cb371", name: "Medium Sea Green"},
    {code: "#7b68ee", name: "Medium Slate Blue"},
    {code: "#00fa9a", name: "Medium Spring Green"},
    {code: "#48d1cc", name: "Medium Turquoise"},
    {code: "#c71585", name: "Red Violet"},
    {code: "#191970", name: "Midnight Blue"},
    {code: "#f5fffa", name: "Mint Cream"},
    {code: "#ffe4e1", name: "Misty Rose"},
    {code: "#ffe4b5", name: "Moccasin"},
    {code: "#ffdead", name: "Navajo White"},
    {code: "#000080", name: "Navy"},
    {code: "#fdf5e6", name: "Old Lace"},
    {code: "#808000", name: "Olive"},
    {code: "#6b8e23", name: "Olive Drab"},
    {code: "#ffa500", name: "Orange"},
    {code: "#ff4500", name: "Orange Red"},
    {code: "#da70d6", name: "Orchid"},
    {code: "#eee8aa", name: "Pale Goldenrod"},
    {code: "#98fb98", name: "Pale Green"},
    {code: "#afeeee", name: "Pale Turquoise"},
    {code: "#db7093", name: "Pale Red Violet"},
    {code: "#ffefd5", name: "Papaya Whip"},
    {code: "#ffdab9", name: "Peach Puff"},
    {code: "#cd853f", name: "Peru"},
    {code: "#ffc0cb", name: "Pink"},
    {code: "#dda0dd", name: "Plum"},
    {code: "#b0e0e6", name: "Powder Blue"},
    {code: "#800080", name: "Purple"},
    {code: "#663399", name: "Rebecca Purple"},
    {code: "#ff0000", name: "Red"},
    {code: "#bc8f8f", name: "Rosy Brown"},
    {code: "#4169e1", name: "Royal Blue"},
    {code: "#8b4513", name: "Saddle Brown"},
    {code: "#fa8072", name: "Salmon"},
    {code: "#f4a460", name: "Sandy Brown"},
    {code: "#2e8b57", name: "Sea Green"},
    {code: "#fff5ee", name: "Seashell"},
    {code: "#c0c0c0", name: "Silver"},
    {code: "#87ceeb", name: "Sky Blue"},
    {code: "#6a5acd", name: "Slate Blue"},
    {code: "#708090", name: "Slate Gray"},
    {code: "#fffafa", name: "Snow"},
    {code: "#00ff7f", name: "Spring Green"},
    {code: "#4682b4", name: "Steel Blue"},
    {code: "#d2b48c", name: "Tan"},
    {code: "#008080", name: "Teal"},
    {code: "#d8bfd8", name: "Thistle"},
    {code: "#ff6347", name: "Tomato"},
    {code: "#40e0d0", name: "Turquoise"},
    {code: "#ee82ee", name: "Violet"},
    {code: "#f5deb3", name: "Wheat"},
    {code: "#ffffff", name: "White"},
    {code: "#f5f5f5", name: "White Smoke"},
    {code: "#ffff00", name: "Yellow"},
    {code: "#9acd32", name: "Yellow Green"},
];