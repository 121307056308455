import React from "react";
import './index.css';
import LinearProgress from "@mui/material/LinearProgress";

function ProgressBar(props) {

    const { progressValue } = props;

    return (
        <div className="progress-bar-wrapper">
            <LinearProgress variant="determinate" value={progressValue} />
        </div>
    )}

export default ProgressBar;