import './index.css'

function Footer() {
	return (
        <div>
            <img src={'https://images.carwiser.com/carwiser/Carwiser_PoweredBy_Color.svg'} className="carwiser-powered-by"/>
        </div>
    )
}

export default Footer