export const defaultColors = [
    {name: "Beige", "swatch_list": ["#f5f5dc"]},
    {name: "Black", "swatch_list": ["#000000"]},
    {name: "Blue", "swatch_list": ["#0000ff"]},
    {name: "Bronze", "swatch_list": ["#CD7F32"]},
    {name: "Brown", "swatch_list": ["#964b00"]},
    {name: "Burgundy", "swatch_list": ["#800020"]},
    {name: "Gold", "swatch_list": ["#ffd700"]},
    {name: "Gray", "swatch_list": ["#808080"]},
    {name: "Green", "swatch_list": ["#008000"]},
    {name: "Orange", "swatch_list": ["#ffa500"]},
    {name: "Pink", "swatch_list": ["#ffc0cb"]},
    {name: "Purple", "swatch_list": ["#800080"]},
    {name: "Red", "swatch_list": ["#ff0000"]},
    {name: "Silver", "swatch_list": ["#c0c0c0"]},
    {name: "Tan", "swatch_list": ["#d2b48c"]},
    {name: "Turquoise", "swatch_list": ["#40e0d0"]},
    {name: "White", "swatch_list": ["#ffffff"]},
    {name: "Yellow", "swatch_list": ["#ffff00"]},
];