import './index.css';

import Footer from '../../../components/ui/Footer';
import HeaderWithTitle from "../../../components/ui/HeaderWithTitle";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {MoneyFormatter} from "../../../shared/formatter";

import { checkField, cloneObject, generateTag, getFormData, inputChangedHandler, prepareFormData, validateForm } from '../../../shared/utility'
import InputField from "../../../components/ui/InputField";
import CheckBoxField from "../../../components/ui/CheckBoxField";
import {useEffect, useState} from "react";
import VehicleHelper from "../../../shared/VehicleHelper";
import CarWithRibbon from "../../../components/ui/CarWithRibbon";
import InputMask from "react-input-mask";
import CircularProgressButton from "../../../components/ui/CircularProgress";
import TextField from "@mui/material/TextField";
import { ReactComponent as ErrorImg } from "../../../img/error-circle.svg";
import { ReactComponent as CheckImg } from "../../../img/check-circle.svg";
import { validationEmail, validationName, validationAmericanPhone, validationLastName } from '../../../shared/validationRules'
import useLocalState from '../../../hooks/useLocalState'
import { Steps } from '../../../constants'
import useQueryParams from '../../../hooks/useQueryParams'


let formData = {
    controls: {
        firstName: {
            label: 'First Name',
            value: '',
            validation: validationName,
            valid: false,
            touched: false,

        },
        lastName: {
            label: 'Last Name',
            value: '',
            validation: validationLastName,
            valid: false,
            touched: false,
        },
        phone: {
            label: 'Phone Number',
            value: '',
            mask: '+1 (999) 999-9999',
            validation: validationAmericanPhone,
            valid: false,
            touched: false,
            helperText: null,
        },
        agree: {
            label: '',
            value: false,
            validation: {presence: {allowEmpty: false}},
            valid: false,
            touched: false,
            helperText: null,
        }
    },
    loading: false,
    valid: false,
    touched: false,
    generalError: null
}

const OffersScreenRange = (props) => {
    const initialData = prepareFormData(formData, {email: props.seller?.email ?? ''})
    const [formState, setFormState] = useState(initialData);
    // const [formState, setFormState] = useLocalState(Steps.OffersRange, initialData);
    const [isSubmitEnabled, setSubmitEnabled] = useState(false)

    const [images, setImages] = useState([])

    const firstNameField = formState.controls.firstName;
    const lastNameField = formState.controls.lastName
    const emailField = formState.controls.email
    const phoneField = formState.controls.phone
    const agreeField = formState.controls.agree

    useQueryParams(formState, setFormState, props.queryParams, 'sellerData')

    useEffect(() => {
        const setData = async () => {
            setImages(VehicleHelper.getVehicleImages(props.vehicle.images, props.vehicle.exteriorColor))
        }
        if (props.vehicle.exteriorColor) {
            setData().catch(console.error)
        }

    }, [props.vehicle.exteriorColor])

    const imageUrl = images.length ? images[0]['@href']?.replace(/http/i, 'https') : null
    const carNameStyle = !imageUrl ? {marginTop: "30px"} : null

    const checkForm = () => {
        if (!agreeField.value) return false
        return validateForm(formState, setFormState, [], false)
    }

    useEffect(() => {
        setSubmitEnabled(checkForm())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState])


    const submitHandler = async (event) => {
        event.preventDefault();
        if (validateForm(formState, setFormState)) {
            let data = getFormData(formState)
            delete data.agree
            data = {seller: data}
            props.onNext(data)
        }
    }

    return (
        <div className="offers-screen-range-wrapper">
            <HeaderWithTitle title="Your Estimate Is Here"/>
            {imageUrl &&
                <CarWithRibbon imageUrl={imageUrl}/>
            }
            <div
                className='car-name'
                style={carNameStyle}>{[props.vehicle.model_year, props.vehicle.make, props.vehicle.model].join(' ')}</div>
            <div className="car-price-wrapper">
                <div
                    className="car-price">{MoneyFormatter.format(props.price.min)} - {MoneyFormatter.format(props.price.max)}</div>
                <div className="est">Est.</div>
            </div>
            <div className="text">Based on the information provided we think we can find you firm offers within the
                above range. Work with one of our Carwiser Agents to get these offers through our trusted process.
            </div>
            <div className="text second-text">Sound good? Let get your firm offers!</div>

            <InputField field={firstNameField} formState={formState} setFormState={setFormState}/>
            <InputField field={lastNameField} formState={formState} setFormState={setFormState}/>
            <InputMask
                mask="+1 (999) 999-9999"
                value={phoneField.value}
                onChange={event => inputChangedHandler(formState, setFormState, event.target.value, phoneField.key)}
            >
                {() => <div className="input-wrapper"><TextField
                    name={phoneField.key}
                    value={phoneField.value}
                    type={'input'}
                    label={phoneField.label}
                    variant="filled"
                    size={"small"}
                    fullWidth
                    helperText={phoneField.helperText}
                    error={!phoneField.valid && phoneField.touched}/>
                    {!phoneField.valid && phoneField.touched && <ErrorImg className="errorImg"  alt="error img" />}
                    {phoneField.touched && phoneField.valid && <CheckImg className="errorImg" alt="check img" />}
                </div>}
            </InputMask>
            <CheckBoxField
                label={(
                    <>
                        I agree to Carwiser’s&nbsp;
                        <a className="linkLabel" href={'https://carwiser.com/terms.html'} target="_blank" rel="noreferrer">Terms of Service</a>
                        &nbsp;&&nbsp;
                        <a className="linkLabel" href={'https://carwiser.com/privacy.html'} target="_blank" rel="noreferrer">Privacy Policy</a>
                    </>
                )}
                field={agreeField}
                formState={formState}
                setFormState={setFormState}
            />
            <div className="save-button-wrapper">
                <CircularProgressButton
                    id={generateTag('save-and-continue', {prefix: props.currentStep, suffix: 'btn'})}
                    text="SAVE & CONTINUE"
                    isLoading={props.loading}
                    isDisabled={!isSubmitEnabled}
                    onClick={(event) => submitHandler(event)}
                />
            </div>
            <div className="footer-wrapper">
                <Footer/>
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        vehicle: state.qualifyVehicle.vehicle,
        seller: state.qualifyVehicle.seller,
        price: state.qualifyVehicle.price,
        queryParams: state.queryParams,
        currentStep: state.qualifyVehicle.currentStep,
        loading: state.app.qualifyVehicle.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onNext: (data, cbOnError, cbOnSuccess) => dispatch(actions.saveQuestions(data, cbOnError, cbOnSuccess)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OffersScreenRange);