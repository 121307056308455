import './index.css'
import AppCloseButton from "../AppCloseButton";


function Header() {
	return (
		<div className="header-block">
			<div className="carwiser-lets-you-div">
				CARWISER LETS YOU
				<br/>
				COMPARE &amp; CHOOSE MULTIPLE <br/>
				OFFERS IN MINUTES.
			</div>
			<div className="this-should-only-take-div">
				This should only take about 3 minutes.
			</div>
		</div>
	)
}

export default Header