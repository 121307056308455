import React from 'react'
import './index.css'
import { MainWrapperIdName, Scenario } from '../../../constants'
import { generateTag, inputChangedHandler } from '../../../shared/utility'

function VinOrYearSwitcher(props) {
	const {formState, setFormState, firstBlock, secondBlock} = props
	const vinOrLicense = [Scenario.Vin, Scenario.LicensePlate].includes(formState.controls.scenario.value)

	return (
		<div className="vin-or-year-switcher-wrapper">
			{vinOrLicense && firstBlock}
			{!vinOrLicense && secondBlock}
			{vinOrLicense
				? <div className="wrapper-block">
					<div className="first-text">
						Can’t find your Plate or VIN?
					</div>
					<div
						id={generateTag('get-estimate-ymm', {suffix: 'link'})}
						className="click-text"
						onClick={() => inputChangedHandler(formState, setFormState, Scenario.MakeModelYear, 'scenario')}
					>
						Get an estimate using Year, Make, &amp; Model
					</div>
				</div>
				: null
			}
			{!vinOrLicense
				? <div className="wrapper-block">
					<div className="first-text">
						Get real offers and save time later by using your
					</div>
					<div
						id={generateTag('license-plate-or-vin', {suffix: 'link'})}
						className="click-text"
						onClick={() => inputChangedHandler(formState, setFormState, Scenario.LicensePlate, 'scenario')}
					>
						License Plate or VIN
					</div>
				</div>
				: null
			}
		</div>
	)
}

export default VinOrYearSwitcher