import React from "react";
import './index.css';
import ProgressBar from '../ProgressBar';
import { ReactComponent as ArrowBack } from "../../../img/arrow-back.svg";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import AppCloseButton from "../AppCloseButton";
import { MainWrapperIdName, Steps } from '../../../constants'
import { generateTag } from '../../../shared/utility'

const  HeaderWithProgress = (props) => {

    return (
        <div className="header-with-bar-wrapper">
            <div className="buttons-block">
                <div
                    id={generateTag('back', {prefix: props.getPrevStep(props.currentStep), suffix: 'btn'})}
                    className="back-button"
                    onClick={() => props.onBack()}
                >
                    <ArrowBack className="back-img"/>
                    BACK
                </div>
                <AppCloseButton />
            </div>
            <ProgressBar progressValue={props.progressValue} />
            <hr className="line" />
        </div>
    )}


const mapStateToProps = state => {
    return {
        qualifyVehicle: state.qualifyVehicle,
        currentStep: state.qualifyVehicle.currentStep,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onBack: () => dispatch(actions.backQualifyVehicle()),
        getPrevStep: (currentStep) => {
            const accordionSteps = [
                Steps.EntryData,
                Steps.History,
                Steps.Exterior,
                Steps.Mechanical,
                Steps.Additional,
            ]
            let prevIndex
            const index = accordionSteps.indexOf(currentStep)
            if (index) {
                prevIndex = index - 1
            }

            return accordionSteps[prevIndex] ?? Steps.History
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderWithProgress);