import axios from "../axios";
import {validateResponse} from "../shared/utility";
import VehicleHelper from "../shared/VehicleHelper";

export default class ServiceLongForm {
    static validateResponse(response) {
        return [200, 201].includes(response.status) && response.data && !response.data.error
    }

    static async getImageByStyle(styleId) {
        const response = await axios.get(`chromedata/images/${styleId}`)

        try {
            if (this.validateResponse(response)) {
                if (response.data && !response.data.error) {
                    if (response.data) {
                        if(!response.data.results)
                            return null
                        const imagesArray = response.data?.results?.colorized ? response.data?.results?.colorized : []
                        const images = imagesArray.filter(el => {
                                return el['@backgroundDescription'] === 'Transparent' && el['@width'] === '320'
                        })
                        return response.data.results.colorized = images
                    } else {
                        throw new Error('Vehicle not found. Please check the VIN')
                    }
                } else {
                    throw new Error('Invalid ChromeData CVD Response')
                }
            } else {
                throw new Error('ChromeData CVD API Error')
            }
        } catch (e) {
            console.log('e', e);
            throw new Error('We are unable to decode this VIN. Apologies for the inconvenience!');
        }
    }

    static getPrice = async (data) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        let response
        try {
            response = await axios.post(`/blackbook/evaluate`, {vehicle: data}, {
                headers: headers,
            })
        } catch (e) {
            console.log('e', e);
            if (e.response?.data.message === 'No BlackBook Data') {
                return {
                    vehicle: {
                        ...data,
                        widgetPriceMin: null,
                        widgetPriceMax: null,
                    },
                    matchingClients: [],
                }
            }
        }

        try {
            if (validateResponse(response)) {
                return response.data
            } else {
                throw new Error('Evaluation Error')
            }
        } catch (e) {
            console.log('e', e);
            throw new Error('We are unable to evaluate your vehicle!');
        }
    }

    static getMatchingClients = async (data) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        let response
        try {
            response = await axios.post(`/carwiser/matchingClients`, {vehicle: data}, {
                headers: headers,
            })
        } catch (e) {
            console.log('e', e);
        }

        try {
            if (validateResponse(response)) {
                return response.data
            } else {
                throw new Error('Carwiser Service Error')
            }
        } catch (e) {
            console.log('e', e);
            throw new Error('We are unable to get matching clients for your vehicle!');
        }
    }

    static getProviders = async () => {
        const headers = {
            'Content-Type': 'application/json',
        }

        let response
        try {
            response = await axios.get(`/carwiser/offer-providers`, {
                headers: headers,
            })
        } catch (e) {
            console.log('e', e);
        }

        try {
            if (validateResponse(response)) {
                return response.data?.offerProviders ? response.data.offerProviders : []
            } else {
                throw new Error('Carwiser Service Error')
            }
        } catch (e) {
            console.log('e', e);
            throw new Error('We are unable to make an offer for your vehicle!');
        }
    }

    static getOffers = async (data) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        let response
        try {
            response = await axios.post(`/carwiser/offers`, data,{
                headers: headers,
            })
        } catch (e) {
            console.log('e', e);
        }

        try {
            if (validateResponse(response)) {
                return response.data
            } else {
                throw new Error('Carwiser Service Error')
            }
        } catch (e) {
            console.log('e', e);
            throw new Error('We are unable to make an offer for your vehicle!');
        }
    }

    static getSubscriber = async (email) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        let response
        try {
            response = await axios.post(`/carwiser/subscriber`, {email: email},{
                headers: headers,
            })
        } catch (e) {
            console.log('e', e);
        }

        try {
            if (validateResponse(response)) {
                return response.data
            } else {
                throw new Error('Carwiser Service Error')
            }
        } catch (e) {
            console.log('e', e);
            throw new Error('We are unable to check the email address');
        }
    }

    static onOffersGenerated = async (data, offers) => {
        const images = VehicleHelper.getVehicleImages(data.vehicle.images, data.vehicle.exteriorColor)
        const imageUrl = images.length ? images[0]['@href']?.replace(/http/i, 'https') : null
        const  body = {
            vehicle: {
                make: data.vehicle.make,
                model: data.vehicle.model,
                model_year: data.vehicle.model_year,
                imageUrl,
            },
            email : data.seller.email ,
            firstName: data.seller.firstName? data.seller.firstName: '',
            lastName: data.seller.lastName? data.seller.lastName : '',
            phone: data.seller.phone,
            formUUID: data.formUUID,
            offers
        }
        const tryCount = 3;
        let attempt = 0;
        let response;
        while(attempt++ < tryCount) {
            // console.log('attempt #', attempt);
            response = await this.sendOffersGenerated(body);
            if (response !== false) {
                break;
            }
        }
        if (response === false) {
            console.log('Carwiser Service Error');
        }
    }

    static sendOffersGenerated = async(body) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        let response;
        try {
            response = await axios.post(`/sendinblue/offers-generated`, body,{
                headers,
            });
        } catch (e) {
            console.log('e', e);
            response = e.response;
        }

        const data = response ? response.data : undefined;
        if (data && data.statusCode === 400 && data.error.includes('Can not add contact email to listId')) {
            return false;
        }

        if (!validateResponse(response)) {
            return false;
        }

        return data;
    }

    static onNoDealers = async (data) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        let response

        const  body = {
            email : data.seller.email ,
            firstName: data.seller.firstName? data.seller.firstName: '',
            lastName: data.seller.lastName? data.seller.lastName : '',
        }
        try {

            response = await axios.post(`/sendinblue/no-dealers`, body,{
                headers: headers,
            })
        } catch (e) {
            console.log('e', e);
        }

        try {
            if (validateResponse(response)) {
                return response.data
            } else {
                throw new Error('Carwiser Service Error')
            }
        } catch (e) {
            console.log('e', e);
        }
    }

    static onOfferRangeCalculated = async (data) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        let response

        const  body = {
            email : data.seller.email ,
            firstName: data.seller.firstName? data.seller.firstName: '',
            lastName: data.seller.lastName? data.seller.lastName : '',
            phone: data.seller.phone,
        }
        try {

            response = await axios.post(`/sendinblue/offer-range-calculated`, body,{
                headers: headers,
            })
        } catch (e) {
            console.log('e', e);
        }

        try {
            if (validateResponse(response)) {
                return response.data
            } else {
                throw new Error('Carwiser Service Error')
            }
        } catch (e) {
            console.log('e', e);
        }
    }

    static getStores = async (locationIds = []) => {
        let response

        try {
            response = await axios.post(
                `/stores/byLocationIds`,
                {
                    ids: locationIds,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );
        } catch (e) {
            console.log('e', e);
        }

        try {
            if (this.validateResponse(response)) {
                return response.data ? response.data : []
            } else {
                throw new Error('Carwiser IOC Service Error: '+response.data.error)
            }
        } catch (e) {
            console.log('e', e);
            throw new Error('We are unable to get the stores settings!');
        }
    }

	static async getByVin(vin) {
		const response = await axios.get(`chromedata/${vin}`)

		try {
			if (this.validateResponse(response)) {
				if (response.data && response.data.success) {
					if (response.data.data.vin) {
						return response.data.data
					} else {
						throw new Error('Vehicle not found. Please check the VIN')
					}
				} else {
					throw new Error('Invalid ChromeData CVD Response')
				}
			} else {
				throw new Error('ChromeData CVD API Error')
			}
		} catch (e) {
			console.log('e', e);
			throw new Error('We are unable to decode this VIN. Apologies for the inconvenience!');
		}
	}

	static async getYearMakes() {
		const years = [];
		for (let year = 1992, limit = (new Date()).getFullYear()+1; year <= limit; year++) {
			years.push({id: Number(year), name: String(year)})
		}
		years.reverse()

		return years;
	}

	static async getMakesByYear(year) {
		const response = await axios.get(`chromedata/vss/makes/${year}`);

		try {
			if (this.validateResponse(response)) {
				const responseData = response.data.data
				if (Array.isArray(responseData)) {
					const data = responseData.map(item => ({id: item.id, name: item.name}))
					return data
				} else {
					throw new Error('Response format is wrong')
				}
			} else {
				throw new Error('ChromeData VSS API Error')
			}
		} catch (e) {
			console.log('e', e);
			throw new Error('We are unable to decode this VIN. Apologies for the inconvenience!');
		}
	}

	static async getModels(yearId, makeId) {
		const response = await axios.get(`chromedata/vss/models/${yearId}/${makeId}`);

		try {

			if (this.validateResponse(response)) {
				const responseData = response.data.data
				if (Array.isArray(responseData)) {
					const data = responseData.map(item => ({id: item.id, name: item.name}))
					return data
				} else {
					throw new Error('Response format is wrong')
				}
			} else {
				throw new Error('ChromeData VSS API Error')
			}
		} catch (e) {
			console.log('e', e);
			throw new Error('We are unable to decode this VIN. Apologies for the inconvenience!');
		}
	}

	static async getStylesByYMM(year, make, model) {
		const response = await axios.get(`chromedata/vss/styles/${year}/${make}/${model}`)

		try {
			if (this.validateResponse(response)) {
				const responseData = response.data.data
				if (responseData) {
					return responseData
				} else {
					throw new Error('Invalid ChromeData VSS Response')
				}
			} else {
				throw new Error('ChromeData VSS API Error')
			}
		} catch (e) {
			console.log('e', e);
			throw new Error('We are unable to decode this vehicle. Apologies for the' +
				' inconvenience!');
		}
	}

	static async getVehicleDescriptionByStyle(styleId) {
		const response = await axios.get(`chromedata/vss/style/${styleId}`)

		try {
			if (this.validateResponse(response)) {
				const responseData = response.data.data
				if (responseData) {
					return responseData
				} else {
					throw new Error('Response format is wrong')
				}
			} else {
				throw new Error('ChromeData VSS API Error')
			}
		} catch (e) {
			console.log('e', e);
			throw new Error('We are unable to decode this VIN. Apologies for the inconvenience!');
		}
	}

}