import './index.css';
import {Box, InputAdornment, Slider, Stack, TextField} from '@mui/material'
import {inputChangedHandler} from '../../../../shared/utility'
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import {MoneyFormatter} from '../../../../shared/formatter'

const config = {
	slider: {
		min: 0,
		max: 100000,
		step: 1000,
	},
	input: {
		min: 0,
		max: 300000,
	}
}

function AskingPrice(props) {
	const {
		id = null,
		className = null,
		field,
		formState,
		setFormState,
		onChange
	} = props;

	const questionTitle = field ? field.label : props.questionTitle
	const defaultHelperText = field?.defaultHelperText ?? null

	const handleChange = (value) => {
		if (onChange) {
			onChange(value)
		}
		else {
			inputChangedHandler(formState, setFormState, value, field.key)
		}
	}

	const handleSliderChange = (event, floatValue) => {
		if ([undefined, null, 0].includes(floatValue)) {
			floatValue = null;
		}
		handleChange(floatValue);
	}

	const handleInputChange = (event) => {
		let floatValue = event.floatValue
		if ([undefined, null].includes(floatValue)) {
			floatValue = null;
		}
		handleChange(floatValue);
	}

	useEffect(() => {
		if (field.value > config.input.max) {
			handleChange(config.input.max);
		}
		if (field.value < config.input.min) {
			handleChange(config.input.min);
		}
	}, [field.value])

	return (
		<>
			<Box className={'question-block'}>
				<Box className={'question-title'}>{questionTitle}</Box>
				{defaultHelperText &&
					<Box className={'question-subtitle'}>{defaultHelperText}</Box>
				}
				<Stack className='asking-price' direction={'row'} gap={'20px'}>
					<Box sx={{px: 1}} flex={1}>
						<Slider
							step={config.slider.step}
							min={config.slider.min}
							max={config.slider.max}
							value={field.value}
							marks={[
								{
									value: config.slider.min,
									label: `${MoneyFormatter.format(config.slider.min)}`
								},
								{
									value: config.slider.max,
									label: `${MoneyFormatter.format(config.slider.max)}`
								},
							]}
							onChange={handleSliderChange}
							valueLabelDisplay={'off'}
						/>
					</Box>
					<Box>
						<NumericFormat
							value={field.value ?? ''}
							label={'Amount'}
							onValueChange={handleInputChange}
							allowNegative={false}
							decimalScale={0}
							customInput={TextField}
							thousandSeparator
							InputProps={{
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
							}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Box>
				</Stack>
			</Box>

		</>
	)
}

export default AskingPrice;