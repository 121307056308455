import './index.css';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import Question from "../../components/ui/Question";
import {generateTag, getDependencyControls, getFormData, prepareFormData, validateForm} from '../../shared/utility'
import {useEffect, useState} from "react";
import Answer from "../../components/ui/Question/Answer";
import CircularProgressButton from "../../components/ui/CircularProgress";
import useLocalState from '../../hooks/useLocalState'
import {HeapTagClassName, Steps} from '../../constants'
import {
    isAnythingBrokenSelected,
    isDamageOtherGlassLocatedSelected,
    isDamageOtherGlassSelected,
    isVehicleDamageFieldSelected,
    isVisibleDefectsLargeLocatedSelected,
    isVisibleDefectsLargeSelected,
    isVisibleDefectsSelected
} from "../../shared/formHelper";
import {prepareAnswersFromState, prepareDamageAnswers} from '../../store/actions/qualifyVehicleQuestions'

let formData = {
    controls: {
        visibleDefects: {
            label: 'Are there any minor defects or damage to the body panels?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            groupValidation: 'visibleDefects',
            valid: false,
            touched: false,
            defaultHelperText: "E.g., dings, scuffs, chips",
            helperText: null,
        },
        visibleDefectsLarge: {
            label: 'Is there any large, visible damage to the body panels?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            groupValidation: 'visibleDefectsLarge',
            valid: false,
            touched: false,
            defaultHelperText: "Damage larger than the size of a baseball. Body panels that are loose or missing.",
            helperText: null,
        },
        replaceWindshield: {
            label: 'Does the windshield need to be replaced?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            valid: false,
            touched: false,
            defaultHelperText: "E.g., cracks, chips, heavy scratches",
            helperText: null,
        },
        damageOtherGlass: {
            label: 'Any damage to the other windows, mirrors, or lights?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            groupValidation: 'damageOtherGlass',
            valid: false,
            touched: false,
            defaultHelperText: "E.g., cracked taillight, broken side mirror",
            helperText: null,
        },
        visibleDefectsHailDamage: {
            label: 'Hail Damage',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'vehicleDamage',
            type: 'boolean'
        },
        floodDamaged: {
            label: 'Flood Damage',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'vehicleDamage',
            type: 'mixed'
        },
        fireDamage: {
            label: 'Fire Damage',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'vehicleDamage',
            type: 'mixed'
        },
        undercarriageDamaged: {
            label: 'Undercarriage Damage',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'vehicleDamage',
            type: 'mixed'
        },
        noDamage: {
            label: 'None of the Above',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'vehicleDamage',
        },
        visibleDefectsDings: {
            label: 'Dings',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'visibleDefects',
            type: 'boolean'
        },
        visibleDefectsDents: {
            label: 'Dents',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'visibleDefectsLarge',
            type: 'boolean'
        },
        visibleDefectsScuffs: {
            label: 'Scuffs',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'visibleDefects',
            type: 'boolean'
        },
        visibleDefectsScratches: {
            label: 'Scratches',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'visibleDefectsLarge',
            type: 'boolean'
        },
        visibleDefectsChippedPaint: {
            label: 'Chipped Paint',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'visibleDefects',
            type: 'boolean'
        },
        visibleDefectsRust: {
            label: 'Rust',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'visibleDefectsLarge',
            type: 'boolean'
        },
        visibleDefectsLooseMissingPanels: {
            label: 'Loose/Missing Panel',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'visibleDefectsLarge',
            type: 'boolean'
        },
        visibleDefectsFadedPaint: {
            label: 'Faded Paint',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'visibleDefectsLarge',
            type: 'boolean'
        },
        visibleDefectsLargeFDS: {
            label: 'Front-driver side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'visibleDefectsLargeLocated',
            type: 'boolean'
        },

        visibleDefectsLargeFPS: {
            label: 'Front-passenger side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'visibleDefectsLargeLocated',
            type: 'boolean'
        },

        visibleDefectsLargeRDS: {
            label: 'Rear-driver side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'visibleDefectsLargeLocated',
            type: 'boolean'
        },

        visibleDefectsLargeRPS: {
            label: 'Rear-passenger side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'visibleDefectsLargeLocated',
            type: 'boolean'
        },

        visibleDefectsLargeTop: {
            label: 'Top/Roof',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'visibleDefectsLargeLocated',
            type: 'boolean'
        },

        damageOtherGlassChippedWindow: {
            label: 'Chipped window glass',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'damageOtherGlass',
            type: 'boolean'
        },
        damageOtherGlassCrackedWindow: {
            label: 'Cracked window glass',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'damageOtherGlass',
            type: 'boolean'
        },
        damageOtherGlassMirrors: {
            label: 'Damaged/missing mirrors',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'damageOtherGlass',
            type: 'boolean'
        },
        damageOtherGlassLights: {
            label: 'Damaged/missing lights',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'damageOtherGlass',
            type: 'boolean'
        },

        damageOtherGlassFDS: {
            label: 'Front-driver side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'damageOtherGlassLocated',
            type: 'boolean'
        },
        damageOtherGlassFPS: {
            label: 'Front-Passenger Side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'damageOtherGlassLocated',
            type: 'boolean'
        },
        damageOtherGlassRDS: {
            label: 'Rear-driver side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'damageOtherGlassLocated',
            type: 'boolean'
        },
        damageOtherGlassRPS: {
            label: 'Rear-passenger side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'damageOtherGlassLocated',
            type: 'boolean'
        },

        smokedIn: {
            label: 'Has the vehicle been smoked in?',
            value: null,
            defaultValue: null,
            values: [
                {key: 'no', value: 'No'},
                {key: 'yes', value: 'Yes'},
            ],
            validation: {
                presence: {allowEmpty: false},
            },
            valid: false,
            touched: false,
            helperText: null,
        },
        anyBurnsStains  : {
            label: 'Do the seats have any burns, stains, rips, or tears?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            valid: false,
            touched: false,
            helperText: null,
        },
        anythingBroken: {
            label: 'Is anything damaged, worn, broken, or inoperable?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            groupValidation: 'anythingBroken',
            valid: false,
            touched: false,
            defaultHelperText: 'E.g., Broken radio, window issues, power seat issues',
            helperText: null,
        },
        anythingBrokenPowerWindow: {
            label: 'Power window problems',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'anythingBroken',
            type: 'boolean'
        },
        anythingBrokenPowerSeat: {
            label: 'Power seat problem',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'anythingBroken',
            type: 'boolean'
        },
        anythingBrokenAntennae: {
            label: 'Antennae problem',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'anythingBroken',
            type: 'boolean'
        },
        anythingBrokenWipers: {
            label: 'Wipers inoperable',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'anythingBroken',
            type: 'boolean'
        },
        anythingBrokenTouchscreen: {
            label: 'Touchscreen broken',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'anythingBroken',
            type: 'boolean'
        },
        anythingBrokenRadio: {
            label: 'Radio broken',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'anythingBroken',
            type: 'boolean'
        },
        anythingBrokenMissingBrokenPieces: {
            label: 'Missing or broken pieces',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'anythingBroken',
            type: 'boolean'
        },
        anythingBrokenStained: {
            label: 'Stained',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'anythingBroken',
            type: 'boolean'
        },
        anythingBrokenDirty: {
            label: 'Dirty',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'anythingBroken',
            type: 'boolean'
        },
        anythingBrokenOther: {
            label: 'Other interior issues',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'anythingBroken',
            type: 'boolean',
        },

    },

    loading: false,
    valid: false,
    touched: false,
    generalError: null

}
const ExteriorCondition = (props) => {
    const initialData = prepareFormData(formData, {})
     // const [formState, setFormState] = useState(initialData);
    const [formState, setFormState] = useLocalState(Steps.Exterior, initialData);

    const [isSubmitEnabled, setSubmitEnabled] = useState(false)

    const visibleDefectsField = formState.controls.visibleDefects;
    const visibleDefectsLargeField = formState.controls.visibleDefectsLarge;
    const replaceWindshieldField = formState.controls.replaceWindshield
    const damageOtherGlassField = formState.controls.damageOtherGlass
    const floodDamagedField = formState.controls.floodDamaged
    const fireDamageField = formState.controls.fireDamage
    const undercarriageDamagedField = formState.controls.undercarriageDamaged
    const visibleDefectsHailDamageField = formState.controls.visibleDefectsHailDamage
    const noDamageField = formState.controls.noDamage

    const visibleDefectsDingsField = formState.controls.visibleDefectsDings
    const visibleDefectsDentsField = formState.controls.visibleDefectsDents
    const visibleDefectsScuffsField = formState.controls.visibleDefectsScuffs
    const visibleDefectsScratchesField = formState.controls.visibleDefectsScratches
    const visibleDefectsChippedPaintField = formState.controls.visibleDefectsChippedPaint
    const visibleDefectsFadedPaintField = formState.controls.visibleDefectsFadedPaint
    const visibleDefectsRustField = formState.controls.visibleDefectsRust
    const visibleDefectsLooseMissingPanelsField = formState.controls.visibleDefectsLooseMissingPanels

    const visibleDefectsLargeFDSField = formState.controls.visibleDefectsLargeFDS
    const visibleDefectsLargeFPSField = formState.controls.visibleDefectsLargeFPS
    const visibleDefectsLargeRDSField = formState.controls.visibleDefectsLargeRDS
    const visibleDefectsLargeRPSField = formState.controls.visibleDefectsLargeRPS
    const visibleDefectsLargeTopField = formState.controls.visibleDefectsLargeTop

    const damageOtherGlassChippedWindowField = formState.controls.damageOtherGlassChippedWindow
    const damageOtherGlassCrackedWindowField = formState.controls.damageOtherGlassCrackedWindow
    const damageOtherGlassMirrorsField = formState.controls.damageOtherGlassMirrors
    const damageOtherGlassLightsField = formState.controls.damageOtherGlassLights

    const damageOtherGlassFDSField = formState.controls.damageOtherGlassFDS
    const damageOtherGlassFPSField = formState.controls.damageOtherGlassFPS
    const damageOtherGlassRDSField = formState.controls.damageOtherGlassRDS
    const damageOtherGlassRPSField = formState.controls.damageOtherGlassRPS

    const smokedInField = formState.controls.smokedIn

    const anyBurnsStainsField = formState.controls.anyBurnsStains
    const anythingBrokenField = formState.controls.anythingBroken

    const anythingBrokenPowerWindowField = formState.controls.anythingBrokenPowerWindow
    const anythingBrokenPowerSeatField = formState.controls.anythingBrokenPowerSeat
    const anythingBrokenAntennaeField = formState.controls.anythingBrokenAntennae
    const anythingBrokenWipersField = formState.controls.anythingBrokenWipers
    const anythingBrokenTouchscreenField = formState.controls.anythingBrokenTouchscreen
    const anythingBrokenRadioField = formState.controls.anythingBrokenRadio
    const anythingBrokenMissingBrokenPiecesField = formState.controls.anythingBrokenMissingBrokenPieces
    const anythingBrokenStainedField = formState.controls.anythingBrokenStained
    const anythingBrokenDirtyField = formState.controls.anythingBrokenDirty
    const anythingBrokenOtherField = formState.controls.anythingBrokenOther

    const checkForm = () => {
        if (!isVisibleDefectsSelected(visibleDefectsField, formState.controls)) return false
        if (!isVisibleDefectsLargeSelected(visibleDefectsLargeField, formState.controls)) return false
        if (!isVisibleDefectsLargeLocatedSelected(visibleDefectsLargeField, formState.controls)) return false
        if (!isDamageOtherGlassSelected(damageOtherGlassField, formState.controls)) return false
        if (!isDamageOtherGlassLocatedSelected(damageOtherGlassField, formState.controls)) return false
        if (!isVehicleDamageFieldSelected(formState.controls)) return false
        if (!isAnythingBrokenSelected(anythingBrokenField, formState.controls)) return false

        const excludeFields = []

        return validateForm(formState, setFormState, excludeFields, false)
    }

    useEffect(() => {
        const progress = countProgress()
        props.setStep(prev => ({...prev, ...progress}))
    }, [formState])

    const countProgress = () => {
        let totalSteps = 8
        let steps = 0
        let fields = []

        const isValid = (field) => {
            return (!field.touched || (field.touched && field.valid))
        }

        fields.push(visibleDefectsField.key)
        fields.push(visibleDefectsLargeField.key)
        fields.push(replaceWindshieldField.key)
        fields.push(damageOtherGlassField.key)
        fields.push(floodDamagedField.key)
        fields.push(fireDamageField.key);

        [
            visibleDefectsField,
            visibleDefectsLargeField,
            replaceWindshieldField,
            damageOtherGlassField,
        ].forEach((el) => {
            if (el.value !== null && el.value !== '' && isValid(el)) {
                steps++
            }
        })

        if (visibleDefectsField.value === "yes") {
            totalSteps++
            if (isVisibleDefectsSelected(visibleDefectsField, formState.controls)) {
                steps++
            }
        }

        if (visibleDefectsLargeField.value === "yes") {
            totalSteps += 2
            if (isVisibleDefectsLargeSelected(visibleDefectsLargeField, formState.controls)) {
                steps++
            }
            if (isVisibleDefectsLargeLocatedSelected(visibleDefectsLargeField, formState.controls)) {
                steps++
            }
        }

        if (damageOtherGlassField.value === "yes") {
            totalSteps += 2
            if (isDamageOtherGlassSelected(damageOtherGlassField, formState.controls)) {
                steps++
            }
            if (isDamageOtherGlassLocatedSelected(damageOtherGlassField, formState.controls)) {
                steps++
            }
        }

        if (isVehicleDamageFieldSelected(formState.controls)) {
            steps++
        }

        fields.push(anyBurnsStainsField.key)
        fields.push(anythingBrokenField.key)
        fields.push(smokedInField.key);
        [
            anyBurnsStainsField,
            anythingBrokenField,
            smokedInField,
        ].forEach((el) => {
            if (el.value !== null && el.value !== '' && isValid(el)) {
                steps++
            }
        })

        if (anythingBrokenField.value === 'yes') {
            totalSteps++
            if (isAnythingBrokenSelected(anythingBrokenField, formState.controls)) {
                steps++
            }
        }

        return {totalSteps, steps, fields, isValid: checkForm()}
    }

    useEffect(() => {
        setSubmitEnabled(checkForm())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState])


    const submitHandler = async (event) => {
        event.preventDefault();
        if (validateForm(formState, setFormState, [])) {
            const data = getFormData(formState)

            data.xchangeData = {
                answers: prepareAnswersFromState(formData, data, (answersData, answers) => {
                    return prepareDamageAnswers(answersData, answers)
                })
            }

            props.onNext(data)
        }
    }

    const heapTagClassNameOptions = {mainPrefix: HeapTagClassName, prefix: props.currentStep}

    return (
        <div className="exterior-condition-wrapper">
            <Question
                id={generateTag(visibleDefectsField.key, {snippet: '{%option%}', suffix: 'btn'})}
                className={generateTag(visibleDefectsField.key, heapTagClassNameOptions)}
                field={visibleDefectsField} formState={formState} setFormState={setFormState} isInOneLine
                dependencyControls={getDependencyControls(visibleDefectsField, formState)}
            />

            {visibleDefectsField.value === "yes" &&
                <>
                    <div className="subquestions">
                        <div className="question-title" style={{width: '100%'}}>What minor defects are there?</div>
                        <div className='question-subtitle'>Select all that apply</div>
                        <Answer
                            id={generateTag(visibleDefectsDingsField.key, {suffix: 'btn'})}
                            className={generateTag(visibleDefectsDingsField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={visibleDefectsDingsField} formState={formState}
                            setFormState={setFormState}
                            dependencyControls={[]}
                        />
                        <Answer
                            id={generateTag(visibleDefectsScuffsField.key, {suffix: 'btn'})}
                            className={generateTag(visibleDefectsScuffsField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={visibleDefectsScuffsField} formState={formState}
                            setFormState={setFormState}/>
                        <Answer
                            id={generateTag(visibleDefectsChippedPaintField.key, {suffix: 'btn'})}
                            className={generateTag(visibleDefectsChippedPaintField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={visibleDefectsChippedPaintField} formState={formState}
                            setFormState={setFormState}/>
                    </div>
                </>
            }
            <Question
                id={generateTag(visibleDefectsLargeField.key, {snippet: '{%option%}', suffix: 'btn'})}
                className={generateTag(visibleDefectsLargeField.key, heapTagClassNameOptions)}
                field={visibleDefectsLargeField} formState={formState} setFormState={setFormState}
                isInOneLine
                dependencyControls={
                    getDependencyControls(visibleDefectsLargeField, formState)
                        .concat(getDependencyControls({groupValidation: 'visibleDefectsLargeLocated'}, formState))
                }
            />

            {visibleDefectsLargeField.value === "yes" &&
                <>
                    <div className="subquestions">
                        <div className="question-title" style={{width: '100%'}}>What is damaged?</div>
                        <div className='question-subtitle'>Select all that apply</div>
                        <Answer id={generateTag(visibleDefectsDentsField.key, {suffix: 'btn'})}
                                className={generateTag(visibleDefectsDentsField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={visibleDefectsDentsField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(visibleDefectsScratchesField.key, {suffix: 'btn'})}
                                className={generateTag(visibleDefectsScratchesField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={visibleDefectsScratchesField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(visibleDefectsFadedPaintField.key, {suffix: 'btn'})}
                                className={generateTag(visibleDefectsFadedPaintField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={visibleDefectsFadedPaintField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(visibleDefectsRustField.key, {suffix: 'btn'})}
                                className={generateTag(visibleDefectsRustField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={visibleDefectsRustField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(visibleDefectsLooseMissingPanelsField.key, {suffix: 'btn'})}
                                className={generateTag(visibleDefectsLooseMissingPanelsField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={visibleDefectsLooseMissingPanelsField} formState={formState}
                                setFormState={setFormState}/>
                    </div>

                    <div className="subquestions subquestions-nested">
                        <div className="question-title" style={{width: '100%'}}>Where is the damage located?</div>
                        <div className='question-subtitle'>Select the most accurate areas of the vehicle</div>
                        <Answer id={generateTag(visibleDefectsLargeFDSField.key, {suffix: 'btn'})}
                                className={generateTag(visibleDefectsLargeFDSField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={visibleDefectsLargeFDSField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(visibleDefectsLargeFPSField.key, {suffix: 'btn'})}
                                className={generateTag(visibleDefectsLargeFPSField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={visibleDefectsLargeFPSField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(visibleDefectsLargeRDSField.key, {suffix: 'btn'})}
                                className={generateTag(visibleDefectsLargeRDSField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={visibleDefectsLargeRDSField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(visibleDefectsLargeRPSField.key, {suffix: 'btn'})}
                                className={generateTag(visibleDefectsLargeRPSField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={visibleDefectsLargeRPSField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(visibleDefectsLargeTopField.key, {suffix: 'btn'})}
                                className={generateTag(visibleDefectsLargeTopField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={visibleDefectsLargeTopField} formState={formState}
                                setFormState={setFormState}/>
                    </div>
                </>
            }
            <Question id={generateTag(replaceWindshieldField.key, {snippet: '{%option%}', suffix: 'btn'})}
                      className={generateTag(replaceWindshieldField.key, heapTagClassNameOptions)}
                      field={replaceWindshieldField} formState={formState} setFormState={setFormState}
                      isInOneLine/>

            <Question id={generateTag(damageOtherGlassField.key, {snippet: '{%option%}', suffix: 'btn'})}
                      className={generateTag(damageOtherGlassField.key, heapTagClassNameOptions)}
                      field={damageOtherGlassField} formState={formState} setFormState={setFormState}
                      isInOneLine
                      dependencyControls={getDependencyControls(damageOtherGlassField, formState).concat(getDependencyControls({groupValidation: 'damageOtherGlassLocated'}, formState))}
            />
            {damageOtherGlassField.value === "yes" &&
                <>
                    <div className="subquestions">
                        <div className="question-title" style={{width: '100%'}}>What is damaged?</div>
                        <div className='question-subtitle'>Select all that apply</div>
                        <Answer id={generateTag(damageOtherGlassChippedWindowField.key, {suffix: 'btn'})}
                                className={generateTag(damageOtherGlassChippedWindowField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={damageOtherGlassChippedWindowField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(damageOtherGlassCrackedWindowField.key, {suffix: 'btn'})}
                                className={generateTag(damageOtherGlassCrackedWindowField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={damageOtherGlassCrackedWindowField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(damageOtherGlassMirrorsField.key, {suffix: 'btn'})}
                                className={generateTag(damageOtherGlassMirrorsField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={damageOtherGlassMirrorsField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(damageOtherGlassLightsField.key, {suffix: 'btn'})}
                                className={generateTag(damageOtherGlassLightsField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={damageOtherGlassLightsField} formState={formState}
                                setFormState={setFormState}/>
                    </div>

                    <div className="subquestions subquestions-nested">
                        <div className="question-title" style={{width: '100%'}}>Where is the damage located?</div>
                        <div className='question-subtitle'>Select the most accurate areas of the vehicle</div>
                        <Answer id={generateTag(damageOtherGlassFDSField.key, {suffix: 'btn'})}
                                className={generateTag(damageOtherGlassFDSField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={damageOtherGlassFDSField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(damageOtherGlassFPSField.key, {suffix: 'btn'})}
                                className={generateTag(damageOtherGlassFPSField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={damageOtherGlassFPSField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(damageOtherGlassRDSField.key, {suffix: 'btn'})}
                                className={generateTag(damageOtherGlassRDSField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={damageOtherGlassRDSField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(damageOtherGlassRPSField.key, {suffix: 'btn'})}
                                className={generateTag(damageOtherGlassRPSField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={damageOtherGlassRPSField} formState={formState}
                                setFormState={setFormState}/>
                    </div>
                </>
            }

            <>
                <div className="subquestions">
                    <div className="question-title" style={{width: '100%'}}>Has any of the following happened to the
                        vehicle?
                    </div>
                    <div className='question-subtitle'>Select all that apply</div>
                    <Answer id={generateTag(visibleDefectsHailDamageField.key, {suffix: 'btn'})}
                            className={generateTag(visibleDefectsHailDamageField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={visibleDefectsHailDamageField} formState={formState}
                            setFormState={setFormState}
                            dependencyControls={[noDamageField.key]}
                    />
                    <Answer id={generateTag(undercarriageDamagedField.key, {suffix: 'btn'})}
                            className={generateTag(undercarriageDamagedField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={undercarriageDamagedField} formState={formState}
                            setFormState={setFormState}
                            dependencyControls={[noDamageField.key]}
                    />
                    <Answer id={generateTag(floodDamagedField.key, {suffix: 'btn'})}
                            className={generateTag(floodDamagedField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={floodDamagedField} formState={formState} setFormState={setFormState}
                            dependencyControls={[noDamageField.key]}
                    />
                    <Answer id={generateTag(fireDamageField.key, {suffix: 'btn'})}
                            className={generateTag(fireDamageField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={fireDamageField} formState={formState} setFormState={setFormState}
                            dependencyControls={[noDamageField.key]}
                    />
                    <Answer id={generateTag(noDamageField.key, {suffix: 'btn'})}
                            className={generateTag(noDamageField.key, {...heapTagClassNameOptions, suffix: 'answers'})}
                            field={noDamageField} formState={formState} setFormState={setFormState}
                            dependencyControls={[
                                visibleDefectsHailDamageField.key,
                                undercarriageDamagedField.key,
                                floodDamagedField.key,
                                fireDamageField.key,
                            ]}
                    />
                </div>
            </>
            <Question
                id={generateTag(smokedInField.key, {snippet: '{%option%}', suffix: 'btn'})}
                className={generateTag(smokedInField.key, heapTagClassNameOptions)}
                field={smokedInField} formState={formState} setFormState={setFormState} isInOneLine/>
            <Question
                id={generateTag(anyBurnsStainsField.key, {snippet: '{%option%}', suffix: 'btn'})}
                className={generateTag(anyBurnsStainsField.key, heapTagClassNameOptions)}
                field={anyBurnsStainsField} formState={formState} setFormState={setFormState} isInOneLine/>
            <Question
                id={generateTag(anythingBrokenField.key, {snippet: '{%option%}', suffix: 'btn'})}
                className={generateTag(anythingBrokenField.key, heapTagClassNameOptions)}
                field={anythingBrokenField} formState={formState} setFormState={setFormState} isInOneLine
                dependencyControls={getDependencyControls(anythingBrokenField, formState)}
            />

            {anythingBrokenField.value === 'yes' &&
                <>
                    <div className="subquestions">
                        <div className="question-subtitle">Select all that apply</div>
                        <Answer
                            id={generateTag(anythingBrokenPowerWindowField.key, {suffix: 'btn'})}
                            className={generateTag(anythingBrokenPowerWindowField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={anythingBrokenPowerWindowField} formState={formState} setFormState={setFormState}/>
                        <Answer
                            id={generateTag(anythingBrokenPowerSeatField.key, {suffix: 'btn'})}
                            className={generateTag(anythingBrokenPowerSeatField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={anythingBrokenPowerSeatField} formState={formState} setFormState={setFormState}/>
                        <Answer
                            id={generateTag(anythingBrokenAntennaeField.key, {suffix: 'btn'})}
                            className={generateTag(anythingBrokenAntennaeField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={anythingBrokenAntennaeField} formState={formState} setFormState={setFormState}/>
                        <Answer
                            id={generateTag(anythingBrokenWipersField.key, {suffix: 'btn'})}
                            className={generateTag(anythingBrokenWipersField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={anythingBrokenWipersField} formState={formState} setFormState={setFormState}/>
                        <Answer
                            id={generateTag(anythingBrokenTouchscreenField.key, {suffix: 'btn'})}
                            className={generateTag(anythingBrokenTouchscreenField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={anythingBrokenTouchscreenField} formState={formState} setFormState={setFormState}/>
                        <Answer
                            id={generateTag(anythingBrokenRadioField.key, {suffix: 'btn'})}
                            className={generateTag(anythingBrokenRadioField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={anythingBrokenRadioField} formState={formState} setFormState={setFormState}/>
                        <Answer
                            id={generateTag(anythingBrokenMissingBrokenPiecesField.key, {suffix: 'btn'})}
                            className={generateTag(anythingBrokenMissingBrokenPiecesField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={anythingBrokenMissingBrokenPiecesField} formState={formState} setFormState={setFormState}/>
                        <Answer
                            id={generateTag(anythingBrokenStainedField.key, {suffix: 'btn'})}
                            className={generateTag(anythingBrokenStainedField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={anythingBrokenStainedField} formState={formState} setFormState={setFormState}/>
                        <Answer
                            id={generateTag(anythingBrokenDirtyField.key, {suffix: 'btn'})}
                            className={generateTag(anythingBrokenDirtyField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={anythingBrokenDirtyField} formState={formState} setFormState={setFormState}/>
                        <Answer
                            id={generateTag(anythingBrokenOtherField.key, {suffix: 'btn'})}
                            className={generateTag(anythingBrokenOtherField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={anythingBrokenOtherField} formState={formState} setFormState={setFormState}/>
                    </div>
                </>
            }
            <div className="save-button-wrapper">
                <CircularProgressButton
                    id={generateTag('continue', {prefix: props.currentStep, suffix: 'btn'})}
                    text="SAVE & CONTINUE"
                    isDisabled={!isSubmitEnabled}
                    isLoading={props.loading}
                    onClick={(event) => submitHandler(event)}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        vehicle: state.qualifyVehicle.vehicle,
        currentStep: state.qualifyVehicle.currentStep,
        loading: state.app.qualifyVehicle.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onNext: (data, cbOnError, cbOnSuccess) => dispatch(actions.saveQuestions(data, cbOnError, cbOnSuccess)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExteriorCondition);