import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import Home from "../pages/Home";
import AccordionSteps from "../pages/AccordionSteps";
import { MainWrapperClassName, MainWrapperIdName, Steps } from '../constants'
import OffersScreenRange from "../pages/OffersScreen/OffersScreenRange";
import OffersScreenRangeSuccess from "../pages/OffersScreen/OffersScreenRangeSuccess";
import AllClientsDQd from "../pages/Exit/AllClientsDQd";
import NoMatchingClientsFound from "../pages/Exit/NoMatchingClientsFound";
import InfoSubmitted from "../pages/Exit/InfoSubmitted";
import OffersScreen from "../pages/OffersScreen";
import CreateAccount from "../pages/CreateAccount";
import CreateAccountThankYou from "../pages/CreateAccount/CreateAccountThankYou";
import AccountExistThankYou from "../pages/CreateAccount/AccountExistThankYou";
import NoRangeCouldBeCalculated from "../pages/Exit/NoRangeCouldBeCalculated";
import * as actions from "../store/actions";
import useGlobalState from '../hooks/useGlobalState'
import WelcomeBack from '../pages/WelcomeBack'
import {getQueryStringParams} from '../shared/utility'
import RetrievingOffers from "../pages/RetrievingOffers";
import ErrorBoundary from './errorBoundry'
import NoInstantOffersFound from '../pages/Exit/NoInstantOffersFound';

const QualifyVehicle = (props) => {
    useGlobalState(props.globalState, props.recoveryAppData, props.recoveryQualifyVehicleData)

    useEffect(() => {
        const queryParams = getQueryStringParams()
        if (
            Object.keys(queryParams.carData).length === 0 &&
            Object.keys(queryParams.sellerData).length === 0 &&
            Object.keys(queryParams.otherData).length === 0
        ) {
            return
        }
        props.onSaveQueryStringParamsToState(queryParams)
    }, [])

    const getStep = (retrievingOffers) => {

        if (retrievingOffers) {
            return <RetrievingOffers/>
        }

        switch (props.currentStep) {
            case Steps.EntryData:
                return (<Home/>)
            case Steps.History:
                return (<AccordionSteps/>)
            case Steps.Exterior:
                return (<AccordionSteps/>)
            case Steps.Mechanical:
                return (<AccordionSteps/>)
            case Steps.Additional:
                return (<AccordionSteps/>)
            case Steps.OffersRange:
                return (<OffersScreenRange />)
            case Steps.OffersRangeThankYou:
                return(<OffersScreenRangeSuccess />)
            case Steps.AllDQd:
                return(<AllClientsDQd />)
            case Steps.ExitNoClients:
                return(<NoMatchingClientsFound />)
            case Steps.ExitNoClientsThankYou:
                return(<InfoSubmitted />)
            case Steps.Offers:
                return(<OffersScreen />)
            case Steps.CreateAccount:
                return(<CreateAccount />)
            case Steps.CreateAccountThankYou:
                return(<CreateAccountThankYou />)
            case Steps.AccountExistThankYou:
                return(<AccountExistThankYou />)
            case Steps.NoRangeCouldBeCalculated:
                return(<NoRangeCouldBeCalculated />)
            case Steps.WelcomeBack:
                return (<WelcomeBack />)
            case Steps.NoInstantOffersFound:
                return (<NoInstantOffersFound/>)
            default:
                return (<Home/>)
        }
    }

    return (
        <div className={MainWrapperClassName} id={MainWrapperIdName}>
            <ErrorBoundary>
                {getStep(props.retrievingOffers)}
            </ErrorBoundary>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        globalState: state,
        qualifyVehicle: state.qualifyVehicle,
        step: state.qualifyVehicle.step,
        currentStep: state.qualifyVehicle.currentStep,
        retrievingOffers: state.app.qualifyVehicle.retrievingOffers
    };
};

const mapDispatchToProps = dispatch => {
    return {
        recoveryAppData: (data) => dispatch(actions.recoveryAppData(data)),
        recoveryQualifyVehicleData: (data) => dispatch(actions.recoveryQualifyVehicleData(data)),
        onSaveQueryStringParamsToState: (params) => dispatch(actions.setQueryParams(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QualifyVehicle);