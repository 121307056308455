import './index.css';
import Footer from '../../../components/ui/Footer'
import Logo from '../../../components/ui/Logo';
import Button from '../../../components/ui/Button';
import HeaderWithTitle from '../../../components/ui/HeaderWithTitle';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import { generateTag } from '../../../shared/utility'
import AppCloseButton from '../../../components/ui/AppCloseButton'

const InfoSubmitted = (props) => {

  return (
    <div className="InfoSubmitted-wrapper">
      <HeaderWithTitle />
      <div className="logo-wrapper"><Logo /></div>
      <div className="title-wrapper">Thank you! We’ll be in touch</div>
      <div className="subtitle-wrapper">Have another vehicle you’d like to sell? Let’s see if we can get some offers!</div>
      <Button
          id={generateTag('try-another-vehicle', {prefix: props.currentStep, suffix: 'btn'})}
          text="TRY ANOTHER VEHICLE"
          onClick={props.goToStart}
      />
      <div className="or-wrapper">– OR –</div>
      <AppCloseButton type="button" sx={{
          minWidth: '260px',
      }}/>
      <div className="footer-wrapper"><Footer /></div>
    </div>
  );
}

const mapStateToProps = state => {
    return {
        vehicle: state.qualifyVehicle.vehicle,
        currentStep: state.qualifyVehicle.currentStep,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        goToStart: () => dispatch(actions.resetQualifyVehicle()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoSubmitted);