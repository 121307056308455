import './index.css'

function Footer() {
	return (
		<div>
			<img src={'https://images.carwiser.com/carwiser/Carwiser_Logo_Logomark.svg'} className="carwiser_logo"/>
		</div>
	)
}

export default Footer