const checkIfSelected = (parentField, groupName, controls, fieldsToExclude) => {
	if (parentField.value === 'yes') {
		let isOneChecked = false
		for (let controlsKey in controls) {
			const field = controls[controlsKey]
			if (field.group === groupName && field.value) {
				if (fieldsToExclude && fieldsToExclude?.length) {
					if (!fieldsToExclude.includes(field.key)) {
						return true
					}
				}
				else {
					return true
				}
			}
		}
		if (!isOneChecked) {
			return false
		}
	}
	return true
}

export const isVehicleDamageFieldSelected = (controls) => {
	return checkIfSelected({value: 'yes'}, 'vehicleDamage', controls)
}

export const isAnyLeaksSelected = (anyLeaksField, controls) => {
	return checkIfSelected(anyLeaksField, 'anyLeaks', controls)
}

export const isRepairNeededSelected = (repairNeededField, controls) => {
	return checkIfSelected(repairNeededField, 'repairNeeded', controls, ['repairNeededEngineRemoved', 'startsAndDrive', 'repairNeededTransmissionRemoved'])
}

export const isVisibleDefectsSelected = (visibleDefectsField, controls) => {
	return checkIfSelected(visibleDefectsField, 'visibleDefects', controls)
}

export const isVisibleDefectsLargeSelected = (visibleDefectsLargeField, controls) => {
	return checkIfSelected(visibleDefectsLargeField, 'visibleDefectsLarge', controls)
}

export const isVisibleDefectsLargeLocatedSelected = (visibleDefectsLargeField, controls) => {
	return checkIfSelected(visibleDefectsLargeField, 'visibleDefectsLargeLocated', controls)
}

export const isDamageOtherGlassSelected = (damageOtherGlassField, controls) => {
	return checkIfSelected(damageOtherGlassField, 'damageOtherGlass', controls)
}

export const isDamageOtherGlassLocatedSelected = (damageOtherGlassField, controls) => {
	return checkIfSelected(damageOtherGlassField, 'damageOtherGlassLocated', controls)
}

export const isAnythingBrokenSelected = (anythingBrokenField, controls) => {
	return checkIfSelected(anythingBrokenField, 'anythingBroken', controls)
}

export const isAftermarketPartsSelected = (hasAftermarketPartsField, controls) => {
	return checkIfSelected(hasAftermarketPartsField, 'hasAftermarketParts', controls)
}

export const isTireDetailsSelected = (tireDetailsField, controls) => {
	return checkIfSelected(tireDetailsField, 'tireDetails', controls)
}

export const isTireDamageLocationSelected = (tireDetailsField, controls) => {
	return checkIfSelected(tireDetailsField, 'tireDamage', controls)
}

export const isWheelDetailsSelected = (tireWheelDamageField, controls) => {
	return checkIfSelected(tireWheelDamageField, 'wheelDetailsDamage', controls)
}

export const isTireWheelDamageLocationSelected = (tireWheelDamageField, controls) => {
	return checkIfSelected(tireWheelDamageField, 'tireWheelDamage', controls)
}

export const isDashboardLightsSelected = (dashboardLightsField, controls) => {
	return checkIfSelected(dashboardLightsField, 'dashboardLights', controls)
}