import './index.css';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {generateTag, getDependencyControls, getFormData, prepareFormData, validateForm} from '../../shared/utility'
import Question from "../../components/ui/Question";
import Answer from "../../components/ui/Question/Answer";
import React, {useEffect, useState} from "react";
import InputField from "../../components/ui/InputField";
import CircularProgressButton from "../../components/ui/CircularProgress";
import {HeapTagClassName, Scenario, Steps} from '../../constants'
import useLocalState from '../../hooks/useLocalState'
import useQueryParams from '../../hooks/useQueryParams'
import QuestionDropdown from "../../components/ui/QuestionDropdown";
import {isCarwiser} from "../../shared/common";
import {
    isTireDamageLocationSelected,
    isTireDetailsSelected,
    isTireWheelDamageLocationSelected,
    isWheelDetailsSelected
} from "../../shared/formHelper";
import {prepareAnswersFromState} from '../../store/actions/qualifyVehicleQuestions'
import AskingPrice from '../../components/ui/Question/AskingPrice'

let formData = {
    controls: {
        milesOnTires: {
            label: 'How old are your tires?',
            value: null,
            defaultValue: null,
            validation: {
                presence: {allowEmpty: false},
            },
            values: [
                {key: '9999', value: 'Under a Year Old'},
                {key: '19999', value: 'Less Than 2 Years Old'},
                {key: '29999', value: 'Less Than 3 Years Old'},
                {key: '30001', value: 'Over 3 Years Old'},
            ],
            valid: false,
            touched: false,
            helperText: null,
            type: 'input',
        },
        tireDamage: {
            label: 'Are there any issues with any of the tires?',
            value: null,
            defaultValue: null,
            values: [
                {key: 'no', value: 'No'},
                {key: 'yes', value: 'Yes'},
            ],
            validation: {
                presence: {allowEmpty: false},
            },
            valid: false,
            touched: false,
            defaultHelperText: 'E.g., mismatched, flat, worn',
            helperText: null,
            groupValidation: 'tireDamage',
        },
        tireDamageFDS: {
            label: 'Front-driver side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'tireDamage',
            type: 'boolean',
        },
        tireDamageFPS: {
            label: 'Front-passenger side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'tireDamage',
            type: 'boolean',
        },
        tireDamageRDS: {
            label: 'Rear-driver side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'tireDamage',
            type: 'boolean',
        },
        tireDamageRPS: {
            label: 'Rear-passenger side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'tireDamage',
            type: 'boolean',
        },
        tireDamageAll: {
            label: 'All',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'tireDamage',
            skip: true,
        },
        tireWheelDamage: {
            label: 'Are there any issues with the wheels?',
            value: null,
            defaultValue: null,
            values: [
                {key: 'no', value: 'No'},
                {key: 'yes', value: 'Yes'},
            ],
            validation: {
                presence: {allowEmpty: false},
            },
            groupValidation: 'tireWheelDamage',
            valid: false,
            touched: false,
            defaultHelperText: 'E.g., curb rash, damaged, missing parts',
            helperText: null,
        },
        wheelDamageFDS: {
            label: 'Front-driver side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'tireWheelDamage',
            type: 'boolean',
        },
        wheelDamageFPS: {
            label: 'Front-passenger side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'tireWheelDamage',
            type: 'boolean',
        },
        wheelDamageRDS: {
            label: 'Rear-driver side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'tireWheelDamage',
            type: 'boolean',
        },
        wheelDamageRPS: {
            label: 'Rear-passenger side',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'tireWheelDamage',
            type: 'boolean',
        },
        wheelDamageAll: {
            label: 'All',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'tireWheelDamage',
            skip: true,
        },
        wheelDetailsCurbRash: {
            label: 'Curb Rash on Wheels',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'wheelDetailsDamage',
            type: 'boolean',
        },
        wheelDetailsOxidized: {
            label: 'Oxidized or Damaged Finish',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'wheelDetailsDamage',
            type: 'boolean',
        },
        wheelDetailsMissingParts: {
            label: 'Missing Parts',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'wheelDetailsDamage',
            type: 'boolean',
        },
        wheelDetailsNeedsReplacement: {
            label: 'Wheel(s) need replacement',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'wheelDetailsDamage',
            type: 'boolean',
        },
        tireDetailsUnevenWear: {
            label: 'Uneven Wear',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'tireDetails',
            type: 'boolean',
        },
        tireDetailsMismatched: {
            label: 'Mismatched',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'tireDetails',
            type: 'boolean',
        },
        tireDetailsNeedsReplacement: {
            label: 'Tire(s) need replacement',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'tireDetails',
            type: 'boolean',
        },
        tireDetailsFlat: {
            label: 'Tire(s) are flat',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'tireDetails',
            type: 'boolean',
        },
        keyCount: {
            label: 'How many keys are there?',
            value: null,
            defaultValue: null,
            values: [
                {key: '2', value: '2+'},
                {key: '1', value: '1'},
                {key: '0', value: '0'},
            ],
            validation: {
                presence: {allowEmpty: false},
            },
            valid: false,
            touched: false,
            helperText: null,
        },
        buyingAnotherCar: {
            label: 'Are you planning on buying another vehicle?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            valid: false,
            touched: false,
            helperText: null,
        },
        askingPrice: {
            label: 'How much are you looking to sell for?',
            value: null,
            defaultValue: null,
            validation: {
                presence: {allowEmpty: true},
            },
            valid: false,
            touched: false,
            defaultHelperText: 'Optional',
            helperText: null,
        }
    },
    loading: false,
    valid: false,
    touched: false,
    generalError: null
}

const AdditionalInformation = (props) => {
    const initialData = prepareFormData(formData, {})
    // const [formState, setFormState] = useState(initialData);
    const [formState, setFormState] = useLocalState(Steps.Additional, initialData);

    const [isSubmitEnabled, setSubmitEnabled] = useState(false)

    const milesOnTiresField = formState.controls.milesOnTires;
    const tireDamageField = formState.controls.tireDamage
    const tireWheelDamageField = formState.controls.tireWheelDamage
    const keyCountField = formState.controls.keyCount

    const tireDamageFDSField = formState.controls.tireDamageFDS
    const tireDamageFPSField = formState.controls.tireDamageFPS
    const tireDamageRDSField = formState.controls.tireDamageRDS
    const tireDamageRPSField = formState.controls.tireDamageRPS
    const tireDamageAllField = formState.controls.tireDamageAll

    const tireDetailsUnevenWearField = formState.controls.tireDetailsUnevenWear
    const tireDetailsMismatchedField = formState.controls.tireDetailsMismatched
    const tireDetailsNeedsReplacementField = formState.controls.tireDetailsNeedsReplacement
    const tireDetailsFlatField = formState.controls.tireDetailsFlat


    const wheelDamageFDSField = formState.controls.wheelDamageFDS
    const wheelDamageFPSField = formState.controls.wheelDamageFPS
    const wheelDamageRDSField = formState.controls.wheelDamageRDS
    const wheelDamageRPSField = formState.controls.wheelDamageRPS
    const wheelDamageAllField = formState.controls.wheelDamageAll

    const wheelDetailsCurbRash = formState.controls.wheelDetailsCurbRash
    const wheelDetailsOxidized = formState.controls.wheelDetailsOxidized
    const wheelDetailsMissingParts = formState.controls.wheelDetailsMissingParts
    const wheelDetailsNeedsReplacement = formState.controls.wheelDetailsNeedsReplacement

    const buyingAnotherCarField = formState.controls.buyingAnotherCar;

    const askingPriceField = formState.controls.askingPrice;

    useQueryParams(formState, setFormState, props.queryParams, 'sellerData')

    const getExcludeFields = () => {
        return ['askingPrice']
    }

    const checkForm = () => {
        if (!isTireDetailsSelected(tireDamageField, formState.controls)) {
            return false
        }
        if (!isTireDamageLocationSelected(tireDamageField, formState.controls)) {
            return false
        }
        if (!isWheelDetailsSelected(tireWheelDamageField, formState.controls)) {
            return false
        }
        if (!isTireWheelDamageLocationSelected(tireWheelDamageField, formState.controls)) {
            return false
        }

        const excludeFields = getExcludeFields();

        return validateForm(formState, setFormState, excludeFields, false)
    }

    useEffect(() => {
        const progress = countProgress()
        props.setStep(prev => ({...prev, ...progress}))
    }, [formState])

    const countProgress = () => {
        let totalSteps = 5
        let steps = 0
        let fields = []

        const isValid = (field) => {
            return (!field.touched || (field.touched && field.valid))
        }

        fields.push(milesOnTiresField.key)
        fields.push(tireDamageField.key)
        fields.push(keyCountField.key)
        fields.push(tireWheelDamageField.key);
        [
            milesOnTiresField,
            tireDamageField,
            keyCountField,
            tireWheelDamageField,
        ].forEach((el) => {
            if (el.value !== null && el.value !== '' && isValid(el)) {
                steps++
            }
        })

        if (tireDamageField.value === 'yes') {
            totalSteps += 2
            if (isTireDetailsSelected(tireDamageField, formState.controls)) {
                steps++
            }
            if (isTireDamageLocationSelected(tireDamageField, formState.controls)) {
                steps++
            }
        }

        if (tireWheelDamageField.value === 'yes') {
            totalSteps += 2
            if (isWheelDetailsSelected(tireWheelDamageField, formState.controls)) {
                steps++
            }
            if (isTireWheelDamageLocationSelected(tireWheelDamageField, formState.controls)) {
                steps++
            }
        }

        // fields.push(buyingAnotherCarField.key)
        if (buyingAnotherCarField.value !== null && isValid(buyingAnotherCarField)) {
            steps++
        }

        return {totalSteps, steps, fields, isValid: checkForm()}
    }

    useEffect(() => {
        setSubmitEnabled(checkForm())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState])

    const submitHandler = async (event) => {
        const excludeFields = getExcludeFields();
        if (validateForm(formState, setFormState, excludeFields)) {
            const {askingPrice, ...answers} = getFormData(formState)

            const data = {
                xchangeData: {
                    answers: prepareAnswersFromState(formData, answers, (answersData, answers) => {
                        return answers
                    })
                },
                seller: {},
                askingPrice,
            }
            props.onNext(data)
        }
    }

    const heapTagClassNameOptions = {mainPrefix: HeapTagClassName, prefix: props.currentStep}

    return (
        <div className="additional-information-wrapper">
            <Question id={generateTag(keyCountField.key, {snippet: '{%option%}', suffix: 'btn'})}
                      className={generateTag(keyCountField.key, heapTagClassNameOptions)}
                      field={keyCountField} formState={formState} setFormState={setFormState}/>

            <Question id={generateTag(milesOnTiresField.key, {snippet: '{%option%}', suffix: 'btn'})}
                      className={generateTag(milesOnTiresField.key, heapTagClassNameOptions)}
                      field={milesOnTiresField} formState={formState} setFormState={setFormState}/>

            <Question id={generateTag(tireDamageField.key, {snippet: '{%option%}', suffix: 'btn'})}
                      className={generateTag(tireDamageField.key, heapTagClassNameOptions)}
                      field={tireDamageField} formState={formState} setFormState={setFormState}
                      isInOneLine
                      dependencyControls={getDependencyControls(tireDamageField, formState).concat(getDependencyControls({groupValidation: 'tireDetails'}, formState))}/>
            {tireDamageField.value === "yes" &&
                <>
                    <div className="subquestions">
                        <div className="question-title" style={{width: '100%'}}>Which tires have an issue?</div>
                        <div className="question-subtitle">Select all that apply</div>
                        <Answer
                            id={generateTag(tireDamageAllField.key, {suffix: 'btn'})}
                            className={generateTag(tireDamageAllField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={tireDamageAllField}
                            formState={formState}
                            setFormState={setFormState}
                            dependencyControls={[tireDamageFDSField.key, tireDamageFPSField.key, tireDamageRDSField.key, tireDamageRPSField.key]}
                        />
                        <Answer
                            id={generateTag(tireDamageFDSField.key, {suffix: 'btn'})}
                            className={generateTag(tireDamageFDSField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={tireDamageFDSField}
                            formState={formState}
                            setFormState={setFormState}
                            dependencyControls={[tireDamageAllField.key]}
                        />
                        <Answer
                            id={generateTag(tireDamageFPSField.key, {suffix: 'btn'})}
                            className={generateTag(tireDamageFPSField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={tireDamageFPSField}
                            formState={formState}
                            setFormState={setFormState}
                            dependencyControls={[tireDamageAllField.key]}
                        />
                        <Answer
                            id={generateTag(tireDamageRDSField.key, {suffix: 'btn'})}
                            className={generateTag(tireDamageRDSField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={tireDamageRDSField}
                            formState={formState}
                            setFormState={setFormState}
                            dependencyControls={[tireDamageAllField.key]}
                        />
                        <Answer
                            id={generateTag(tireDamageRPSField.key, {suffix: 'btn'})}
                            className={generateTag(tireDamageRPSField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={tireDamageRPSField}
                            formState={formState}
                            setFormState={setFormState}
                            dependencyControls={[tireDamageAllField.key]}
                        />
                    </div>

                    <div className="subquestions">
                        <div className="question-title" style={{width: '100%'}}>What best describes the issue(s)?</div>
                        <div className='question-subtitle'>Select all that apply</div>
                        <Answer id={generateTag(tireDetailsUnevenWearField.key, {suffix: 'btn'})}
                                className={generateTag(tireDetailsUnevenWearField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={tireDetailsUnevenWearField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer
                            id={generateTag(tireDetailsMismatchedField.key, {suffix: 'btn'})}
                            className={generateTag(tireDetailsMismatchedField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={tireDetailsMismatchedField} formState={formState}
                            setFormState={setFormState}/>
                        <Answer id={generateTag(tireDetailsNeedsReplacementField.key, {suffix: 'btn'})}
                                className={generateTag(tireDetailsNeedsReplacementField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={tireDetailsNeedsReplacementField} formState={formState}
                                setFormState={setFormState}
                        />
                        <Answer id={generateTag(tireDetailsFlatField.key, {suffix: 'btn'})}
                                className={generateTag(tireDetailsFlatField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={tireDetailsFlatField} formState={formState}
                                setFormState={setFormState}
                        />
                    </div>
                </>
            }
            <Question id={generateTag(tireWheelDamageField.key, {snippet: '{%option%}', suffix: 'btn'})}
                      className={generateTag(tireWheelDamageField.key, heapTagClassNameOptions)}
                      field={tireWheelDamageField} formState={formState} setFormState={setFormState}
                      isInOneLine
                      dependencyControls={getDependencyControls(tireWheelDamageField, formState).concat(getDependencyControls({groupValidation: 'wheelDetailsDamage'}, formState))}
            />

            {tireWheelDamageField.value === "yes" &&
                <>
                    <div className="subquestions">
                        <div className="question-title" style={{width: '100%'}}>Which wheels have an issue?</div>
                        <div className='question-subtitle'>Select all that apply</div>
                        <Answer
                            id={generateTag(wheelDamageAllField.key, {suffix: 'btn'})}
                            className={generateTag(wheelDamageAllField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={wheelDamageAllField} formState={formState}
                            setFormState={setFormState}
                            dependencyControls={[wheelDamageFDSField.key, wheelDamageFPSField.key, wheelDamageRDSField.key, wheelDamageRPSField.key]}/>
                        <Answer id={generateTag(wheelDamageFDSField.key, {suffix: 'btn'})}
                                className={generateTag(wheelDamageFDSField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={wheelDamageFDSField} formState={formState}
                                setFormState={setFormState} dependencyControls={[wheelDamageAllField.key]}/>
                        <Answer
                            id={generateTag(wheelDamageFPSField.key, {suffix: 'btn'})}
                            className={generateTag(wheelDamageFPSField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={wheelDamageFPSField} formState={formState}
                            dependencyControls={[wheelDamageAllField.key]}
                            setFormState={setFormState}
                        />
                        <Answer id={generateTag(wheelDamageRDSField.key, {suffix: 'btn'})}
                                className={generateTag(wheelDamageRDSField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={wheelDamageRDSField} formState={formState}
                                dependencyControls={[wheelDamageAllField.key]}
                                setFormState={setFormState}
                        />
                        <Answer id={generateTag(wheelDamageRPSField.key, {suffix: 'btn'})}
                                className={generateTag(wheelDamageRPSField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={wheelDamageRPSField} formState={formState}
                                dependencyControls={[wheelDamageAllField.key]}
                                setFormState={setFormState}
                        />
                    </div>

                    <div className="subquestions">
                        <div className="question-title" style={{width: '100%'}}>What best describes the issue(s)?</div>
                        <div className='question-subtitle'>Select all that apply</div>
                        <Answer id={generateTag(wheelDetailsCurbRash.key, {suffix: 'btn'})}
                                className={generateTag(wheelDetailsCurbRash.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={wheelDetailsCurbRash} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(wheelDetailsOxidized.key, {suffix: 'btn'})}
                                className={generateTag(wheelDetailsOxidized.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={wheelDetailsOxidized} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(wheelDetailsMissingParts.key, {suffix: 'btn'})}
                                className={generateTag(wheelDetailsMissingParts.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                                field={wheelDetailsMissingParts} formState={formState}
                                setFormState={setFormState}
                        />
                        <Answer
                            id={generateTag(wheelDetailsNeedsReplacement.key, {suffix: 'btn'})}
                            className={generateTag(wheelDetailsNeedsReplacement.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={wheelDetailsNeedsReplacement} formState={formState}
                            setFormState={setFormState}
                        />
                    </div>
                </>
            }
            <Question
                id={generateTag(buyingAnotherCarField.key, {snippet: '{%option%}', suffix: 'btn'})}
                className={generateTag(buyingAnotherCarField.key, heapTagClassNameOptions)}
                field={buyingAnotherCarField} formState={formState} setFormState={setFormState} isInOneLine
            />

            <AskingPrice
                id={generateTag(askingPriceField.key, {suffix: 'slider-field'})}
                className={generateTag(askingPriceField.key, heapTagClassNameOptions)}
                field={askingPriceField} formState={formState} setFormState={setFormState}
            />

            <div className="save-button-wrapper">
                <CircularProgressButton
                    id={generateTag('get-offers', {prefix: props.currentStep, suffix: 'btn'})}
                    text="GET OFFERS"
                    isLoading={props.loading}
                    isDisabled={!isSubmitEnabled}
                    onClick={(event) => submitHandler(event)}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        vehicle: state.qualifyVehicle.vehicle,
        scenario: state.qualifyVehicle.entryData.scenario,
        queryParams: state.queryParams,
        currentStep: state.qualifyVehicle.currentStep,
        loading: state.app.qualifyVehicle.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onNext: (data, cbOnError, cbOnSuccess) => dispatch(actions.saveQuestions(data, cbOnError, cbOnSuccess)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInformation);