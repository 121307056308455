import './index.css';
import * as actions from '../../../store/actions';
import {connect} from 'react-redux';
import HeaderWithTitle from '../../../components/ui/HeaderWithTitle';
import Logo from '../../../components/ui/Logo';
import {Box} from '@mui/material';
import Button from '../../../components/ui/Button';
import {generateTag} from '../../../shared/utility';
import AppCloseButton from '../../../components/ui/AppCloseButton';
import Footer from '../../../components/ui/Footer';
import {DisclamerWithAsteriskText, OfferTypes} from '../../../constants';
import AccordionWithResult from '../../../components/ui/AccordionWithResult';
import OfferCard from '../../../components/ui/OfferCard';
import {PrimaryNamesType} from '../../../shared/MatchingClientsHelper';
import {getProviderLogo} from '../../../shared/providerHelper';
import {NumberFormatter} from '../../../shared/formatter';

const NotInstantOffersFound = (props) => {
    return (
        <div className="NotInstantOffersFound-wrapper">
            <HeaderWithTitle/>
            <div className="logo-wrapper"><Logo/></div>
            <div className="title-wrapper">No Instant Offers Found</div>
            <Box className="sub-wrapper">
                <div className="subtitle-wrapper">We were unable to generate offers for your vehicle at this time. This may be due to the vehicle’s details, history report, or location.</div>
                <div className="subtitle-wrapper">Please check out our partner Carvana for a potential offer!</div>
            </Box>
            <Box>
                {
                    props.matchingClients.map((el, index) => {
                        return <AccordionWithResult key={el?.id ?? index} index={index} provider={el}/>

                        /*
                        const providerData = props.providers.find(item => item.key === el.primaryName);
                        if (providerData.hasOwnProperty('card') && el?.offer?.badges) {
                            providerData.card = {...providerData.card, badges: el.offer.badges}
                        }

                        return <OfferCard
                            key={el?.id ?? index}
                            provider={el}
                            mClient={{
                                primaryName: el.primaryName,
                                status: el.status,
                                logo: {
                                    isLocalDealer: (el.primaryName === PrimaryNamesType.Vincue && !el.address?.logoURL) || (el.offerType === OfferTypes.InstantOfferConfigurator && !providerData?.card?.logo) ? true : false,
                                    isLogoHidden: el.offer?.isLogoHidden,
                                    src: providerData?.card?.logo ?? el.address?.logoURL ?? getProviderLogo(el),
                                },
                                offer: {
                                    amount: NumberFormatter.format(el.offer?.amount),
                                    badges: el.offer?.badges,
                                    expirationDate: el.offer?.expirationDate,
                                    // expirationDate: moment(el.offer?.expirationDate).subtract(10, 'days').format(),
                                    details: providerData?.card?.offerDetails,
                                },
                                address: el.address,
                                isAffiliateProgram: el.isAffiliateProgram ?? false,
                                // isAffiliateProgram: index === 0 ? true : el.isAffiliateProgram ?? false,
                            }}
                        />
                        */
                    })
                }
            </Box>
            <Button
                id={generateTag('try-another-vehicle', {prefix: props.currentStep, suffix: 'btn'})}
                text="TRY ANOTHER VEHICLE"
                onClick={props.goToStart}
                sx={{
                    mt: '60px'
                }}
            />
            <div className="asterisk-wrapper">
                <ul>
                    <li>
                        {DisclamerWithAsteriskText}
                    </li>
                </ul>
            </div>
            <div className="footer-wrapper"><Footer/></div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        currentStep: state.qualifyVehicle.currentStep,
        providers: state.qualifyVehicle.providersData,
        matchingClients: state.qualifyVehicle.matchingClients,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        goToStart: () => dispatch(actions.resetQualifyVehicle())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotInstantOffersFound);