import {PrimaryNamesType} from "../shared/MatchingClientsHelper";

export class OfferHelper {
    static getOffers(matchingClients) {
        const offers = []
        matchingClients
            .filter(mc => mc.status !== 'disqualified')
            .filter(mc => !mc.deferred)
            .forEach(mc => {
                let offer = {
                    clientName: mc.name,
                    matchingClient: {
                        id: mc.id,
                        name: mc.name,
                        address: mc.address,
                        primaryName: mc.primaryName,
                    },
                }
                if (mc?.offer?.price > 0 || ![PrimaryNamesType.Vroom, PrimaryNamesType.Shift].includes(mc.name.toLowerCase())) {
                    offer.amount = mc?.offer?.price;
                }
                offers.push(offer)
            })

        return offers;
    }

    static generateClass = (offer, section = '', prefix = '') => {
        let badge
        for (let key in offer.card.badges) {
            if (['icon'].includes(key)) {
                continue
            }

            const badgeObj = offer.card.badges[key]

            if (badgeObj.status) {
                badge = key
                break
            }
        }

        return ' '+`provider-${offer.key}${badge ? '-'+badge : ''}${section ? '-'+section : ''}${prefix ? '-'+prefix :''}`
    }
}