import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import App from '../../App';
import WidgetTypes from '../../shared/WidgetTypes';
import AppReducer from '../../store/reducers/app';
import {reducer as QualifyVehicleReducer} from '../../store/reducers/qualifyVehicle';
import { onAppClosed, onAppOpened } from '../../store/actions';
import { getAppWidgetProps } from '../transport/http/widget';
import './WidgetComponent.css';
import { reducer as QueryParamsReducer } from '../../store/reducers/queryParams'

export class WidgetComponent {
    cfg = {
      targetId: undefined,
      render: undefined,
      appContainer: undefined,
      token: undefined,
      campaignId: undefined,
      styles: undefined,
      onClose: undefined, // event
      animationOnClose: undefined,
      hideMainLogoOnTheFirstStep: undefined,
      onTheFirstStepOpen: undefined, // event
    };
    store = {};
    widgetType;
    widgetClass;

    getParentNode() {
      return this.cfg.targetId ? document.getElementById(this.cfg.targetId) : document.body;
    }

    getContainer() {
      return document.querySelector('.c-long-form-container');
    }

    renderComponent() {
      let parentNode = this.getParentNode();
      if (!parentNode) {
        console.error('targetId is not correct');
        return ;
      }
      this.cfg.targetId && (parentNode.innerHTML = '');
      this.widgetType = this.cfg.targetId ? WidgetTypes.BuiltIn : WidgetTypes.Fixed;
      this.widgetClass = this.widgetType ? ('clw-' + this.widgetType) : null;

      const rootReducer = combineReducers({
          qualifyVehicle: QualifyVehicleReducer,
          app: AppReducer,
          queryParams: QueryParamsReducer,
      });

      this.store = createStore(rootReducer, compose(
          applyMiddleware(thunk),
          window.devToolsExtension ? window.devToolsExtension() : f => f
      ));

      if (this.cfg.render === 'frame') {
          this.renderIframe();
          return ;
      }

      const container = document.createElement('div');
      container.classList.add('c-long-form-container');
      this.widgetClass && container.classList.add(this.widgetClass);
      parentNode.appendChild(container);
      container.addEventListener('close', (e) => {
        console.log('on el event close trigger', e);
        this.close();
      })
      this.cfg.appContainer = container;

      ReactDOM.render(
        <React.StrictMode>
          <Provider store={this.store}>
              <App config={this.cfg}/>
          </Provider>
        </React.StrictMode>,
        container
      );
    }

    renderIframe() {
      let parentNode = this.getParentNode();
      if (!parentNode) {
        console.error('targetId is not correct');
        return ;
      }
      const token = this.cfg && this.cfg.token ? this.cfg.token : process.env.REACT_APP_ACCESS_TOKEN;
      const campaignId = this.cfg && this.cfg.campaignId ? this.cfg.campaignId : null;
      const el = document.createElement('iframe');
      const dstOrigin = process.env.REACT_APP_FRONT_API_URL;
      const origin = window.location.href;
      el.classList.add('c-long-form-frame-container');
      this.widgetClass && el.classList.add(this.widgetClass);
      el.setAttribute('allowtransparency', true);
      el.setAttribute('frameborder', '0');
      el.setAttribute('scrolling', 'yes');
      el.setAttribute('name', 'c_long_form_widget')
      el.setAttribute('src', dstOrigin + '?widget=1'
        + '&widgetType=' + encodeURIComponent(this.widgetType)
        + '&campaignId=' + (campaignId ? encodeURIComponent(campaignId) : '')
      );
      el.addEventListener('load', (e) => {
        console.log('on iframe loaded', e);
        window.frames.c_long_form_widget?.postMessage({ token: token, origin, source: 'CLongFormWidget', styles: this.cfg.styles }, dstOrigin);
      })
      parentNode.appendChild(el);
    }

    init(cfg) {
      cfg || (cfg = {});
      this.cfg = cfg;
      const reloadWidget = () => {
        let parentNode = this.getParentNode();
        if (!parentNode || parentNode.innerHTML.length > 10) {
          return ;
        }
        this.renderComponent();
      }
      document.addEventListener('DOMContentLoaded', reloadWidget);
      window.addEventListener('load', reloadWidget);
      window.addEventListener('popstate', reloadWidget);
      this.renderComponent();
    }

    close() {
      const container = this.getContainer();
      if (!container) {
        throw new Error('Container not found');
      }
      const props = getAppWidgetProps();
      if (props.animationOnClose) {
        container.classList.add('closing');
        setTimeout(() => {
          container.classList.add('closed');
          container.classList.remove('closing');
          this.store.dispatch(onAppClosed());
        }, 300);
      } else {
        container.classList.add('closed');
        this.store.dispatch(onAppClosed());
      }
    }

    open() {
      const container = this.getContainer();
      if (!container) {
        throw new Error('Container not found');
      }
      const props = getAppWidgetProps();
      if (props.animationOnClose) {
        container.classList.remove('closed');
        container.classList.add('closing');
        setTimeout(() => {
          container.classList.add('opening');
          container.classList.remove('closing');
        }, 50);
        setTimeout(() => {
          container.classList.remove('opening');
          this.store.dispatch(onAppOpened());
        }, 350);
      } else {
        container.classList.remove('closed');
        this.store.dispatch(onAppOpened());
      }
    }
  }