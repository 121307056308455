import {AffiliateProgram, PrimaryNamesType} from "./MatchingClientsHelper";
import {OfferTypes} from '../constants';

export function getProviderLogo(provider) {
	if (!provider || provider.primaryName === 'default') {
		return undefined
	}

	if (provider.offerType === OfferTypes.InstantOfferConfigurator) {
		return undefined
	}

	let providerName = provider.primaryName

	if (providerName === PrimaryNamesType.Echopark && provider.name === 'EchoPark at Long Beach BMW') {
		providerName = 'echoparkLBB'
	}

	if (providerName === AffiliateProgram.carvana.name) {
		providerName = PrimaryNamesType.Carvana
	}

	let logo = `${process.env.REACT_APP_IMAGES_STORE}/providers/logos/${providerName}.svg`
	return logo
}