import './index.css'


function HeaderText(props) {
	return (
		<div className="header-block">
			<div className="carwiser-retrieving-offers">
				{props.text}
			</div>
		</div>
	)
}

export default HeaderText