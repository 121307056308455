import './index.css'
import * as actions from '../../store/actions'
import { connect } from 'react-redux'
import { Box } from '@mui/material'
import HeaderWithTitle from '../../components/ui/HeaderWithTitle'
import Logo from '../../components/ui/Logo'
import Button from '../../components/ui/Button'
import AppCloseButton from '../../components/ui/AppCloseButton'
import Footer from '../../components/ui/Footer'
import { useEffect, useState } from 'react'
import VehicleHelper from '../../shared/VehicleHelper'
import CarWithRibbon from '../../components/ui/CarWithRibbon'
import { getItem } from '../../hooks/useGlobalState'
import { generateTag } from '../../shared/utility'

const WelcomeBack = (props) => {
	const [images, setImages] = useState([])

	useEffect(() => {
		const setData = async () => {
			const localState = getItem(props.step)
			const styleId = props.vehicle?.styleId ?? localState?.controls?.styleId?.value
			const trackedExteriorColor = localState?.controls?.exteriorColor?.value
			const exteriorColor = props.vehicle?.exteriorColor ??
				(styleId && props.vehicle.trims?.find(trim => Number(trim.styleId) === Number(styleId))
				?.exteriorColors.find(exteriorColor => exteriorColor?.name?.toLowerCase() === trackedExteriorColor?.toLowerCase())) ??
				(styleId && props.vehicle.trims[0]?.exteriorColors[0])

			setImages(VehicleHelper.getVehicleImages(props.vehicle.images, exteriorColor))
		}
		if (props.vehicle) {
			setData().catch(console.error)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.vehicle])

	const imageUrl = images.length ? images[0]['@href']?.replace(/http/i, 'https') : null
	const carNameStyle = !imageUrl ? {marginTop: "30px"} : null

	return (
		<Box className="WelcomeBack-wrapper">
			<HeaderWithTitle title="Welcome Back"/>
			<div className="logo-wrapper"><Logo /></div>

			{imageUrl && <CarWithRibbon imageUrl={imageUrl}/>}
			<div className="car-name" style={carNameStyle}>{[props.vehicle.model_year, props.vehicle.make, props.vehicle.model].join(' ')}</div>

			{/*<div className="title-wrapper">Unfortunately, Our Dealer Partners are not able to purchase this vehicle</div>*/}
			<div className="subtitle-wrapper">It looks like you’ve already began receiving your offers. Would you like to continue from your previous session or start over?</div>
			<Button
				id={generateTag('continue', {prefix: props.currentStep, suffix: 'btn'})}
				text="Continue"
				onClick={props.goToContinue}
			/>
			<div className="or-wrapper">– OR –</div>
			<Button
				id={generateTag('start-over', {prefix: props.currentStep, suffix: 'btn'})}
				text="Start Over"
				onClick={props.goToStart}
			/>
			<div className="footer-wrapper"><Footer /></div>
		</Box>
	)
}

const mapStateToProps = state => {
	return {
		vehicle: state.qualifyVehicle.vehicle,
		step: state.qualifyVehicle.step,
		currentStep: state.qualifyVehicle.currentStep,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		goToContinue: () => dispatch(actions.continueQualifyVehicle()),
		goToStart: () => dispatch(actions.resetQualifyVehicle()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeBack);