import './index.css'
import React from 'react'
import { Box } from '@mui/material'

import {ReactComponent as WheelBackground} from '../../../img/retrieving-offers/wheel/wheel-background.svg'
import {ReactComponent as WheelInside} from '../../../img/retrieving-offers/wheel/wheel-inside.svg'

function LoaderComponent(props) {
	return (
		<Box className="spinner-container">
			<Box className="wheel">
				<Box component={WheelBackground} className="background"/>
				<Box component={WheelInside} className="inside"/>
			</Box>
		</Box>
	)
}

export default LoaderComponent