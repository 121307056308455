import * as actionTypes from '../actions/actionTypes';
import {cloneObject, updateObject} from "../../shared/utility";
import {Steps} from "../../constants";

export const initialState = {
    step: Steps.EntryData,
    currentStep: Steps.EntryData,
    years: [],
    makes: [],
    models: [],
    ChromeData: {
        CVD: {},
        ADS: {},
    },
    vehicle: {},
    trims: [],
    styles: [],
    exteriorColors: [],
    interiorColors: [],
    matchingClients: [],
    price: {},
    formUUID: null,
    entryData: {},
    seller:{},
}

const setData = (state, action) => {
    const updatedState = cloneObject(action.data)
    return updateObject(state, updatedState);
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_QUALIFY_VEHICLE_QUESTIONS:
            return setData(state, action);
        case actionTypes.RESET_QUALIFY_VEHICLE_QUESTIONS:
            return initialState
        default:
            return state;
    }
};

export const getInitialState = () => initialState
