import React, {useEffect, useRef} from "react";
import './index.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VehicleDetails from "../../../pages/VehicleDetails";
import ExteriorCondition from "../../../pages/ExteriorCondition";
import MechanicalCondition from "../../../pages/MechanicalCondition";
import AdditionalInformation from "../../../pages/AdditionalInformation";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import { MainWrapperIdName, Steps } from '../../../constants'
import { ReactComponent as CheckImg } from "../../../img/check-circle.svg";
import { SvgIcon } from '@mui/material'

const AccordionComponent = (props) => {
    const checkIcon = <SvgIcon component={CheckImg} inheritViewBox sx={{fontSize: '20px'}}></SvgIcon>

    return (
        <div className="accordion-wrapper">
            <Accordion expanded={props.currentStep === Steps.History} onChange={() => props.backToStep(Steps.History)}>
                <AccordionSummary expandIcon={[Steps.Exterior, Steps.Mechanical, Steps.Additional].includes(props.currentStep) ? checkIcon : <ExpandMoreIcon/>}
                    aria-controls="panel1bh-content"
                    id={`${MainWrapperIdName}-vehicle-details-and-history-panel`}
                >
                    <Typography className="accordion-title">
                        VEHICLE DETAILS & HISTORY
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography component="div">
                        <VehicleDetails setStep={props.setDetailsHistoryStep}/>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={props.currentStep === Steps.Exterior} onChange={() => props.backToStep(Steps.Exterior)}
                disabled={[Steps.History].includes(props.currentStep)}>
                <AccordionSummary expandIcon={[Steps.Mechanical, Steps.Additional].includes(props.currentStep)  ? checkIcon : <ExpandMoreIcon/>}
                    aria-controls="panel2bh-content"
                    id={`${MainWrapperIdName}-exterior-and-interior-condition-panel`}
                >
                    <Typography>
                        EXTERIOR & INTERIOR CONDITION
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography component="div">
                        <ExteriorCondition setStep={props.setExteriorInteriorConditionStep}/>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={props.currentStep === Steps.Mechanical} onChange={() => props.backToStep(Steps.Mechanical)}
                disabled={[Steps.History, Steps.Exterior].includes(props.currentStep)}
                id={'mechanicalPanel'}>
                <AccordionSummary expandIcon={[Steps.Additional].includes(props.currentStep) ? checkIcon : <ExpandMoreIcon/>}
                    aria-controls="panel3bh-content"
                    id={`${MainWrapperIdName}-mechanical-condition-panel`}
                >
                    <Typography component="div" >
                        MECHANICAL CONDITION
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography component={'div'}>
                        <MechanicalCondition setStep={props.setMechanicalConditionStep}/>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={props.currentStep === Steps.Additional} onChange={() => props.backToStep(Steps.Additional)}
                disabled={[Steps.History, Steps.Exterior, Steps.Mechanical].includes(props.currentStep)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel4bh-content"
                    id={`${MainWrapperIdName}-additional-information-panel`}
                >
                    <Typography>ADDITIONAL INFORMATION</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <Typography component="div">
                        <AdditionalInformation setStep={props.setAdditionalInformationStep}/>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentStep: state.qualifyVehicle.currentStep,
        step: state.qualifyVehicle.step,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        backToStep: (step) => dispatch(actions.backToStep(step))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccordionComponent);