import React from "react";
import {inputChangedHandler} from "../../../shared/utility";
import DropDown from "../DropDown";

function DropDownField(props) {
  const {id = null, className = null, field, formState, setFormState, items, onChange, isDisabled } = props;
  const label = field.placeholder ? field.placeholder : field.label

  const onChangeHandler = onChange ? onChange : event => inputChangedHandler(formState, setFormState, event.target.value, field.key)

  return <DropDown
      id={id ?? field.key}
      className={className}
      items={field.values?  field.values: items}
      label={label}
      displayEmpty
      value={field.value}
      onChange = { event => onChangeHandler(event)}
      checked={field.touched && field.valid}
      textTransform = "none"
      isDisabled={isDisabled}
  />
}

export default DropDownField