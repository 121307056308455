import React from "react";
import './index.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box } from '@mui/material'
import { ReactComponent as CheckImg } from '../../../img/check-circle.svg'

function Option(props) {

    const {id = null, className = null, isInOneLine, option, onCheck} = props;

    const buttonItems = (
        <ToggleButton
            id={id ?? option.id}
            className={className}
            key={option.id}
            value={option.id}
            sx={{
                padding: '11px',
                ':hover': {
                    padding: '9px',
                },
                '&.Mui-selected': {
                    padding: '13px',
                }
            }}
        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
            }}>
                <Box sx={{
                    flexGrow: 1,
                }}>
                    {option.name}
                </Box>
            </Box>
        </ToggleButton>
    )


    const inOneLineStyles = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: "10px",
    }

    return (
        <div className='question-block'>
            <ToggleButtonGroup
                orientation={isInOneLine ? "horizontal" : "vertical"}
                color="primary"
                value={option.checked ? option.id : null}
                exclusive
                onChange={() => onCheck(option)}
                style={isInOneLine && inOneLineStyles}
            >
                {buttonItems}
            </ToggleButtonGroup>
        </div>
    )
}

export default Option;