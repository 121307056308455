import * as actions from '../../store/actions'
import { connect } from 'react-redux'
import { Box, Fade } from '@mui/material'
import HeaderWithTitle from '../../components/ui/HeaderWithTitle'
import Footer from '../../components/ui/Footer'
import { useEffect, useState } from 'react'
import HeaderText from '../../components/ui/HeaderText'
import LoaderComponent from '../../components/ui/Loader'
import { Scenario } from '../../constants'
import moment from 'moment'

const RetrievingOffers = (props) => {
	const [fade, setFade] = useState(true)
	const [selectedTextIndex, setSelectedTextIndex] = useState(0)

	const text = [
		'Your one stop shop to<br/>sell your car',
		'Choose from multiple offers',
		'Get support on demand from an agent',
		'Sell smarter and faster',
	]

	const TIME_DELAY = 2000
	const TIME_DELAY_ANIMATION = 2000

	// console.log('render')
	// console.log(moment().format('YYYY-MM-DDTHH:mm:ss.SSS'))

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (selectedTextIndex === text.length - 1) {
				return
			}
			if (fade === false) {
				let nextTextIndex = selectedTextIndex + 1
				setSelectedTextIndex(nextTextIndex)
			}

			// console.log('trigger')
			// console.log(moment().format('YYYY-MM-DDTHH:mm:ss.SSS'))

			setFade(prev => !prev)
		}, TIME_DELAY)
		if (selectedTextIndex >= text.length - 1) {
			clearTimeout(timeout)
		}
		return () => clearTimeout(timeout)
	}, [fade])

	const headerText = props.qualifyVehicle?.entryData.scenario === Scenario.MakeModelYear ? 'Retrieving Your Estimate...' : 'Retrieving Your Offers...'

	return (
		<Box className="retrieving-offers-wrapper">
			<HeaderWithTitle/>
			<HeaderText text={headerText}/>
			<LoaderComponent/>
			<Fade appear={true} in={fade} timeout={TIME_DELAY_ANIMATION} easing={'ease'}>
				<Box component={'h3'} className={`text`} dangerouslySetInnerHTML={{ __html: text[selectedTextIndex]}}/>
			</Fade>
			<div className="footer-wrapper"><Footer/></div>
		</Box>
	)
}

const mapStateToProps = state => {
	return {
		vehicle: state.qualifyVehicle.vehicle,
		step: state.qualifyVehicle.step,
		qualifyVehicle: state.qualifyVehicle,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		goToContinue: () => dispatch(actions.continueQualifyVehicle()),
		goToStart: () => dispatch(actions.resetQualifyVehicle()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RetrievingOffers);