import './index.css';
import Logo from '../../../components/ui/Logo';
import Button from '../../../components/ui/Button';
import HeaderWithTitle from '../../../components/ui/HeaderWithTitle';
import Footer from "../../../components/ui/Footer";
import { generateTag } from '../../../shared/utility'
import { Steps } from '../../../constants'

function CreateAccountThankYou() {
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <div className="create-account-thank-you-wrapper">
            <HeaderWithTitle />
            <div className="logo-wrapper"><Logo /></div>
            <div className="title-wrapper">Thank you! Your account has been created and your offers are saved</div>
            <div className="subtitle-wrapper">View your account for next steps! A Carwiser Agent will also be in touch to help you along your selling journey.</div>
            <Button
                id={generateTag('view-account', {prefix: Steps.CreateAccountThankYou, suffix: 'btn'})}
                text="VIEW ACCOUNT"
                onClick={()=> openInNewTab(process.env.REACT_APP_CARWISER_REDIRECT)}
            />
            <div className="footer-wrapper"><Footer /></div>
        </div>
    );
}

export default CreateAccountThankYou;