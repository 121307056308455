import React from 'react'
import {Box} from '@mui/material'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		errorInfo.componentStack = errorInfo.componentStack.replace(/(?:\r\n|\r|\n)/g, '<br />')
		this.setState({
			error,
			errorInfo,
		})
	}

	render() {
		if (this.state.error) {
			return <Box sx={{
				display: 'flex',
				flexDirection: 'column',
				m: 5,
			}}>
				<Box component={'h3'} sx={{
					textAlign: 'center',
				}}>Something went wrong.</Box>
				<details>
					<Box sx={{
						m: 1,
						fontWeight: 600,
					}}>
						{this.state.error && this.state.error.toString()}
					</Box>
					{false && (
						<Box sx={{
							m: 0.5,
						}} dangerouslySetInnerHTML={{__html: this.state.errorInfo.componentStack}}/>
					)}
				</details>
			</Box>;
		}

		return this.props.children;
	}
}

export default ErrorBoundary