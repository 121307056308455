import ServiceLongForm from '../services/ServiceLongForm';
import zipcodes from 'zipcodes';
import {Steps} from '../constants';
import {AffiliateProgram} from './MatchingClientsHelper';
import {cloneObject} from './utility';

const yearEqualAndGreater = 1992;
const stateExcludes = ['AK', 'HI'];
const quantityInstantOffersLessThan = 3;

const cssIdSelectorBase = 'carvana-affiliate-LF';
const cssIdSelectorCaseParams = {
    case1: '1',
    case2: '2',
    case3: '3'
}
const urlBase = 'http://carvana.sjv.io/';
const urlCaseParams = {
    case1: '0Z5mmY',
    case2: 'OrZnnK',
    case3: '3eajjy'
}

export class CarvanaAffiliateProgram {
    static async process(qualifyState) {
        let isEligible = false
        if (yearEqualAndGreater <= +qualifyState.vehicle.model_year) {
            isEligible = true
        }

        if (!qualifyState.entryData?.zip) {
            isEligible = false
        }

        if (isEligible) {
            const result = zipcodes.lookup(qualifyState.entryData.zip)
            if (stateExcludes.includes(result.state)) {
                isEligible = false
            }
        }

        if (!isEligible) {
            return
        }

        if (!qualifyState.providersData || (Array.isArray(qualifyState.providersData) && !qualifyState.providersData.length)) {
            qualifyState.providersData = await ServiceLongForm.getProviders()
        }

        if (!qualifyState.matchingClients) {
            qualifyState.matchingClients = []
        }

        const matchingClients = cloneObject(qualifyState.matchingClients)
            .filter((el, index) => {
                return qualifyState.matchingClients.findIndex(item => el.primaryName === item.primaryName) === index
            })
            .filter(el => el.status !== 'disqualified')
            .filter((el: any) => !el.doesNotUseForCarvanaAffiliateProgram)

        const mcItem = {
            isAffiliateProgram: true,
            primaryName: AffiliateProgram.carvana.name,
            status: 'open',
            offer: {
                price: null,
            },
        }

        if (!matchingClients.length) {
            qualifyState.step = Steps.NoInstantOffersFound
            qualifyState.currentStep = Steps.NoInstantOffersFound

            qualifyState.matchingClients = qualifyState.matchingClients
                .filter((el, index) => {
                    return qualifyState.matchingClients.findIndex(item => el.primaryName === item.primaryName) === index
                })
                .filter(el => el.status !== 'disqualified')
                .filter((el: any) => !el.doesNotUseForCarvanaAffiliateProgram)

            qualifyState.matchingClients.push({
                ...mcItem,
                case: {
                    idSelector: `${cssIdSelectorBase}${cssIdSelectorCaseParams.case1}`,
                    url: `${urlBase}${urlCaseParams.case1}`,
                },
            })
            return
        }

        /*const isNoInstantOffersOnlyFound = !matchingClients.filter(item => item.status !== 'open').length;
        if (isNoInstantOffersOnlyFound) {
            qualifyState.matchingClients.push({
                ...mcItem,
                case: {
                    idSelector: `${cssIdSelectorBase}${cssIdSelectorCaseParams.case2}`,
                    url: `${urlBase}${urlCaseParams.case2}`
                }
            })
            return
        }*/

        /*const quantityInstantOffers = matchingClients.filter(item => item.status !== 'open').length;
        if (quantityInstantOffers < quantityInstantOffersLessThan) {
            qualifyState.matchingClients.push({
                ...mcItem,
                case: {
                    idSelector: `${cssIdSelectorBase}${cssIdSelectorCaseParams.case3}`,
                    url: `${urlBase}${urlCaseParams.case3}`
                }
            })
            return
        }*/
    }
}