import React from 'react'
import './index.css'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { inputChangedHandler } from '../../../../shared/utility'

function Answer(props) {

	const {id = null, className = null, isInOneLine, field, formState, setFormState, dependencyControls} = props

	const handleChange = (event, newTab) => {
		event.preventDefault()
		inputChangedHandler(formState, setFormState, !field.value, field.key, dependencyControls)
	}

	const buttonItems = (
        <ToggleButton
            id={id ?? field.key}
			className={className}
            key={field.key}
            value={field.key}
			sx={{
				padding: '11px',
				':hover': {
					padding: '9px',
				},
				'&.Mui-selected': {
					padding: '13px',
				},
			}}
        >
			{field.label}
		</ToggleButton>
    )

	const inOneLineStyles = {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gridGap: '10px',
	}

	return (
		<div className="question-block">
			<ToggleButtonGroup
                orientation={isInOneLine ? 'horizontal' : 'vertical'}
                color="primary"
                value={field.value ? field.key : null}
                exclusive
                onChange={handleChange}
                style={isInOneLine && inOneLineStyles}
            >
				{buttonItems}
			</ToggleButtonGroup>
		</div>
	)
}

export default Answer