import { getAppEventHandlers, getAppWidgetProps } from '../../utils/transport/http/widget';
import * as actionTypes from './actionTypes';
import ServiceGrowSurf from '../../services/ServiceGrowSurf';

export const setAppData = (data) => {
    return {
        type: actionTypes.SET_APP_DATA,
        data
    };
};
export const resetAppData = () => {
    return async (dispatch, getState) => {
        dispatch({data: null, type: actionTypes.RESET_APP_DATA})
    }
}

export const appOpen = () => {
    const appWidgetProps = getAppWidgetProps();
    const appContainer = appWidgetProps.appContainer;
    if (appContainer) {
        const event = new Event('open');
        appContainer.dispatchEvent(event);
    }
    return onAppOpened();
}

export const appClose = () => {
    const appWidgetProps = getAppWidgetProps();
    const appContainer = appWidgetProps.appContainer;
    if (appContainer) {
        const event = new Event('close');
        appContainer.dispatchEvent(event);
    }
    return onAppClosed();
}

export const onAppOpened = () => {
    try {
        const handlers = getAppEventHandlers();
        handlers.onOpen && handlers.onOpen();
    } catch (e) {
        console.log('error', e);
    }
    return setAppClose(false);
}

export const onAppClosed = () => {
    try {
        const handlers = getAppEventHandlers();
        handlers.onClose && handlers.onClose();
    } catch (e) {
        console.log('error', e);
    }
    return setAppClose(true);
}

export const setAppClose = (close) => {
    return {
        type: actionTypes.SET_APP_CLOSE,
        close,
    }
}

export const recoveryAppData = (data) => {
    return async (dispatch, getState) => {
        dispatch(setAppData(data))
    }
}

export const checkForGrowSurfToken = () => {
    return async (dispatch, getState) => {
        const service = ServiceGrowSurf.create();
        const grsf = service.getReferrerId();
        const growSurfToken = getState().app.growSurfToken;
        if (growSurfToken && !grsf) {
            return ;
        }
        const token = await service.getToken(); 
        
        dispatch(token ?
            setGrowSurfToken(token) :
            resetGrowSurfToken());
    }
}

export const setGrowSurfToken = (token) => {
    return {
        type: actionTypes.APP_SET_GRSF_TOKEN,
        growSurfToken: token,
    }
}

export const resetGrowSurfToken = () => {
    return {
        type: actionTypes.APP_RESET_GRSF_TOKEN,
    }
}