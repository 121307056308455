export const StringFormatter = {
    repeat: (value, symbol, count) => {
        const diff = count - String(value).length;
        return diff > 0 ? (String(symbol)).repeat(diff) : "";
    },
    leftPad: (value, symbol, count) => {
        return StringFormatter.repeat(value, symbol, count) + value;
    },
    rightPad: (value, symbol, count) => {
        return value + StringFormatter.repeat(value, symbol, count);
    },
    trim: (value, length) => {
        if (value === undefined || value === null) {
            return value;
        }
        value = String(value);
        if (value.length > length) {
            value = value.substr(0, length);
        }

        return value;
    }
}

export const NumberFormatter = {

    format: (value, decimals = 0, maxDigits = 0) => {
        return Intl.NumberFormat('en-US', {
            style: 'decimal', minimumFractionDigits: decimals,
            maximumFractionDigits: maxDigits
        }).format(value)
    },
    parseFract: (value, count) => {
        return Math.round(Math.pow(10, count) * (parseFloat(value) - parseInt(value)));
    },
    formatFract: (value, count) => {
        const fract = NumberFormatter.parseFract(value, count);
        return fract === 0 ? '' : StringFormatter.rightPad(fract, "0", count);
    },
    formatInt: (value, separator) => {
        let valueInt = parseInt(value);
        const res = [];
        while (valueInt > 0) {
            res.push(NumberFormatter.parseFract(valueInt / 1000, 3));
            valueInt = Math.floor(valueInt / 1000);
        }

        return res.reverse().join(separator);
    }
}

export const MoneyFormatter = {
    symbol: '$',
    currency: 'USD',
    format: (value, decimals) => {
        if (typeof value === 'undefined' || value === null) {
            return '';
        }

        let maxDigits = 2;
        if (decimals === undefined || NumberFormatter.parseFract(value, decimals) === 0) {
            decimals = 0;
            maxDigits = 0;
        }

        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: MoneyFormatter.currency,
            minimumFractionDigits: decimals,
            maximumFractionDigits: maxDigits
        }).format(value);
    }
}

export const DateFormatter = {

    stringDbDate2StringDate: (value) => {
        return DateFormatter.dateObject2StringDate(new Date(value))
    },
    timestamp2StringDate: (timestamp) => {
        const date = new Date(timestamp * 1000);
        return DateFormatter.dateObject2StringDate(date);
    },
    dateObject2StringDate: (date, isLeadingZero = false) => {
        const values = [
            isLeadingZero ? StringFormatter.leftPad(1 + date.getMonth(), '0', 2) : (1 + date.getMonth()),
            isLeadingZero ? StringFormatter.leftPad(date.getDate(), '0', 2) : date.getDate(),
            (+date.getFullYear() - 2000)
        ];
        return values.join('/');
    }
};

export const ZipFormatter = {
    zipLength: 5,
    format: (value) => {
        value = ['string', 'number'].indexOf(typeof value) > -1 ? String(value) : "";
        value = StringFormatter.trim(value, ZipFormatter.zipLength);
        value = StringFormatter.repeat('', '0', ZipFormatter.zipLength - value.length) + value;
        return value;
    }
}