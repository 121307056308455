export const MainWrapperIdName = 'c-long-form-widget'
export const MainWrapperClassName = 'c-long-form-widget'
export const HeapTagClassName = 'heap-tag'

export const TitleStatuses = {
	Clean: 'Clean',
	Unknown: 'Unknown',
	PartsOnly: 'Parts Only',
	Salvage: 'Salvage',
	Rebuilt : 'Rebuilt'
}

export const Scenario = {
	Vin: 'vin',
	LicensePlate: 'licensePlate',
	MakeModelYear: 'makeModelYear'
}

export const Steps = {
	EntryData: 'entryData',
	History: 'history',
	Exterior: 'exterior',
	Mechanical: 'mechanical',
	Additional: 'additional',
	Offers: 'offers',
	OffersRange: 'offersRange',
	OffersRangeThankYou: 'offersRangeThankYou',
	OffersUnlocked: 'offersUnlocked',
	CreateAccount: 'createAccount',
	CreateAccountThankYou: 'createAccountThankYou',
	ExitNoClients: 'exitNoClients',
	ExitNoClientsThankYou: 'exitNoClientsThankYou',
	AllDQd: 'allDQd',
	AccountExistThankYou: 'accountExistThankYou',
	NoRangeCouldBeCalculated: 'noRangeCouldBeCalculated',
	WelcomeBack: 'welcomeBack',
	NoInstantOffersFound: 'noInstantOffersFound',
}

export const States = [
	{ id: 'AL', name: 'AL'},
	{ id: 'AK', name: 'AK'},
	{ id: 'AZ', name: 'AZ'},
	{ id: 'AR', name: 'AR'},
	{ id: 'CA', name: 'CA'},
	{ id: 'CO', name: 'CO'},
	{ id: 'CT', name: 'CT'},
	{ id: 'DC', name: 'DC'},
	{ id: 'DE', name: 'DE'},
	{ id: 'FL', name: 'FL'},
	{ id: 'GA', name: 'GA'},
	{ id: 'HI', name: 'HI'},
	{ id: 'ID', name: 'ID'},
	{ id: 'IL', name: 'IL'},
	{ id: 'IN', name: 'IN'},
	{ id: 'IA', name: 'IA'},
	{ id: 'KS', name: 'KS'},
	{ id: 'KY', name: 'KY'},
	{ id: 'LA', name: 'LA'},
	{ id: 'ME', name: 'ME'},
	{ id: 'MD', name: 'MD'},
	{ id: 'MA', name: 'MA'},
	{ id: 'MI', name: 'MI'},
	{ id: 'MN', name: 'MN'},
	{ id: 'MS', name: 'MS'},
	{ id: 'MO', name: 'MO'},
	{ id: 'MT', name: 'MT'},
	{ id: 'NE', name: 'NE'},
	{ id: 'NV', name: 'NV'},
	{ id: 'NH', name: 'NH'},
	{ id: 'NJ', name: 'NJ'},
	{ id: 'NM', name: 'NM'},
	{ id: 'NY', name: 'NY'},
	{ id: 'NC', name: 'NC'},
	{ id: 'ND', name: 'ND'},
	{ id: 'OH', name: 'OH'},
	{ id: 'OK', name: 'OK'},
	{ id: 'OR', name: 'OR'},
	{ id: 'PA', name: 'PA'},
	{ id: 'RI', name: 'RI'},
	{ id: 'SC', name: 'SC'},
	{ id: 'SD', name: 'SD'},
	{ id: 'TN', name: 'TN'},
	{ id: 'TX', name: 'TX'},
	{ id: 'UT', name: 'UT'},
	{ id: 'VT', name: 'VT'},
	{ id: 'VA', name: 'VA'},
	{ id: 'WA', name: 'WA'},
	{ id: 'WV', name: 'WV'},
	{ id: 'WI', name: 'WI'},
	{ id: 'WY', name: 'WY'},
]

export const DisclamerWithAsteriskText = 'All offers presented by Carwiser come directly from qualified dealers participating in our CarwiserXchange marketplace. All offers are contingent on your providing accurate information via Carwiser.com. Each dealer maintains the right to conduct an in-person verification of your vehicle and evaluate other vehicle use and history information prior to finalizing their offer. Any differences between the information you provide about your vehicle and the vehicle’s actual condition, use, and history may impact the offer you receive from the dealer. Carwiser is making no offer to purchase your vehicle and in no way should be considered a party to the transaction.'

export const OfferTypes = {
	Instant: 'Instant',
	Manual: 'Manual',
	InstantOfferConfigurator: 'InstantOfferConfigurator',
}