import { useEffect } from 'react'
import { checkField, cloneObject } from '../shared/utility'
import { isEqual } from 'underscore'

const useQueryParams = (formState, setFormState, queryParams = null, section = 'carData', modificators = []) => {
	useEffect(() => {
		if (!queryParams || !queryParams[section]) {
			return
		}

		const qParamsData = queryParams[section]
		const updatedFormState = cloneObject(formState)

		for (let key in updatedFormState.controls) {
			let field = updatedFormState.controls[key]
			if (qParamsData[key]) {
				let value = String(qParamsData[key])

				if (key === 'phone') {
					const regex = new RegExp(field.validation.format.pattern)
					if (regex.test(value)) {
						value = value.replace(regex, "1$2$3$4")
					}

					const mask = field.mask
					const inputSymbols = value.toString().split('')
					let result = ''
					for (let i = 0; i < mask.length; i++) {
						const element = mask[i]
						if (!['+', '(', ')', '-', ' '].includes(element)) {
							result = result + (inputSymbols.length ? inputSymbols.shift() : element.replace('9', '_'))
						} else {
							result = result + element
						}
					}
					value = result ?? ''
				}

				updatedFormState.controls[key] = checkField(
					field,
					value,
					updatedFormState
				)
			}
		}

		// console.log('#####')
		// console.log(JSON.stringify(formState.controls['phone']))
		// console.log(JSON.stringify(updatedFormState.controls['phone']))
		// console.log(isEqual(formState, updatedFormState))

		if (!isEqual(formState, updatedFormState)) {
			// console.log('setUpdatedFormState')
			setFormState(prevFormState => {
				return {
					...prevFormState,
					...updatedFormState,
				}
			})
		}
	}, [queryParams])
}

export default useQueryParams