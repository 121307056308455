import * as actionTypes from '../actions/actionTypes';
import {cloneObject, updateObject} from "../../shared/utility";

export const initialState = {
    isAppClosed: false,
    qualifyVehicle: {
        loading: null,
        error: null,
        yearLoading: null,
        makeLoading: null,
        modelLoading: null,
        vehicleDescriptionLoading: null,
        colorLoading: null,
    },
    growSurfToken: null,
}

const setData = (state, action) => {
    const updatedState = cloneObject(action.data)
    return updateObject(state, updatedState);
}

const setAppClose = (state, action) => {
    return updateObject(state, {
        ...state,
        isAppClosed: typeof action.close === 'undefined' ? true : !!action.close,
    });
}

const setGrowSurfToken = (state, action) => {
    const growSurfToken = action.growSurfToken;
    return updateObject(state, {
        ...state,
        growSurfToken,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_APP_DATA:
            return setData(state, action);
        case actionTypes.RESET_APP_DATA:
            return initialState
        case actionTypes.SET_APP_CLOSE:
            return setAppClose(state, action);
        case actionTypes.APP_SET_GRSF_TOKEN:
            return setGrowSurfToken(state, action);
        case actionTypes.APP_RESET_GRSF_TOKEN:
            return setGrowSurfToken(state, { growSurfToken: null });
        default:
            return state;
    }
};

export default reducer;