import './index.css';
import bestOfferRibbon from "../../../img/best-offer_ribbon.png";
import { Box, Skeleton } from '@mui/material'
import { useEffect, useState } from 'react'

function CarWithRibbon(props) {
    const {imageUrl, showRibbon = true} = props
    const [isLoaded, setIsLoaded] = useState(false)

    return (
        <Box className="car-with-ribbon" sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '21px',
        }}>
            <Box sx={{
                display: 'flex',
                position: 'relative',
            }}>
                {!isLoaded && <Skeleton animation={'wave'} variant={'rectangular'} width={320} height={232} sx={{borderRadius: '6px', mb: 1}}/>}
                <Box component={'img'} className="car-img" alt="car" src={imageUrl} onLoad={() => setIsLoaded(true)} sx={{height: `240px`, display: isLoaded ? 'block' : 'none'}}/>
                {showRibbon && isLoaded && <Box component={'img'} className="best-offer-ribbon" src={bestOfferRibbon} sx={{
                    position: 'absolute',
                    left: '0px',
                    top: {xs: '0'},
                    width: 57,
                    height: 74
                }}/>}
            </Box>
        </Box>
    )
}

export default CarWithRibbon;