import axios from "../axios";
import {validateResponse} from "./utility";

class BlackBookHelper {
    static getVinByLicensePlate = async (state, plate) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        const res = await axios.get(`/blackbook/license-plate/${plate}/${state}`, {
            headers: headers,
        })

        try {
            if (validateResponse(res)) {
                if (res.data) {
                    if (res.data.vin) {
                        return res.data
                    } else {
                        throw new Error('Vehicle not found. Please check the VIN')
                    }
                } else {
                    throw new Error('Invalid Blackbook Response')
                }
            } else {
                throw new Error('Blackbook API Error')
            }
        } catch (e) {
            console.log('e', e);
            throw new Error('We couldn’t find your vehicle. Please try again or enter your VIN instead.');
        }
    }
}

export default BlackBookHelper