import { getAppWidgetProps } from "../utils/transport/http/widget";

let service;

class GrowSurfToken {
    campaignId;
    referrerId;

    static create(campaignId, referrerId) {
        const self = new GrowSurfToken();
        self.campaignId = campaignId;
        self.referrerId = referrerId;
        return self;
    }
}

export default class ServiceGrowSurf {

    getGrowsurf() {
        return window['growsurf'];
    }

    async getCampaign() {
        //return this.getGrowsurf()?.getCampaign();
        return getAppWidgetProps()['growSurfCampaignId'];
    }

    async getToken() {
        const referrerId = this.getReferrerId();
        if (!referrerId) {
            return null;
        }
        const campaignId = await this.getCampaign();
        return GrowSurfToken.create(campaignId, referrerId);
    }

    getReferrerId() {
        const url = new URL(window.location.href);
        const grsf = url.searchParams.get('grsf');
        return grsf;
    }

    resetReferrerId() {
        const url = new URL(window.location.href);
        if (url.searchParams.has('grsf')) {
            url.searchParams.delete('grsf');
            window.history.replaceState(null, window.document.title, url.href);
        }
        return this;
    }

    static token2String(token) {
        if (!token) {
            return undefined;
        }
        const values = [token.campaignId, token.referrerId].map(item => item ? item : '');
        return values.join(':');
    }

    static create() {
        if (!service) {
            service = new ServiceGrowSurf();
        }

        return service;
    }
}