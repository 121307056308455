import React from "react";
import './index.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { inputChangedHandler, sanitizeString } from '../../../shared/utility'
import { Box } from '@mui/material'
import { ReactComponent as CheckImg } from "../../../img/check-circle.svg";

function Question(props) {
    const {id = null, className = null, isInOneLine, field, formState, setFormState, onChange, dependencyControls=[], isUpperCase = false} = props;

    let questionTitle = field ? field.label : props.questionTitle
    let answers = field && field.values ? field.values : [{key: field.value, value: field.value}]
    let defaultHelperText = field?.defaultHelperText ?? null

    const handleChange = (event, newTab) => {
        if(onChange) {
            onChange(newTab)
        } else {
            inputChangedHandler(formState, setFormState, newTab, field.key, dependencyControls)
        }
    };

    const buttonItems = answers.map((item) => {
        return (
            <ToggleButton
                id={id ? id.replaceAll('{%option%}', sanitizeString(item.value)) : field.key}
                className={className} key={item.key}
                value={item.key}
                disabled={answers.length === 1}
                sx={{
                    padding: '11px',
                    ':hover': {
                        padding: '9px',
                    },
                    '&.Mui-selected': {
                        padding: '13px',
                    },
                }}
            >
                <Box className={'toogle-button-content'} sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}>
                    {field.value === item.key && answers.length === 1 && <Box sx={{ml: '7px', visibility: 'hidden'}}><CheckImg className="checkImg" alt="check img"/></Box>}
                    <Box sx={{
                        flexGrow: 1,
                    }}>
                        {item.value}
                    </Box>
                    {field.value === item.key && answers.length === 1 && <Box sx={{mr: '7px'}}><CheckImg className="checkImg" alt="check img"/></Box>}
                </Box>
            </ToggleButton>
        )
    });

    const inOneLineStyles = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: "10px",
    }

    return (
        <div className='question-block'>
            <Box component="div" className='question-title' sx={{textTransform: isUpperCase ? 'uppercase' : 'inherit'}}>{questionTitle}</Box>
            {defaultHelperText &&
                <Box component="div" className="question-subtitle" style={{width: '100%'}}>{defaultHelperText}</Box>
            }
            <ToggleButtonGroup
                orientation={isInOneLine ? "horizontal" : "vertical"}
                color="primary"
                value={field.value}
                exclusive
                onChange={handleChange}
                style={isInOneLine && inOneLineStyles}
            >
                {buttonItems}
            </ToggleButtonGroup>
        </div>
    )
}

export default Question;