import { colors as colorsLibrary } from './colors'

const colorsLibraryMap = new Map(colorsLibrary.map(item => [item.name.toLowerCase(), item.code]))

class ColorHelper {
	static getCVDColor = (item) => {
		if (item.rgbHexValue !== undefined) {
			return [`${item.rgbHexValue}`]
		}

		if (item.rgbValue !== undefined) {
			const rgb = item.rgbValue.split(',')
			return [this.rgbToHex(...rgb)]
		}

		return this.getCVDColorByName(item)
	}

	static getCVDColorByName = (item) => {
		// return colorsLibrary.find(el => [item.genericDesc?.toLowerCase(), item.description?.toLowerCase()].includes(el.name.toLowerCase()))?.code
		const colorKey = item.genericDesc?.toLowerCase() ?? item.description?.toLowerCase() ?? null
		if (colorKey !== null && colorsLibraryMap.has(colorKey)) {
			return [colorsLibraryMap.get(colorKey)]
		}

		return []
	}

	static rgbToHex = (r, g, b) => {
		return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b)
	}

	static componentToHex = (c) => {
		const hex = parseInt(c).toString(16)
		return hex.length === 1 ? "0" + hex : hex
	}

	static getADSColor = (item) => {
		if (item.rgbValue !== undefined) {
			return [`#${item.rgbValue}`]
		}

		return this.getADSColorByName(item)
	}

	static getADSColorByName = (item) => {
		const colors = []

		if (item.genericcolor?.name) {
			const colorKey = item.genericcolor?.name.toLowerCase()
			return colorsLibraryMap.has(colorKey) && colors.push(colorsLibraryMap.get(colorKey))
		}

		item.colorName.split(' ')?.forEach(item => {
			const colorKey = item.toLowerCase()
			const multiColorKey = colorKey.split('/')
			if (multiColorKey.length > 1) {
				multiColorKey.forEach(colorKey => colorsLibraryMap.has(colorKey) && colors.push(colorsLibraryMap.get(colorKey)))
			} else {
				colorsLibraryMap.has(colorKey) && colors.push(colorsLibraryMap.get(colorKey))
			}
		})

		return colors
	}

	static getUniqueColours(coloursInput) {
		const mapInput = coloursInput.map(item => ([item.name, item.swatch_list]))
		const mapColours = new Map(mapInput)
		const coloursOutput = []
		mapColours.size && mapColours.forEach((value, key) => coloursOutput.push({
			name: key,
			swatch_list: value
		}));

		return coloursOutput
	}
}

export default ColorHelper