import axios from 'axios';

let axiosToken
let axiosOrigin
let axiosCampaignId
const appWidgetProps = {
	appContainer: undefined,
	animationOnClose: false,
	hideMainLogoOnTheFirstStep: false,
}
const appEventHandlers = {
	onClose: null,
	onOpen: null,
	onTheFirstStepOpen: null,
};
let instance

const create = () => {
	instance =  axios.create({
		baseURL: process.env.REACT_APP_WIDGET_API_URL,
		'Content-Type': 'application/json',
		headers: {'Authorization': 'Bearer ' + axiosToken}
	})
	instance.cancelToken = axios.CancelToken.source();

	instance.isCancel = axios.isCancel;

	instance.changeCancelToken = () => {
		instance.cancelToken = axios.CancelToken.source();
	}

	instance.interceptors.request.use((cfg) => {
		cfg.headers.Authorization = 'Bearer ' + axiosToken;
		axiosOrigin && (cfg.headers['x-origin'] = axiosOrigin);
		axiosCampaignId && (cfg.headers['x-campaign-id'] = axiosCampaignId);
		return cfg;
	});

	return instance
}

export const setToken = (token, origin, campaignId) => {
	axiosToken = token
	axiosOrigin = origin
	axiosCampaignId = campaignId
}

export const setOnCloseHandler = (onCloseHandler) => {
	appEventHandlers.onClose = onCloseHandler;
}

export const setOnTheFirstStepOpenHandler = (handler) => {
	appEventHandlers.onTheFirstStepOpen = handler;
}

export const setAppWidgetProp = (prop, value) => {
	appWidgetProps[prop] = value;
}

export const getAppEventHandlers = () => {
	return appEventHandlers;
}

export const getAppWidgetProps = () => {
	return appWidgetProps;
}

export default instance ? instance : create();