import React from "react";
import './index.css';
import AppCloseButton from "../AppCloseButton";

function HeaderWithTitle(props) {

    let {title} = props;

    return (
        <div className="header-with-title-wrapper">
            <div className="buttons-block">
                <AppCloseButton/>
            </div>
            {title &&
                <>
                    <div className="title">{title}</div>
                    <hr className="line"/>
                </>
            }

        </div>
    )
}

export default HeaderWithTitle;