import './index.css';
import HeaderWithTitle from '../../components/ui/HeaderWithTitle';
import AccordionWithResult from '../../components/ui/AccordionWithResult';
import Footer from '../../components/ui/Footer';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import VehicleHelper from "../../shared/VehicleHelper";
import {useEffect, useState} from "react";
import CarWithRibbon from "../../components/ui/CarWithRibbon";
import CircularProgressButton from "../../components/ui/CircularProgress";
import {cloneObject, generateTag} from '../../shared/utility'
import {MatchingClientsHelper, PrimaryNamesType} from "../../shared/MatchingClientsHelper";
import {DisclamerWithAsteriskText, OfferTypes} from '../../constants';


// temp 
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import {Box, Icon} from '@mui/material';
import Button from "../../components/ui/Button";
import OfferCard from '../../components/ui/OfferCard';
import {getProviderLogo} from '../../shared/providerHelper';
import {NumberFormatter} from '../../shared/formatter';
import moment from 'moment';
import {PeddleLowOffersLogic} from '../../shared/PeddleLowOffersLogic';


const OffersScreen = (props) => {
    const [images, setImages] = useState([])

    useEffect(() => {
        const setData = async () => {
            setImages(VehicleHelper.getVehicleImages(props.vehicle.images, props.vehicle.exteriorColor))
        }
        if (props.vehicle.exteriorColor) {
            setData().catch(console.error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.vehicle.exteriorColor])

    const imageUrl = images.length ? images[0]['@href']?.replace(/http/i, 'https') : null
    const carNameStyle = !imageUrl ? {marginTop: "30px"} : null

    const submitHandler = async (event) => {
        event.preventDefault();
        props.onNext({})
    }

    let matchingClients = cloneObject(props.matchingClients)

    matchingClients = PeddleLowOffersLogic.process({
        matchingClients
    })

    matchingClients = matchingClients
        .filter((obj, index) => {
            return matchingClients.findIndex(item => obj.primaryName === item.primaryName) === index
        })
        .filter(el => el.status !== 'disqualified')

    // replace dealers mc
    for (let mc of props.matchingClients) {
        if (!mc.offer || !mc.offer?.dealersMCs || mc.offer?.dealersMCs.length < 1) continue;
        for (let dmc of mc.offer.dealersMCs) {
            matchingClients.push({
                address: dmc.locationAddress,
                clientId: dmc.clientId,
                clientName: dmc.clientName,
                disqualified: false,
                id: dmc.locationAddress.dealerId,
                locationBehaviourType: "multiple",
                name: dmc.locationAddress.dealerName,
                offer: {price: 0},
                primaryName: dmc.clientName,
                status: dmc.status,
            })
        }
        matchingClients = matchingClients.filter(el => el.id !== mc.id)
    }

    matchingClients = matchingClients.sort((a, b) => {
        if (!a.offer?.price && !b.offer?.price) {
            return 0
        }
        if (!a.offer?.price) {
            return 1
        }
        if (!b.offer?.price) {
            return -1
        }
        return b.offer?.price - a.offer?.price
    })

    const providers = matchingClients

    const cardsActiveOffer = providers
        .filter((el) => {
            return el.status !== 'open' || el.isAffiliateProgram
        });

    const cardsPendingOffer = providers
        .filter((el) => {
            return el.status === 'open' && !el.isAffiliateProgram
        });

    const cardsLength = cardsActiveOffer.length + cardsPendingOffer.length;

    const template = '{%template%}'
    let title = `We Found ${template}`
    if (cardsActiveOffer.length > 1 || cardsPendingOffer.length >= 1) {
        title = title.replace(template, 'Multiple Offers')
    }
    if (cardsActiveOffer.length === 1 && !cardsPendingOffer.length) {
        title = title.replace(template, 'An Offer For You')
    }
    const titleElement = <>{title} <span className={'asterix'}>*</span></>

    let submitButtonText = 'SAVE OFFERS';
    if (cardsActiveOffer.length) {
        submitButtonText = 'SAVE OFFERS';
    }
    if (cardsActiveOffer.length === 1) {
        submitButtonText = 'SAVE OFFER';
    }
    if (cardsPendingOffer.length) {
        submitButtonText = 'SAVE & GET ALL OFFERS';
    }

    const lockedBlock = (
        <div className="accordion-with-result-wrapper">
            <Accordion>
                <AccordionSummary
                    expandIcon={<div className="details">
                        <div className="text">DETAILS</div>
                        <div className="icon"><ExpandMoreIcon/></div>
                    </div>}
                >
                    <Typography component={'div'} className='centered'>
                        <Box>
                            <Box component={'img'} src={'https://images.carwiser.com/carwiser/Carwiser_card_unlock.svg'}
                                    className={`provider-logo logo-img unlock`}/>
                            <Box className="toggle-info">
                                <Box className="toggle-text">
                                    <span>Create a Carwiser Account and we will work to get you more offers</span>
                                </Box>
                            </Box>
                        </Box>
                    </Typography>
                </AccordionSummary>
            </Accordion>
        </div>
    )

    const blurredBox = (
        <div className="blurred-unlock-blocks">
            <div className='unlock-btn'>
                <CircularProgressButton
                    id={generateTag('unlock-all-offers', {prefix: props.currentStep, suffix: 'btn'})}
                    text="UNLOCK ALL OFFERS"
                    isLoading={props.loading}
                    onClick={(event) => submitHandler(event)}
                />
            </div>
            {lockedBlock}
            {lockedBlock}
        </div>
    )

    return (
        <div className="offers-screen-wrapper">
            <HeaderWithTitle title={titleElement}/>
            {imageUrl && <CarWithRibbon imageUrl={imageUrl}/>}
            <div className="car-name" style={carNameStyle}>
                {[props.vehicle.model_year, props.vehicle.make, props.vehicle.model].join(' ')}
            </div>
            {
                cardsActiveOffer.map((el, index) => {
                    return <AccordionWithResult key={el?.id ?? index} index={index} provider={el}/>

                    /*
                    const providerData = props.providers.find(item => item.key === el.primaryName);
                    if (providerData.hasOwnProperty('card') && el?.offer?.badges) {
                        providerData.card = {...providerData.card, badges: el.offer.badges}
                    }

                    return <OfferCard
                        key={el?.id ?? index}
                        mClient={{
                            primaryName: el.primaryName,
                            status: el.status,
                            logo: {
                                isLocalDealer: (el.primaryName === PrimaryNamesType.Vincue && !el.address?.logoURL) || (el.offerType === OfferTypes.InstantOfferConfigurator && !providerData?.card?.logo) ? true : false,
                                isLogoHidden: el.offer?.isLogoHidden,
                                src: providerData?.card?.logo ?? el.address?.logoURL ?? getProviderLogo(el),
                            },
                            offer: {
                                amount: NumberFormatter.format(el.offer?.amount),
                                badges: el.offer?.badges,
                                expirationDate: el.offer?.expirationDate,
                                // expirationDate: moment(el.offer?.expirationDate).subtract(10, 'days').format(),
                                details: providerData?.card?.offerDetails,
                            },
                            pending: {
                                text: 'Create your Carwiser account to receive this offer'
                            },
                            address: el.address,
                            isAffiliateProgram: el.isAffiliateProgram ?? false,
                            // isAffiliateProgram: index === 0 ? true : el.isAffiliateProgram ?? false,
                        }}
                    />
                    */
                })
            }
            {
                !!cardsPendingOffer.length && blurredBox
            }
            <div className="save-button-wrapper">
                <CircularProgressButton
                    id={generateTag('save-all-offers', {prefix: props.currentStep, suffix: 'btn'})}
                    text={submitButtonText}
                    isLoading={props.loading}
                    onClick={(event) => submitHandler(event)}
                />
            </div>
            <div className="asterisk-wrapper">
                <ul>
                    <li>
                        {DisclamerWithAsteriskText}
                    </li>
                </ul>
            </div>
            <div className="footer-wrapper">
                <Footer/>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        vehicle: state.qualifyVehicle.vehicle,
        price: state.qualifyVehicle.price,
        providers: state.qualifyVehicle.providersData,
        matchingClients: state.qualifyVehicle.matchingClients,
        currentStep: state.qualifyVehicle.currentStep,
        loading: state.app.qualifyVehicle.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onNext: (data, cbOnError, cbOnSuccess) => dispatch(actions.saveQuestions(data, cbOnError, cbOnSuccess)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OffersScreen);