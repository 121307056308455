import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './index.css';
import { ReactComponent as CheckImg } from "../../../img/check-circle.svg";

function DropDown(props) {
  const { id = null, label, checked = false, value, onChange, items, isDisabled, textTransform = 'uppercase' } = props;

  const listItems = items.map((item) =>
    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
  );

  const CustomIcon = (props) => (
    <svg
      {...props}
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path
          d="M13.1733 1.00182L7.99997 6.17515L2.82664 1.00182C2.30664 0.481816 1.46664 0.481816 0.946641 1.00182C0.426641 1.52182 0.426641 2.36182 0.946641 2.88182L7.06664 9.00182C7.58664 9.52182 8.42664 9.52182 8.94664 9.00182L15.0666 2.88182C15.5866 2.36182 15.5866 1.52182 15.0666 1.00182C14.5466 0.49515 13.6933 0.481816 13.1733 1.00182Z"
          fill="#CCCCCC"
        />
    </svg>
  );

  return (
    <div className="dropdown-wrapper">
      <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id={id} style={{textTransform : textTransform}}>{label}</InputLabel>
        <Select
          id={id}
          value={value}
          onChange={onChange}
          IconComponent={CustomIcon}
          disabled={!items.length || isDisabled }
          style={{paddingRight: ((checked) ? '40px' : 'none') }}
        >
          {listItems}
        </Select>
      </FormControl>
      {checked && <CheckImg className="errorImg" alt="check img" />}
    </div>
)}

export default DropDown;