import React from "react";
import Input from "../Input";
import {inputChangedHandler} from "../../../shared/utility";

function InputField(props) {

    const {id = null, field, formState, setFormState, toUpperCase, isDisabled, isPassword} = props;

    return <Input
        label={field.label}
        value={field.value}
        id={id ?? field.key}
        error={field.touched && !field.valid }
        helperText={field.touched && field.helperText }
        onChange={event => inputChangedHandler(formState, setFormState, toUpperCase? event.target.value.toUpperCase() : event.target.value, field.key)}
        checked={field.touched && field.valid}
        isDisabled={isDisabled}
        isPassword={isPassword}
    />
}

export default InputField