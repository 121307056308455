import {colors} from "./colors";
import axios from "../axios";
import {validateResponse} from "./utility";
import ServiceLongForm from "../services/ServiceLongForm";

class VehicleHelper {
    static getColorsFromState = (state, exteriorColor, interiorColor) => {
        const result = {}

        if (state.exteriorColors.length) {
            result.exteriorColor = state.exteriorColors.find((item) => item.name === exteriorColor)
        } else {
            const color = colors.find((item) => item.name === exteriorColor)
            result.exteriorColor = {name: color.name, swatch_list: [color.code]}
        }

        if (state.interiorColors.length) {
            result.interiorColor = state.interiorColors.find((item) => item.name === interiorColor)
        } else {
            const color = colors.find((item) => item.name === interiorColor)
            result.interiorColor = {name: color.name, swatch_list: [color.code]}
        }

        return result
    }

    static getTrimData = (trimData, vehicleTrims) => {
        const result = {}

        result.uvc = trimData.uvc
        result.make = trimData.make
        result.model = trimData.model
        result.model_year = trimData.model_year
        result.style = trimData.style
        result.series = trimData.series
        result.transmission = trimData.transmission
        result.drivetrain = trimData.drivetrain
        result.trim = [trimData.series, trimData.style].join(' ')

        const trims = []
        vehicleTrims.forEach((item) => {
            trims.push({
                uvc: item.uvc,
                series: item.series,
                style: item.style,
            })
        })
        result.trims = trims

        return result
    }

    static submitVehicle = async (data) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        const res = await axios.post(`/vehicles`, data, {
            headers: headers,
        })
        try {
            if (validateResponse(res)) {
                return res.data

            } else {
                throw new Error('Evaluation Error')
            }
        } catch (e) {
            console.log('e', e);
            throw new Error('We are unable to evaluate your vehicle!');
        }
    }

    static  getVehicleImages = (imageData, color = null) => {
        let images = []
        if (!imageData) {
            return images
        }
        if (imageData.length) {
            if (color) {
                const vehicleColors = color.swatch_list.map(swatchElement => swatchElement.replace(/#/g, ''))
                images = imageData.filter(el => {
                    const colorCode = el['@primaryRGBHexCode']
                    return vehicleColors.includes(colorCode)
                })
            }
            if(!images.length) {
                images = imageData
            }
            images.forEach(el => {
                const colorCode = el['@shotCode']
                switch (colorCode) {
                    case '01':
                        el.priority = 1
                        break
                    case '02':
                        el.priority = 3
                        break
                    case '03':
                        el.priority = 2
                        break
                    default:
                        el.priority = 4

                }
            })
            images = images.sort((a, b) => a.priority - b.priority)
        }

        return images
    }
}

export default VehicleHelper