import React from "react";
import './index.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {inputChangedHandler} from "../../../shared/utility";
import { ReactComponent as CheckImg } from "../../../img/check-circle.svg";
import { Box } from '@mui/material'


function ColorQuestion(props) {

    const {id = null, className = null, questionTitle, answers, formState, setFormState, field} = props;

    const handleChange = (event, newTab) => {
        event.preventDefault()
        inputChangedHandler(formState, setFormState, newTab, field.key)
    };

    const buttonItems = answers.map((item) => {
        const isMultipleColor = item.swatch_list.length > 1 ? true : false

        return (
            <ToggleButton
                id={id ?? field.key}
                className={className}
                key={item.name}
                value={item.name}
                disabled={answers.length === 1}
                sx={{
                    padding: '8px',
                    ':hover': {
                        padding: '6px',
                    },
                    '&.Mui-selected': {
                        padding: '6px',
                    }
                }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                }}>
                    <Box sx={{
                        mr: '10px',
                    }}>
                        <Box className={"color-wrapper"} sx={{
                            display: 'flex',
                        }}>
                            <div className={isMultipleColor ? 'first-color' : 'color-block'} style={{background: item.swatch_list[0]}} key={`${item.swatch_list[0]}_0`} value={item.swatch_list[0]}/>
                            {isMultipleColor && <div className="second-color" style={{background: item.swatch_list[1]}} key={`${item.swatch_list[1]}_1`} value={item.swatch_list[1]}/>}
                        </Box>
                    </Box>
                    <Box sx={{
                        flexGrow: 1,
                        textAlign: 'left',
                    }}>
                        {item.name}
                    </Box>
                    {field.value === item.name && answers.length === 1 && <Box sx={{mr: '7px'}}><CheckImg className="checkImg" alt="check img"/></Box>}
                </Box>
            </ToggleButton>
        )
    });

    return (
        <div className='question-with-two-colors-block'>
            <div className='question-title'>{questionTitle}</div>
            <ToggleButtonGroup
                orientation="vertical"
                color="primary"
                value={field.value}
                exclusive
                onChange={handleChange}
            >
                {buttonItems}
            </ToggleButtonGroup>
        </div>
    )
}

export default ColorQuestion;