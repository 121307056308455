import './index.css'
import {useEffect, useState} from 'react'
import Footer from '../../components/ui/Footer'
import HeaderWithProgress from '../../components/ui/HeaderWithProgress'
import AccordionComponent from '../../components/ui/Accordion'
import {cloneObject, validateForm} from '../../shared/utility'

const AccordionSteps = (props) => {
	// useEffect(() => {
	// 	return () => document.getElementById('c-long-form-widget').scrollIntoView()
	// })

	const [progress, setProgress] = useState(0)

	const defaultStepState = {
		steps: 0,
		totalSteps: 0,
		isValid: false
	}

	const [detailsHistoryStep, setDetailsHistoryStep] = useState(cloneObject(defaultStepState))
	const [exteriorInteriorConditionStep, setExteriorInteriorConditionStep] = useState(cloneObject(defaultStepState))
	const [mechanicalConditionStep, setMechanicalConditionStep] = useState(cloneObject(defaultStepState))
	const [additionalInformationStep, setAdditionalInformationStep] = useState(cloneObject(defaultStepState))

	useEffect(() => {
		const steps = [
			detailsHistoryStep,
			exteriorInteriorConditionStep,
			mechanicalConditionStep,
			additionalInformationStep,
		]

		const answered = +steps.reduce((sum, current) => {
			return sum + (+current.steps)
		}, 0)
		const total = +steps.reduce((sum, current) => {
			return sum + (+current.totalSteps)
		}, 0)

		const percent = answered === 0 ? 0 : answered / total
		const progressValue = Number((Math.round(percent * 100) / 100 * 100).toFixed(1))

		setProgress(progressValue)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		detailsHistoryStep,
		exteriorInteriorConditionStep,
		mechanicalConditionStep,
		additionalInformationStep,
	])

	return (
		<div className="accordion-steps-layout">
			<HeaderWithProgress progressValue={progress}/>
			<AccordionComponent
				setDetailsHistoryStep={setDetailsHistoryStep}
				setExteriorInteriorConditionStep={setExteriorInteriorConditionStep}
				setMechanicalConditionStep={setMechanicalConditionStep}
				setAdditionalInformationStep={setAdditionalInformationStep}
			/>
			<div className="footer-wrapper">
				<Footer/>
			</div>
		</div>
	)
}

export default AccordionSteps