import './index.css';
import Logo from '../../../components/ui/Logo';
import Button from '../../../components/ui/Button';
import HeaderWithTitle from '../../../components/ui/HeaderWithTitle';
import Footer from "../../../components/ui/Footer";
import { generateTag } from '../../../shared/utility'
import { Steps } from '../../../constants'

function AccountExistsThankYou() {
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <div className="create-account-thank-you-wrapper">
            <HeaderWithTitle />
            <div className="logo-wrapper"><Logo /></div>
            <div className="title-wrapper">A Carwiser Account with this email address already exists</div>
            <div className="subtitle-wrapper">Click the button bellow to log in to your Carwiser Account</div>
            <Button
                id={generateTag('login', {prefix: Steps.AccountExistThankYou, suffix: 'btn'})}
                text="Log In"
                onClick={()=> openInNewTab(process.env.REACT_APP_CARWISER_REDIRECT)}
            />
            <div className="footer-wrapper"><Footer /></div>
        </div>
    );
}

export default AccountExistsThankYou;