import { connect } from 'react-redux'
import { ReactComponent as Close } from '../../../img/close.svg'
import * as actions from '../../../store/actions'
import Button from '../../../components/ui/Button'
import { MainWrapperIdName } from '../../../constants'
import { generateTag } from '../../../shared/utility'

const AppCloseButton = (props) => {
	const heapTagPrefix = props.retrievingOffers ? 'retrievingOffers' : props.currentStep
	const type = props.type
	if (type === 'button') {
		return (
			<Button
				id={generateTag('close', {prefix: heapTagPrefix, suffix: 'btn'})}
                text="CLOSE"
                onClick={() => props.onAppClose()}
                sx={props.sx ?? null}
            />
		)
	}

	return (
		<div
			id={generateTag('close', {prefix: heapTagPrefix, suffix: 'btn'})}
			className="close-button"
			onClick={() => props.onAppClose()}
		>
			CLOSE
			<Close className="close-img"/>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		isAppClosed: state.app.isAppClosed,
		currentStep: state.qualifyVehicle.currentStep,
		retrievingOffers: state.app.qualifyVehicle.retrievingOffers,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onAppClose: () => dispatch(actions.appClose()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AppCloseButton)