import React from 'react'
import './index.css'
import Checkbox from '@mui/material/Checkbox'
import { inputChangedHandler } from '../../../shared/utility'

function CheckboxFieldComponent(props) {
	const {label, field, formState, setFormState, items, onChange} = props
	const onChangeHandler = onChange ? onChange : event => inputChangedHandler(formState, setFormState, !field.value, field.key)
	return (
		<div className="checkbox-wrapper">
			<Checkbox onChange={event => onChangeHandler(event)} checked={field.value}/>
			<div className="label">
				{label}
			</div>
		</div>
	)
}

export default CheckboxFieldComponent