import {cloneObject} from './utility';
import {PrimaryNamesType} from './MatchingClientsHelper';

const THRESHOLD_PERCENTAGE = 10;

export class PeddleLowOffersLogic {
    static process(qualifyState) {
        if (!qualifyState.matchingClients.length) {
            return qualifyState.matchingClients;
        }

        const matchingClients = cloneObject(qualifyState.matchingClients);

        const peddle = matchingClients.find((item) => {
            return item.primaryName === PrimaryNamesType.Peddle && item?.offer?.price;
        });

        if (!peddle) {
            return qualifyState.matchingClients;
        }

        const MCsWithOffers = matchingClients.filter((item) => {
            return item.status === 'offerMade';
        });

        const MCsWithOffersPriceOrderDesc = MCsWithOffers.sort((a, b) => {
            return b.offer.price - a.offer.price;
        })

        let nextMCWithOfferPriceGreaterThanPeddle = null;
        for (let i = 0, size = MCsWithOffersPriceOrderDesc.length; i < size; i++) {
            const item = MCsWithOffersPriceOrderDesc[i];
            const isLowest = size === i + 1;
            if (item.primaryName === PrimaryNamesType.Peddle && isLowest) {
                nextMCWithOfferPriceGreaterThanPeddle = MCsWithOffersPriceOrderDesc[i-1] ?? null;
            }
        }

        const othersMcsWithOffers = MCsWithOffers.filter((item) => {
            return item.primaryName !== PrimaryNamesType.Peddle;
        });

        const MCsWithBlurredOffers = matchingClients.filter((item) => {
            return item.status === 'open';
        });

        if (
            nextMCWithOfferPriceGreaterThanPeddle
            &&
            (
                (othersMcsWithOffers.length === 1 && MCsWithBlurredOffers.length)
                ||
                othersMcsWithOffers.length > 1
            )
        ) {
            const percent = Math.round((nextMCWithOfferPriceGreaterThanPeddle.offer.price / peddle.offer.price) * 100 - 100);

            if (percent >= THRESHOLD_PERCENTAGE) {
                qualifyState.matchingClients = qualifyState.matchingClients.filter((item) => {
                    return item.primaryName !== PrimaryNamesType.Peddle;
                })
            }
        }

        return qualifyState.matchingClients;
    }
}