import './index.css';
import Footer from '../../../components/ui/Footer'
import Logo from '../../../components/ui/Logo';
import Button from '../../../components/ui/Button';
import HeaderWithTitle from '../../../components/ui/HeaderWithTitle';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import { generateTag, getFormData, inputChangedHandler, prepareFormData, validateForm } from '../../../shared/utility'
import InputField from "../../../components/ui/InputField";
import InputMask from "react-input-mask";
import {useEffect, useState} from "react";
import CircularProgressButton from "../../../components/ui/CircularProgress";
import TextField from "@mui/material/TextField";
import { ReactComponent as ErrorImg } from "../../../img/error-circle.svg";
import { ReactComponent as CheckImg } from "../../../img/check-circle.svg";
import { validationAmericanPhone, validationName, validationLastName } from '../../../shared/validationRules'
import useLocalState from '../../../hooks/useLocalState'
import { Steps } from '../../../constants'
import useQueryParams from '../../../hooks/useQueryParams'

let formData = {
    controls: {
        firstName: {
            label: 'First Name',
            value: '',
            validation: validationName,
            valid: false,
            touched: false,

        },
        lastName: {
            label: 'Last Name',
            value: '',
            validation: validationLastName,
            valid: false,
            touched: false,
        },
        phone: {
            label: 'Phone Number',
            value: '',
            mask: '+1 (999) 999-9999',
            validation: validationAmericanPhone,
            valid: false,
            touched: false,
            helperText: null,
        },
    },
    loading: false,
    valid: false,
    touched: false,
    generalError: null
}

const NoRangeCouldBeCalculated = (props) => {
    const initialData = prepareFormData(formData, {})
    const [formState, setFormState] = useState(initialData);
    // const [formState, setFormState] = useLocalState(Steps.NoRangeCouldBeCalculated, initialData);
    const [isSubmitEnabled, setSubmitEnabled] = useState(false)

    const firstNameField = formState.controls.firstName;
    const lastNameField = formState.controls.lastName
    const phoneField = formState.controls.phone

    useQueryParams(formState, setFormState, props.queryParams, 'sellerData')

    const checkForm = () => {
        return validateForm(formState, setFormState, [], false)
    }

    useEffect(() => {
        setSubmitEnabled(checkForm())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState])


    const submitHandler = async (event) => {
        event.preventDefault();
        if (validateForm(formState, setFormState)) {
            let data = getFormData(formState)
            data = {seller: data}
            props.onNext(data)
        }
    }
    return (
        <div className="NoRangeCouldBeCalculated-wrapper">
            <HeaderWithTitle title="RESULTS"/>
            <div className="logo-wrapper"><Logo/></div>
            <div className="title-wrapper">Unfortunately, we were unable to generate estimated offers based on the
                information provided.
            </div>
            <div className="subtitle-wrapper">One of our Carwiser Agents can still get your best options, submit your
                contact info below!
            </div>
            <InputField field={firstNameField} formState={formState} setFormState={setFormState}/>
            <InputField field={lastNameField} formState={formState} setFormState={setFormState}/>
            <InputMask
                mask="+1 (999) 999-9999"
                value={phoneField.value}
                onChange={event => inputChangedHandler(formState, setFormState, event.target.value, phoneField.key)}
            >
                {() => <div className="input-wrapper"><TextField
                    name={phoneField.key}
                    value={phoneField.value}
                    type={'input'}
                    label={phoneField.label}
                    variant="filled"
                    size={"small"}
                    fullWidth
                    helperText={phoneField.helperText}
                    error={!phoneField.valid && phoneField.touched}/>
                    {!phoneField.valid && phoneField.touched &&
                        <ErrorImg className="errorImg" alt="error img"/>}
                    {phoneField.touched && phoneField.valid &&
                        <CheckImg className="errorImg" alt="check img"/>}
                </div>}
            </InputMask>
            <div className='buttons-wrapper'>
                <CircularProgressButton
                    id={generateTag('submit', {prefix: props.currentStep, suffix: 'btn'})}
                    text="SUBMIT"
                    isLoading={props.loading}
                    isDisabled={!isSubmitEnabled}
                    onClick={(event) => submitHandler(event)}
                />
                <div className="or-wrapper">– OR –</div>
                <Button
                    id={generateTag('try-again', {prefix: props.currentStep, suffix: 'btn'})}
                    text="TRY AGAIN"
                    onClick={props.goToStart}
                />
            </div>

            <div className="footer-wrapper"><Footer/></div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        vehicle: state.qualifyVehicle.vehicle,
        seller: state.qualifyVehicle.seller,
        price: state.qualifyVehicle.price,
        queryParams: state.queryParams,
        currentStep: state.qualifyVehicle.currentStep,
        loading: state.app.qualifyVehicle.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onNext: (data, cbOnError, cbOnSuccess) => dispatch(actions.saveQuestions(data, cbOnError, cbOnSuccess)),
        goToStart: () => dispatch(actions.resetQualifyVehicle())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoRangeCouldBeCalculated);