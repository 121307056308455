import { useState, useEffect } from 'react'
import { getItem, keyLocalStorageLastUpdate, setItem } from './useGlobalState'
import moment from 'moment'
import { isEqual } from 'underscore'

const useLocalState = (storageKey, fallbackState) => {
	const [value, setValue] = useState(
		getItem(storageKey) ?? fallbackState
	)
	// console.log(`recovery localState ${storageKey}`)

	useEffect(() => {
		if (!isEqual(value, fallbackState) && !isEqual(value, getItem(storageKey))) {
			// console.log(`store localState ${storageKey}`)
			setItem(storageKey, value)

			// console.log('store time point last change of any localState')
			setItem(keyLocalStorageLastUpdate, moment().utc().format())
		}
	}, [value, storageKey])

	return [value, setValue]
}

export default useLocalState