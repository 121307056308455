class HeapHelper {
    static load = (callback) => {
        const existingScript = document.getElementById('heap');
        if (!existingScript) {
            if(!window.heap) {
                window.heap = []
                window.heap.load = (e, t) => {
                    window.heap.appid = e
                    window.heap.config = t ? t: {}
                    const r = document.createElement("script");
                    r.id = 'heap'
                    r.type = "text/javascript"
                    r.async = !0
                    r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js";
                    const a = document.getElementsByTagName("script")[0];
                    a.parentNode.insertBefore(r, a);
                    for (let n = function (e) {
                        return function () {
                            window.heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
                        }
                    }, p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "resetIdentity", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"], o = 0; o < p.length; o++) window.heap[p[o]] = n(p[o])
                }
            }

            window.heap.load(process.env.REACT_APP_HEAP_APP_ID, {
                secureCookie: true
            });

            /*const myElementToCheckIfClicksAreInsideOf = document.querySelector('#c-long-form-widget');
            const events = ['click', 'focus']
            events.forEach(eventName => {
                document.body.addEventListener(eventName, function (event) {
                    console.log(window.heap,myElementToCheckIfClicksAreInsideOf.contains(event.target), event.target.id ,event.target.id.indexOf('c-long-form-widget-'))
                    if(window.heap) {
                        if (myElementToCheckIfClicksAreInsideOf.contains(event.target) || event.target.id.indexOf('c-long-form-widget-') !== -1) {
                            window.heap.loaded = true
                        } else {
                            window.heap.loaded = false
                        }
                    }
                });
            })
             */

        }
        if (existingScript && callback) callback();
    }
}

export default HeapHelper