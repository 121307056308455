export class TimeMeasurer {
    startedAt
    endedAt
    error

    start() {
        this.startedAt = new Date().getTime();
        this.error = null;
    }

    end() {
        this.endedAt = new Date().getTime();
    }

    getResult() {
        return this.endedAt - (this.startedAt ? this.startedAt : 0);
    }

    endAndGetResult() {
        this.end();
        return this.getResult();
    }

    setError(e) {
        this.error = e;
    }

    getErrorMsg() {
        return this.error ? this.error.message : undefined;
    }

    static createAndStart() {
        const self = new TimeMeasurer();
        self.start();

        return self;
    }
}
