import React from 'react'
import './index.css'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'

function CircularProgressButton(props) {

	const {id = null, text, isDisabled, onClick, isLoading} = props
	return (
		<div className="circular-progress-wrapper">
			<div className="button-wrapper">
				<Button
                    id={id}
                    disabled={isDisabled || isLoading}
                    onClick={onClick}
                    size={'medium'}
                    text={text}
                >
					{text}
					{isLoading && <div className="circular-progress-block"><CircularProgress/></div>}
				</Button>
			</div>
		</div>
	)
}

export default CircularProgressButton