import axios from 'axios'

let instance

const create = () => {
	instance = axios.create({
		baseURL: process.env.REACT_APP_CVD_API_URL,
		headers: {
			'Content-Type': 'application/json',
			'x-cw-source' : 'longForm'
		}
	})

	return instance
}

export default instance ? instance : create()