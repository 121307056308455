import axios from "../axios";
import {validateResponse} from "./utility";
import {DATA_VERSION} from "./dataVersion";

class FormStatHelper {
    static submitFormData = async (data) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            }
            data['dataVersion'] = DATA_VERSION
            const res = await axios.post(`/form-stat`, data, {
                headers: headers,
            })

            if (validateResponse(res)) {
                return res.data
            } else {
                throw new Error('Evaluation Error')
            }
        } catch (e) {
            console.log('e', e);
            throw new Error('We are unable to evaluate your vehicle!');
        }
    }
}

export default FormStatHelper