import React from "react";
import TextField from '@mui/material/TextField';
import './index.css';
import { ReactComponent as ErrorImg } from "../../../img/error-circle.svg";
import { ReactComponent as CheckImg } from "../../../img/check-circle.svg";

function Input(props) {

  const { id, label, error = false, helperText, checked = false, value, onChange, isDisabled, isPassword,   } = props;

  return (
    <div className="input-wrapper">
      <TextField
        error={error}
        id={id}
        label={label}
        variant="filled"
        helperText={helperText}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        //defaultValue={value}
        type={isPassword && "password"}

      />
      {error && <ErrorImg className="errorImg"  alt="error img" />}
      {checked && <CheckImg className="errorImg" alt="check img" />}
    </div>
)}
  
export default Input;