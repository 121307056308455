import React from "react";
import './index.css';
import Button from "../Button";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { ReactComponent as HelpImg } from "../../../img/help-outline.svg";
import { ReactComponent as CarLogo } from "../../../img/where-is-vin-car.svg";

function MyVinModal() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="MyVinModal-text-wrapper" onClick={handleClickOpen}>
        <HelpImg className="help-img"  alt="help img" />
        <div className="where-can-i-find-text">Where can I find my VIN?</div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: { borderRadius: 12 }
        }}
      >
        <DialogContent>
          <div className="carwiser-modal-wrapper">
            <div className="title-block">Where is my VIN?</div>
            <div className="sub-block">Check your vehicle's title, registration, insurance card, or maintenance records.</div>
            <div className="sub-block second">You can also find it on your vehicle, either on the driver’s side door jam or on the dashboard looking through the windshield. </div>
            <div className="car-logo" ><CarLogo alt="car logo" /></div>
            <div onClick={handleClose}>
                <Button text="Close" isDisabled={false}/>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
)}
  
export default MyVinModal;