import React from "react";
import './index.css';
import { Box } from '@mui/material'
import DropDownField from "../DropDownField";

function QuestionDropdown(props) {
    const {id = null, className = null, field, formState, setFormState, isUpperCase = false} = props;

    let questionTitle = field ? field.label : props.questionTitle
    return (
        <div className='question-block'>
            <Box component="div" className='question-title'
                 sx={{textTransform: isUpperCase ? 'uppercase' : 'inherit'}}>{questionTitle}</Box>
            <div className={!field.valid ? 'line-wrapper error' : 'line-wrapper'}>
                <div className="DropDown-state-wrapper">
                    <DropDownField
                        id={id ?? field.key}
                        className={className}
                        field={field} formState={formState} setFormState={setFormState}
                    />
                </div>
            </div>
        </div>
    )
}

export default QuestionDropdown;