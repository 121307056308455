import './App.css';
import React, {useEffect, useState} from 'react';
import QualifyVehicle from "./components/qualifyVehicle";
import {
    setAppWidgetProp,
    setOnCloseHandler,
    setOnTheFirstStepOpenHandler,
    setToken as setWidgetToken
} from "./utils/transport/http/widget";
import {setToken} from "./axios";
import {connect} from 'react-redux';
import * as actions from "./store/actions";
import {Steps} from './constants';
import heapHelper from "./shared/HeapHelper";
import {isCarwiser} from "./shared/common";
import ServiceGrowSurf from './services/ServiceGrowSurf';
import vwoHelper from "./shared/VwoHelper";


function App(props) {
    const [heapLoaded, setHeapLoaded] = useState(false);
    const [vwoLoaded, setVWOLoaded] = useState(false);

    useEffect(() => {
        if(isCarwiser) {
            heapHelper.load(() => {
                setHeapLoaded(true);
            });
            vwoHelper.load(()=>{
                setVWOLoaded(true);
            })
        }
        props.checkForGrowSurfToken();
    }, []);

    useEffect(() => {
        if ([Steps.EntryData, Steps.WelcomeBack].includes(props.currentStep)) {
            return;
        }
        document.getElementById('c-long-form-widget').scrollIntoView()
    }, [props.currentStep])

    let token = props.config && props.config.token ? props.config.token : null;
    let campaignId = props.config && props.config.campaignId ? props.config.campaignId : null;
    let growSurfCampaignId = props.config && props.config.growSurfCampaignId ? props.config.growSurfCampaignId : null;
    let onCloseHandler;
    let onTheFirstStepOpenHandler;
    let animationOnClose = false;
    let hideMainLogoOnTheFirstStep = false;
    let appContainer;
    if (props.config) {
        onCloseHandler = props.config.onClose ? props.config.onClose : null;
        animationOnClose = typeof props.config.animationOnClose !== 'undefined'
            ? !!props.config.animationOnClose : animationOnClose;
        appContainer = props.config.appContainer;
        hideMainLogoOnTheFirstStep = typeof props.config.hideMainLogoOnTheFirstStep !== 'undefined'
            ? !!props.config.hideMainLogoOnTheFirstStep : hideMainLogoOnTheFirstStep;
        onTheFirstStepOpenHandler = props.config.onTheFirstStepOpen ? props.config.onTheFirstStepOpen : null;
    }
    let origin;
    const appClasses = ['App'];
    if (!token) {
        const url = new URL(window.location.href);
        const query = url.search.replace(/^\?/, '').split('&').map(keyval => {
            keyval = keyval.split('=');
            return {[keyval[0]]: decodeURIComponent(keyval[1])}
        }).reduce((res, val) => ({...res, ...val}), {});
        query.token && (token = query.token);
        query.origin && (origin = query.origin);
        query.campaignId && (campaignId = query.campaignId);
        if (query.widget) {
            appClasses.push('c-long-form-frame-body');
            const widgetClass = query.widgetType ? ('lw-' + query.widgetType) : null;
            widgetClass && appClasses.push(widgetClass);
        }
    }
    if (!token) {
        token = process.env.REACT_APP_ACCESS_TOKEN;
    }
    if (!campaignId) {
        campaignId = process.env.REACT_APP_CAMPAIGN_ID;
    }

    setToken(token, origin, campaignId);
    setWidgetToken(token, origin, campaignId);
    setOnCloseHandler(onCloseHandler);
    setOnTheFirstStepOpenHandler(onTheFirstStepOpenHandler);
    setAppWidgetProp('animationOnClose', animationOnClose);
    setAppWidgetProp('hideMainLogoOnTheFirstStep', hideMainLogoOnTheFirstStep);
    setAppWidgetProp('appContainer', appContainer);
    setAppWidgetProp('growSurfCampaignId', growSurfCampaignId);
    
    return (
        <div className={appClasses.join(' ')}>
            <QualifyVehicle/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isAppClosed: state.app.isAppClosed,
        currentStep: state.qualifyVehicle.currentStep,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        appOpen: () => dispatch(actions.appOpen()),
        onAppClosed: () => dispatch(actions.onAppClosed()),
        checkForGrowSurfToken: () => dispatch(actions.checkForGrowSurfToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
