import './index.css';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {
    generateTag,
    getDependencyControls,
    getFormData,
    prepareFormData,
    sanitizeString,
    validateForm,
} from '../../shared/utility'
import Question from "../../components/ui/Question";
import Answer from "../../components/ui/Question/Answer";
import {useEffect, useState} from "react";
import CircularProgressButton from "../../components/ui/CircularProgress";
import useLocalState from '../../hooks/useLocalState'
import { HeapTagClassName, MainWrapperIdName, Steps } from '../../constants'
import {
    isAftermarketPartsSelected,
    isAnyLeaksSelected,
    isDashboardLightsSelected,
    isRepairNeededSelected
} from "../../shared/formHelper";
import {prepareAnswersFromState} from '../../store/actions/qualifyVehicleQuestions'

let formData = {
    controls: {
        startsAndDrive: {
            label: 'Does the vehicle start and drive?',
            value: null,
            defaultValue: null,
            values: [
                {key: "startsAndDrive", value: "Starts and drives"},
                {key: "startsNotDrive", value: "Starts but does not drive"},
                {key: "notStart", value: "Does not start"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            //group: 'repairNeeded',
            valid: false,
            touched: false,
            helperText: null,
            skip: true
        },
        dashboardLights: {
            label: 'Are any dashboard warning lights currently on?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            groupValidation: 'dashboardLights',
            valid: false,
            touched: false,
            defaultHelperText: 'E.g., check engine light, brakes, tire pressure, etc.',
            helperText: null,
        },
        anyLeaks: {
            label: 'Are there any fluid leaks?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            groupValidation: 'anyLeaks',
            valid: false,
            touched: false,
            helperText: null,
        },
        anyLeaksCoolant: {
            label: 'Coolant',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'anyLeaks',
            type: 'boolean'
        },
        anyLeaksEngineOil: {
            label: 'Engine Oil',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'anyLeaks',
            type: 'boolean'
        },
        anyLeaksTransmissionFluid: {
            label: 'Transmission Fluid',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'anyLeaks',
            type: 'boolean'
        },
        anyLeaksBrakeFluid: {
            label: 'Brake Fluid',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'anyLeaks',
            type: 'boolean'
        },
        originalOdometer: {
            label: 'Has the odometer ever been replaced or modified?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            valid: false,
            touched: false,
            helperText: null,
        },
        repairNeeded: {
            label: 'Are there any mechanical issues with the vehicle?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            groupValidation: 'repairNeeded',
            valid: false,
            touched: false,
            defaultHelperText: 'E.g., warning lights, engine, transmission, or brake issues, etc.',
            helperText: null,
        },
        repairNeededEngine: {
            label: 'Engine',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'repairNeeded',
            type: 'boolean'
        },
        repairNeededTransmission: {
            label: 'Transmission',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'repairNeeded',
            type: 'boolean'
        },
        repairNeededAirConditioning: {
            label: 'Air Conditioning',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'repairNeeded',
            type: 'boolean'
        },
        repairNeededElectrical: {
            label: 'Electrical',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'repairNeeded',
            type: 'boolean'
        },
        repairNeededBrakes: {
            label: 'Brakes',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'repairNeeded',
            type: 'boolean'
        },
        repairNeededExhaust : {
            label: 'Exhaust',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'repairNeeded',
            type: 'boolean'
        },
        repairNeededO2Sensor: {
            label: 'O2 Sensor',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'repairNeeded',
            type: 'boolean'
        },
        repairNeededTirePressureSensors: {
            label: 'Tire Pressure Sensors',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'repairNeeded',
            type: 'boolean'
        },
        repairNeededSunroof: {
            label: 'Sunroof',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'repairNeeded',
            type: 'boolean'
        },
        repairNeededConvertible: {
            label: 'Convertible Top',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'repairNeeded',
            type: 'boolean'
        },
        repairNeededOther: {
            label: 'Other',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'repairNeeded',
            type: 'boolean'
        },
        repairNeededEngineRemoved: {
            label: 'Has the engine been removed?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            group: 'repairNeeded',
            valid: false,
            touched: false,
            helperText: null,
        },
        repairNeededTransmissionRemoved: {
            label: 'Has the transmission been removed?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            group: 'repairNeeded',
            valid: false,
            touched: false,
            helperText: null,
        },
        dashboardLightsCheckEngine:{
            label: 'Check Engine Light',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'dashboardLights',
            type: 'boolean'
        },
        dashboardLightsBattery:{
            label: 'Battery Charging System',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'dashboardLights',
            type: 'boolean'
        },
        dashboardLightsBrakes:{
            label: 'Brakes',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'dashboardLights',
            type: 'boolean'
        },
        dashboardLightsABS:{
            label: 'ABS',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'dashboardLights',
            type: 'boolean'
        },
        dashboardLightsAirbag:{
            label: 'Airbag/SRS',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'dashboardLights',
            type: 'boolean'
        },
        dashboardLightsSuspension:{
            label: 'Suspension',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'dashboardLights',
            type: 'boolean'
        },
        dashboardLightsTirePressure:{
            label: 'Tire Pressure',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'dashboardLights',
            type: 'boolean'
        },
        dashboardLightsTractionControl:{
            label: 'Traction Control',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'dashboardLights',
            type: 'boolean'
        },
        dashboardLightsOther:{
            label: 'Other',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'dashboardLights',
            type: 'boolean'
        },
        hasAftermarketParts: {
            label: 'Are there any aftermarket parts?',
            value: null,
            defaultValue: null,
            values: [
                {key: "no", value: "No"},
                {key: "yes", value: "Yes"},
            ],
            validation: {
                presence: {allowEmpty: false}
            },
            groupValidation: 'hasAftermarketParts',
            valid: false,
            touched: false,
            defaultHelperText: 'E.g., modified suspension, exhaust, etc.',
            helperText: null,
        },
        hasAftermarketPartsExhaust: {
            label: 'Exhaust',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'hasAftermarketParts',
            type: 'boolean'
        },
        hasAftermarketPartsWheels: {
            label: 'Wheels',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'hasAftermarketParts',
            type: 'boolean'
        },
        hasAftermarketPartsEngineModifications: {
            label: 'Engine Modifications',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'hasAftermarketParts',
            type: 'boolean'
        },
        hasAftermarketPartsLiftedSuspension: {
            label: 'Suspension Modifications',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'hasAftermarketParts',
            type: 'boolean',
        },
        hasAftermarketPartsOther: {
            label: 'Other',
            value: false,
            defaultValue: false,
            valid: false,
            touched: false,
            helperText: null,
            group: 'hasAftermarketParts',
            type: 'boolean'
        },
    },
    loading: false,
    valid: false,
    touched: false,
    generalError: null
}

const MechanicalCondition = (props) => {
    const initialData = prepareFormData(formData, {})
    // const [formState, setFormState] = useState(initialData);
    const [formState, setFormState] = useLocalState(Steps.Mechanical, initialData);

    const [isSubmitEnabled, setSubmitEnabled] = useState(false)

    const startsAndDriveField = formState.controls.startsAndDrive;
    const dashboardLightsField = formState.controls.dashboardLights

    const anyLeaksField = formState.controls.anyLeaks
    const anyLeaksCoolantField = formState.controls.anyLeaksCoolant
    const anyLeaksEngineOilField = formState.controls.anyLeaksEngineOil
    const anyLeaksTransmissionFluidField = formState.controls.anyLeaksTransmissionFluid
    const anyLeaksBrakeFluidField = formState.controls.anyLeaksBrakeFluid

    const originalOdometerField = formState.controls.originalOdometer
    const repairNeededField = formState.controls.repairNeeded
    const repairNeededEngineField = formState.controls.repairNeededEngine
    const repairNeededTransmissionField = formState.controls.repairNeededTransmission
    const repairNeededAirConditioningField = formState.controls.repairNeededAirConditioning
    const repairNeededElectricalField = formState.controls.repairNeededElectrical
    const repairNeededTirePressureSensorsField = formState.controls.repairNeededTirePressureSensors
    const repairNeededBrakesField = formState.controls.repairNeededBrakes
    const repairNeededExhaustField = formState.controls.repairNeededExhaust
    const repairNeededO2SensorField = formState.controls.repairNeededO2Sensor
    const repairNeededSunroofField = formState.controls.repairNeededSunroof
    const repairNeededConvertibleField = formState.controls.repairNeededConvertible
    const repairNeededOtherField = formState.controls.repairNeededOther
    const repairNeededEngineRemovedField = formState.controls.repairNeededEngineRemoved
    const repairNeededTransmissionRemovedField = formState.controls.repairNeededTransmissionRemoved

    const dashboardLightsCheckEngineField = formState.controls.dashboardLightsCheckEngine
    const dashboardLightsBatteryField = formState.controls.dashboardLightsBattery
    const dashboardLightsBrakesField = formState.controls.dashboardLightsBrakes
    const dashboardLightsABSField = formState.controls.dashboardLightsABS
    const dashboardLightsAirbagField = formState.controls.dashboardLightsAirbag
    const dashboardLightsSuspensionField = formState.controls.dashboardLightsSuspension
    const dashboardLightsTirePressureField = formState.controls.dashboardLightsTirePressure
    const dashboardLightsTractionControlField = formState.controls.dashboardLightsTractionControl
    const dashboardLightsOtherField = formState.controls.dashboardLightsOther

    const hasAftermarketPartsField = formState.controls.hasAftermarketParts
    const hasAftermarketPartsExhaustField = formState.controls.hasAftermarketPartsExhaust
    const hasAftermarketPartsWheelsField = formState.controls.hasAftermarketPartsWheels
    const hasAftermarketPartsEngineModificationsField = formState.controls.hasAftermarketPartsEngineModifications
    const hasAftermarketPartsLiftedSuspensionField = formState.controls.hasAftermarketPartsLiftedSuspension
    const hasAftermarketPartsOtherField = formState.controls.hasAftermarketPartsOther

    const getExcludeFields = () => {
        const excludeFields = []
        if (repairNeededField.value !== 'yes') {
            excludeFields.push(startsAndDriveField.key)
            excludeFields.push(repairNeededEngineRemovedField.key)
            excludeFields.push(repairNeededTransmissionRemovedField.key)
            excludeFields.push(dashboardLightsField.key)
            excludeFields.push(originalOdometerField.key)
        }

        if (repairNeededEngineField.value !== true) {
            excludeFields.push(repairNeededEngineRemovedField.key)
        }
        if (repairNeededTransmissionField.value !== true) {
            excludeFields.push(repairNeededTransmissionRemovedField.key)
        }

        return excludeFields
    }

    const checkForm = () => {
        if (!isAnyLeaksSelected(anyLeaksField, formState.controls)) return false
        if (!isRepairNeededSelected(repairNeededField, formState.controls)) return false
        if (!isDashboardLightsSelected(dashboardLightsField, formState.controls)) return false
        if (!isAftermarketPartsSelected(hasAftermarketPartsField, formState.controls)) return false

        const excludeFields = getExcludeFields()

        return validateForm(formState, setFormState, excludeFields, false)
    }

    useEffect(() => {
        const progress = countProgress()
        props.setStep(prev => ({...prev, ...progress}))
    }, [formState])

    const countProgress = () => {
        let totalSteps = 3
        let steps = 0
        let fields = []

        const isValid = (field) => {
            return (!field.touched || (field.touched && field.valid)) && field.value !== null
        }

        fields.push(anyLeaksField.key)
        fields.push(repairNeededField.key)
        fields.push(hasAftermarketPartsField.key);
        [
            anyLeaksField,
            repairNeededField,
            hasAftermarketPartsField,
        ].forEach((el) => {
            if (el.value !== null && el.value !== '' && isValid(el)) {
                steps++
            }
        })

        if (anyLeaksField.value === 'yes') {
            totalSteps++
            if (isAnyLeaksSelected(anyLeaksField, formState.controls)) {
                steps++
            }
        }

        if (repairNeededField.value === 'yes') {
            totalSteps += 4
            fields.push(startsAndDriveField.key)

            if (startsAndDriveField.value !== null && startsAndDriveField.value !== '' && isValid(startsAndDriveField)) {
                steps++
            }

            if (isRepairNeededSelected(repairNeededField, formState.controls)) {
                steps++
            }

            if (repairNeededEngineField.value === true) {
                totalSteps++
                if (repairNeededEngineRemovedField.value !== null && repairNeededEngineRemovedField.value !== '' && isValid(repairNeededEngineRemovedField)) {
                    steps++
                }

            }
            if (repairNeededTransmissionField.value === true) {
                totalSteps++
                if (repairNeededTransmissionRemovedField.value !== null && repairNeededTransmissionRemovedField.value !== '' &&
                    isValid(repairNeededTransmissionRemovedField)) {
                    steps++
                }
            }

            fields.push(dashboardLightsField.key)
            if (dashboardLightsField.value !== null && dashboardLightsField.value !== '' && isValid(dashboardLightsField)) {
                steps++
            }
            if (dashboardLightsField.value === 'yes') {
                totalSteps++
                if (isDashboardLightsSelected(dashboardLightsField, formState.controls)) {
                    steps++
                }
            }
            fields.push(originalOdometerField.key)

            if (originalOdometerField.value !== null && originalOdometerField.value !== '' && isValid(originalOdometerField)) {
                steps++
            }
        }

        if (hasAftermarketPartsField.value === 'yes') {
            totalSteps++
            if (isAftermarketPartsSelected(hasAftermarketPartsField, formState.controls)) {
                steps++
            }
        }

        return {totalSteps, steps, fields, isValid: checkForm()}
    }

    useEffect(() => {
        if (repairNeededField.value === 'no') {
            startsAndDriveField.value = startsAndDriveField.defaultValue
            repairNeededEngineField.value = repairNeededEngineField.defaultValue
            repairNeededTransmissionField.value = repairNeededTransmissionField.defaultValue
            repairNeededBrakesField.value = repairNeededBrakesField.defaultValue
            repairNeededExhaustField.value = repairNeededExhaustField.defaultValue
            repairNeededElectricalField.value = repairNeededElectricalField.defaultValue
            repairNeededAirConditioningField.value = repairNeededAirConditioningField.defaultValue
            repairNeededO2SensorField.value = repairNeededO2SensorField.defaultValue
            repairNeededTirePressureSensorsField.value = repairNeededTirePressureSensorsField.defaultValue
            repairNeededSunroofField.value = repairNeededSunroofField.defaultValue
            repairNeededConvertibleField.value = repairNeededConvertibleField.defaultValue
            repairNeededOtherField.value = repairNeededOtherField.defaultValue
            repairNeededEngineRemovedField.value = repairNeededEngineRemovedField.defaultValue
            repairNeededTransmissionRemovedField.value = repairNeededTransmissionRemovedField.defaultValue

            dashboardLightsField.value = dashboardLightsField.defaultValue
            dashboardLightsCheckEngineField.value = dashboardLightsCheckEngineField.defaultValue
            dashboardLightsBatteryField.value = dashboardLightsBatteryField.defaultValue
            dashboardLightsBrakesField.value = dashboardLightsBrakesField.defaultValue
            dashboardLightsABSField.value = dashboardLightsABSField.defaultValue
            dashboardLightsAirbagField.value = dashboardLightsAirbagField.defaultValue
            dashboardLightsSuspensionField.value = dashboardLightsSuspensionField.defaultValue
            dashboardLightsTirePressureField.value = dashboardLightsTirePressureField.defaultValue
            dashboardLightsTractionControlField.value = dashboardLightsTractionControlField.defaultValue
            dashboardLightsOtherField.value = dashboardLightsOtherField.defaultValue

            originalOdometerField.value = originalOdometerField.defaultValue
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [repairNeededField.value])

    useEffect(() => {
        setSubmitEnabled(checkForm())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState])

    const submitHandler = async (event) => {
        event.preventDefault();
        if (validateForm(formState, setFormState, getExcludeFields())) {
            const data = getFormData(formState)

            data.xchangeData = {
                answers: prepareAnswersFromState(formData, data, (answersData, answers) => {
                    answers['runnable'] = 'yes'
                    answers['drivable'] = 'yes'

                    if (answersData['repairNeeded'] === 'yes') {
                        if (answersData['startsAndDrive'] === 'startsAndDrive') {
                            answers['runnable'] = 'yes'
                            answers['drivable'] = 'yes'
                        }
                        else if (answersData['startsAndDrive'] === 'startsNotDrive') {
                            answers['runnable'] = 'yes'
                            answers['drivable'] = 'no'
                        }
                        else if (answersData['startsAndDrive'] === 'notStart') {
                            answers['runnable'] = 'no'
                            answers['drivable'] = 'no'
                        }
                    }

                    return answers
                })
            }

            props.onNext(data)
        }
    }

    const heapTagClassNameOptions = {mainPrefix: HeapTagClassName, prefix: props.currentStep}

    return (
        <div className="mechanical-condition-wrapper">
            <Question
                id={generateTag(repairNeededField.key, {snippet: '{%option%}', suffix: 'btn'})}
                className={generateTag(repairNeededField.key, heapTagClassNameOptions)}
                field={repairNeededField} formState={formState} setFormState={setFormState}
                      isInOneLine
                      dependencyControls={getDependencyControls(repairNeededField, formState).concat([startsAndDriveField.key])}
            />
            {repairNeededField.value === "yes" &&
                <>
                    <Question
                        id={generateTag(startsAndDriveField.key, {snippet: '{%option%}', suffix: 'btn'})}
                        className={generateTag(startsAndDriveField.key, heapTagClassNameOptions)}
                        field={startsAndDriveField} formState={formState} setFormState={setFormState} />
                    <div className="subquestions">
                        <div className="question-title" style={{width: '100%'}}>What are the mechanical issues related to?</div>
                        <div className='question-subtitle'>Select all that apply</div>
                        <Answer
                            id={generateTag(repairNeededEngineField.key, {suffix: 'btn'})}
                            className={generateTag(repairNeededEngineField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={repairNeededEngineField} formState={formState}
                                setFormState={setFormState} dependencyControls={[repairNeededEngineRemovedField.key]}/>
                        <Answer
                            id={generateTag(repairNeededTransmissionField.key, {suffix: 'btn'})}
                            className={generateTag(repairNeededTransmissionField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={repairNeededTransmissionField} formState={formState}
                                setFormState={setFormState} dependencyControls={[repairNeededTransmissionRemovedField.key]}/>
                        <Answer
                            id={generateTag(repairNeededBrakesField.key, {suffix: 'btn'})}
                            className={generateTag(repairNeededBrakesField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={repairNeededBrakesField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(repairNeededExhaustField.key, {suffix: 'btn'})}
                                className={generateTag(repairNeededExhaustField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                            field={repairNeededExhaustField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer
                            id={generateTag(repairNeededElectricalField.key, {suffix: 'btn'})}
                            className={generateTag(repairNeededElectricalField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={repairNeededElectricalField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer
                            id={generateTag(repairNeededAirConditioningField.key, {suffix: 'btn'})}
                            className={generateTag(repairNeededAirConditioningField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={repairNeededAirConditioningField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer
                            id={generateTag(repairNeededO2SensorField.key, {suffix: 'btn'})}
                            className={generateTag(repairNeededO2SensorField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={repairNeededO2SensorField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer
                            id={generateTag(repairNeededTirePressureSensorsField.key, {suffix: 'btn'})}
                            className={generateTag(repairNeededTirePressureSensorsField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={repairNeededTirePressureSensorsField} formState={formState}
                            setFormState={setFormState}
                        />
                        <Answer
                            id={generateTag(repairNeededSunroofField.key, {suffix: 'btn'})}
                            className={generateTag(repairNeededSunroofField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={repairNeededSunroofField} formState={formState}
                            setFormState={setFormState}
                        />
                        <Answer
                            id={generateTag(repairNeededConvertibleField.key, {suffix: 'btn'})}
                            className={generateTag(repairNeededConvertibleField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={repairNeededConvertibleField} formState={formState}
                            setFormState={setFormState}
                        />
                        <Answer
                            id={generateTag(repairNeededOtherField.key, {suffix: 'btn'})}
                            className={generateTag(repairNeededOtherField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={repairNeededOtherField} formState={formState}
                                setFormState={setFormState}/>
                    </div>
                    {repairNeededEngineField.value &&
                        <Question
                            id={generateTag(repairNeededEngineRemovedField.key, {snippet: '{%option%}', suffix: 'btn'})}
                            className={generateTag(repairNeededEngineRemovedField.key, heapTagClassNameOptions)}
                            field={repairNeededEngineRemovedField} formState={formState} setFormState={setFormState}
                                  isInOneLine/>
                    }
                    {repairNeededTransmissionField.value &&
                        <Question
                            id={generateTag(repairNeededTransmissionRemovedField.key, {snippet: '{%option%}', suffix: 'btn'})}
                            className={generateTag(repairNeededTransmissionRemovedField.key, heapTagClassNameOptions)}
                            field={repairNeededTransmissionRemovedField} formState={formState} setFormState={setFormState}
                                  isInOneLine/>
                    }

                    <Question
                        id={generateTag(dashboardLightsField.key, {snippet: '{%option%}', suffix: 'btn'})}
                        className={generateTag(dashboardLightsField.key, heapTagClassNameOptions)}
                        field={dashboardLightsField} formState={formState} setFormState={setFormState}
                              isInOneLine
                              dependencyControls={getDependencyControls(dashboardLightsField, formState)}
                    />

                    {dashboardLightsField.value === "yes" &&
                        <>
                            <div className="subquestions">
                                <div className="question-title" style={{width: '100%'}}>What are the issues related to?</div>
                                <div className='question-subtitle'>Select all that apply</div>
                                <Answer
                                    id={generateTag(dashboardLightsCheckEngineField.key, {suffix: 'btn'})}
                                    className={generateTag(dashboardLightsCheckEngineField.key, {
                                        ...heapTagClassNameOptions,
                                        suffix: 'answers'
                                    })}
                                    field={dashboardLightsCheckEngineField} formState={formState}
                                        setFormState={setFormState}/>
                                <Answer
                                    id={generateTag(dashboardLightsBatteryField.key, {suffix: 'btn'})}
                                    className={generateTag(dashboardLightsBatteryField.key, {
                                        ...heapTagClassNameOptions,
                                        suffix: 'answers'
                                    })}
                                    field={dashboardLightsBatteryField} formState={formState}
                                        setFormState={setFormState}/>
                                <Answer            id={generateTag(dashboardLightsBrakesField.key, {suffix: 'btn'})}
                                                   className={generateTag(dashboardLightsBrakesField.key, {
                                                       ...heapTagClassNameOptions,
                                                       suffix: 'answers'
                                                   })}
                                    field={dashboardLightsBrakesField} formState={formState}
                                        setFormState={setFormState}/>
                                <Answer
                                    id={generateTag(dashboardLightsABSField.key, {suffix: 'btn'})}
                                    className={generateTag(dashboardLightsABSField.key, {
                                        ...heapTagClassNameOptions,
                                        suffix: 'answers'
                                    })}
                                    field={dashboardLightsABSField} formState={formState}
                                        setFormState={setFormState}/>
                                <Answer
                                    id={generateTag(dashboardLightsAirbagField.key, {suffix: 'btn'})}
                                    className={generateTag(dashboardLightsAirbagField.key, {
                                        ...heapTagClassNameOptions,
                                        suffix: 'answers'
                                    })}
                                    field={dashboardLightsAirbagField} formState={formState}
                                        setFormState={setFormState}/>
                                <Answer
                                    id={generateTag(dashboardLightsSuspensionField.key, {suffix: 'btn'})}
                                    className={generateTag(dashboardLightsSuspensionField.key, {
                                        ...heapTagClassNameOptions,
                                        suffix: 'answers'
                                    })}
                                    field={dashboardLightsSuspensionField} formState={formState}
                                        setFormState={setFormState}/>
                                <Answer
                                    id={generateTag(dashboardLightsTirePressureField.key, {suffix: 'btn'})}
                                    className={generateTag(dashboardLightsTirePressureField.key, {
                                        ...heapTagClassNameOptions,
                                        suffix: 'answers'
                                    })}
                                    field={dashboardLightsTirePressureField} formState={formState}
                                        setFormState={setFormState}/>
                                <Answer
                                    id={generateTag(dashboardLightsTractionControlField.key, {suffix: 'btn'})}
                                    className={generateTag(dashboardLightsTractionControlField.key, {
                                        ...heapTagClassNameOptions,
                                        suffix: 'answers'
                                    })}
                                    field={dashboardLightsTractionControlField} formState={formState}
                                    setFormState={setFormState}
                                />
                                <Answer
                                    id={generateTag(dashboardLightsOtherField.key, {suffix: 'btn'})}
                                    className={generateTag(dashboardLightsOtherField.key, {
                                        ...heapTagClassNameOptions,
                                        suffix: 'answers'
                                    })}
                                    field={dashboardLightsOtherField} formState={formState}
                                    setFormState={setFormState}
                                />

                            </div>
                        </>
                    }

                    <Question
                        id={generateTag(originalOdometerField.key, {snippet: '{%option%}', suffix: 'btn'})}
                        className={generateTag(originalOdometerField.key, heapTagClassNameOptions)}
                        field={originalOdometerField} formState={formState} setFormState={setFormState}
                              isInOneLine/>
                </>
            }


            <Question
                id={generateTag(anyLeaksField.key, {snippet: '{%option%}', suffix: 'btn'})}
                className={generateTag(anyLeaksField.key, heapTagClassNameOptions)}
                field={anyLeaksField} formState={formState} setFormState={setFormState} isInOneLine
                dependencyControls={getDependencyControls(anyLeaksField, formState)}
            />
            {anyLeaksField.value === "yes" &&
                <>
                    <div className="subquestions">
                        <div className="question-title" style={{width: '100%'}}>What is leaking?</div>
                        <div className='question-subtitle'>Select all that apply</div>
                        <Answer
                            id={generateTag(anyLeaksCoolantField.key, {suffix: 'btn'})}
                            className={generateTag(anyLeaksCoolantField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={anyLeaksCoolantField} formState={formState} setFormState={setFormState}/>
                        <Answer   id={generateTag(anyLeaksEngineOilField.key, {suffix: 'btn'})}
                                  className={generateTag(anyLeaksEngineOilField.key, {
                                      ...heapTagClassNameOptions,
                                      suffix: 'answers'
                                  })}
                            field={anyLeaksEngineOilField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer
                            id={generateTag(anyLeaksTransmissionFluidField.key, {suffix: 'btn'})}
                            className={generateTag(anyLeaksTransmissionFluidField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={anyLeaksTransmissionFluidField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer
                            id={generateTag(anyLeaksBrakeFluidField.key, {suffix: 'btn'})}
                            className={generateTag(anyLeaksBrakeFluidField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={anyLeaksBrakeFluidField} formState={formState}
                                setFormState={setFormState}/>
                    </div>
                </>
            }

            <Question
                id={generateTag(hasAftermarketPartsField.key, {snippet: '{%option%}', suffix: 'btn'})}
                className={generateTag(hasAftermarketPartsField.key, heapTagClassNameOptions)}
                field={hasAftermarketPartsField} formState={formState} setFormState={setFormState}
                      isInOneLine
                      dependencyControls={getDependencyControls(hasAftermarketPartsField, formState)}
            />
            {hasAftermarketPartsField.value === "yes" &&
                <>
                    <div className="subquestions">
                        <div className="question-title" style={{width: '100%'}}>Has any of the following happened to the vehicle?</div>
                        <div className='question-subtitle'>Select all that apply</div>
                        <Answer
                            id={generateTag(hasAftermarketPartsExhaustField.key, {suffix: 'btn'})}
                            className={generateTag(hasAftermarketPartsExhaustField.key, {
                                ...heapTagClassNameOptions,
                                suffix: 'answers'
                            })}
                            field={hasAftermarketPartsExhaustField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer   id={generateTag(hasAftermarketPartsWheelsField.key, {suffix: 'btn'})}
                                  className={generateTag(hasAftermarketPartsWheelsField.key, {
                                      ...heapTagClassNameOptions,
                                      suffix: 'answers'
                                  })}
                                  field={hasAftermarketPartsWheelsField} formState={formState}
                                setFormState={setFormState}/>
                        <Answer id={generateTag(hasAftermarketPartsEngineModificationsField.key, {suffix: 'btn'})}
                                className={generateTag(hasAftermarketPartsEngineModificationsField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                            field={hasAftermarketPartsEngineModificationsField} formState={formState}
                                setFormState={setFormState}
                        />
                        <Answer  id={generateTag(hasAftermarketPartsLiftedSuspensionField.key, {suffix: 'btn'})}
                                 className={generateTag(hasAftermarketPartsLiftedSuspensionField.key, {
                                     ...heapTagClassNameOptions,
                                     suffix: 'answers'
                                 })}
                            field={hasAftermarketPartsLiftedSuspensionField} formState={formState}
                                setFormState={setFormState}
                        />
                        <Answer id={generateTag(hasAftermarketPartsOtherField.key, {suffix: 'btn'})}
                                className={generateTag(hasAftermarketPartsOtherField.key, {
                                    ...heapTagClassNameOptions,
                                    suffix: 'answers'
                                })}
                            field={hasAftermarketPartsOtherField} formState={formState}
                                setFormState={setFormState}/>
                    </div>
                </>
            }
            <div className="save-button-wrapper">
                <CircularProgressButton
                    id={generateTag('continue', {prefix: props.currentStep, suffix: 'btn'})}
                    text="SAVE & CONTINUE"
                    isLoading={props.loading}
                    isDisabled={!isSubmitEnabled}
                    onClick={(event) => submitHandler(event)}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        vehicle: state.qualifyVehicle.vehicle,
        currentStep: state.qualifyVehicle.currentStep,
        loading: state.app.qualifyVehicle.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onNext: (data, cbOnError, cbOnSuccess) => dispatch(actions.saveQuestions(data, cbOnError, cbOnSuccess)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MechanicalCondition);