import * as actionTypes from './actionTypes'

export const setQueryParams = (data) => {
	return {
		type: actionTypes.SET_QUERY_PARAMS,
		data
	};
};

export const resetQueryParams = () => {
	return async (dispatch, getState) => {
		dispatch({data: null, type: actionTypes.RESET_QUERY_PARAMS})
	}
}