import './index.css';

import HeaderWithTitle from '../../../components/ui/HeaderWithTitle';
import Footer from '../../../components/ui/Footer';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import VehicleHelper from "../../../shared/VehicleHelper";
import AppCloseButton from '../../../components/ui/AppCloseButton';
import { Box } from '@mui/material'
import bestOfferRibbon from "../../../img/best-offer_ribbon.png";
import CarWithRibbon from '../../../components/ui/CarWithRibbon'


const OffersScreenRangeSuccess = (props) => {
    const [images, setImages] = useState([])
    useEffect(() => {
        const setData = async () => {
            setImages(VehicleHelper.getVehicleImages(props.vehicle.images, props.vehicle.exteriorColor))
        }
        if (props.vehicle.exteriorColor) {
            setData()?.catch(console.error)
        }
    }, [props.vehicle.exteriorColor])
    const imageUrl = images.length ? images[0]['@href']?.replace(/http/i, 'https') : null
    const carNameStyle = !imageUrl ? {marginTop: "30px"} : null
    return (
        <div className="offers-screen-range-success-wrapper">
            <HeaderWithTitle title="Your Estimate Is Here"/>
            {imageUrl && <CarWithRibbon imageUrl={imageUrl}/>}
            <div
                className='car-name'
                style={carNameStyle}
            >
                {[props.vehicle.model_year, props.vehicle.make, props.vehicle.model].join(' ')}
            </div>
            <div className="thank-you">Thank You!</div>
            <div className="text">A Carwiser Agent will reach out to you soon to help you get your offers.</div>
            <div className="save-button-wrapper">
                <AppCloseButton type="button"/>
            </div>
            <div className="footer-wrapper">
                <Footer/>
            </div>
        </div>
    );
}
const mapStateToProps = state => {
    return {
        vehicle: state.qualifyVehicle.vehicle,
        price: state.qualifyVehicle.price,
        loading: state.app.qualifyVehicle.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onNext: (data, cbOnError, cbOnSuccess) => dispatch(actions.saveQuestions(data, cbOnError, cbOnSuccess)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OffersScreenRangeSuccess);