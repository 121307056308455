import './index.css';
import Footer from '../../../components/ui/Footer'
import Logo from '../../../components/ui/Logo';
import Button from '../../../components/ui/Button';
import HeaderWithTitle from '../../../components/ui/HeaderWithTitle';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import { generateTag } from '../../../shared/utility'
import { Box } from '@mui/material'
import AppCloseButton from '../../../components/ui/AppCloseButton'

const NoMatchingClientsFound = (props) => {
    return (
        <div className="NoMatchingClientsFound-wrapper">
            <HeaderWithTitle/>
            <div className="logo-wrapper"><Logo/></div>
            <div className="title-wrapper">No Partners Found</div>
            <Box className="sub-wrapper">
                <div className="subtitle-wrapper">We were unable to find a buying partner for your vehicle at this time. This could be due to the vehicle’s details or your location.</div>
                <div className="subtitle-wrapper">Our network of buyers is constantly growing, we hope to provide a match in the future!</div>
            </Box>
            <Button
                id={generateTag('try-another-vehicle', {prefix: props.currentStep, suffix: 'btn'})}
                text="TRY ANOTHER VEHICLE"
                onClick={props.goToStart}
            />
            <div className="or-wrapper">– OR –</div>
            <AppCloseButton type="button" sx={{
                minWidth: '260px',
            }}/>
            <div className="footer-wrapper"><Footer/></div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        currentStep: state.qualifyVehicle.currentStep,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        goToStart: () => dispatch(actions.resetQualifyVehicle())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoMatchingClientsFound);