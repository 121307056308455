import * as actionTypes from './actionTypes';
import {cloneObject} from '../../shared/utility';
import BlackBookHelper from "../../shared/BlackbookHelper";
import FormStatHelper from "../../shared/FormStatHelper";
import {v4 as uuidv4} from 'uuid';
import ServiceCVD from '../../services/ServiceCVD'
import {OfferTypes, Scenario, Steps} from "../../constants";
import ServiceLongForm from "../../services/ServiceLongForm";
import {defaultColors} from "../../shared/defaultColors";
import ServiceWidget from "../../services/ServiceWidget";
import {clearAllItems, removeLocalStateForStep, updateSub2Item} from '../../hooks/useGlobalState'
import {OfferHelper} from '../../utils/offer_helper';
import {TimeMeasurer} from "../../shared/time.measurer";
import {delay} from "../../shared/DelayHelper";
import {resetGrowSurfToken} from './app';
import ServiceGrowSurf from '../../services/ServiceGrowSurf';
import {AffiliateProgram, PrimaryNamesType} from "../../shared/MatchingClientsHelper";
import {CarvanaAffiliateProgram} from '../../shared/CarvanaAffiliateProgram';
import {PeddleLowOffersLogic} from '../../shared/PeddleLowOffersLogic';
import {ZipFormatter} from '../../shared/formatter'

export const setQualifyVehicleData = (data) => {
	return {
		type: actionTypes.SET_QUALIFY_VEHICLE_QUESTIONS,
		data,
	};
};

export const resetQualifyVehicle = () => {
	return async (dispatch, getState) => {
		clearLocalStorage();
		dispatch({
			data: null,
			type: actionTypes.RESET_QUALIFY_VEHICLE_QUESTIONS,
		});
	};
};

export const backQualifyVehicle = () => {
	return async (dispatch, getState) => {
		const state = getState();
		const qualifyVehicle = cloneObject(state.qualifyVehicle);
		const currentStep = qualifyVehicle.currentStep;
		let allowRemoveLocalStateForStep = false;
		switch (qualifyVehicle.currentStep) {
			case Steps.Additional:
				qualifyVehicle.currentStep = Steps.Mechanical;
				allowRemoveLocalStateForStep = true;
				break;
			case Steps.Mechanical:
				qualifyVehicle.currentStep = Steps.Exterior;
				allowRemoveLocalStateForStep = true;
				break;
			case Steps.Exterior:
				qualifyVehicle.currentStep = Steps.History;
				allowRemoveLocalStateForStep = true;
				break;
			case Steps.History:
				qualifyVehicle.currentStep = Steps.EntryData;
				allowRemoveLocalStateForStep = true;
				break;
		}
		allowRemoveLocalStateForStep &&
			removeLocalStateForStep(currentStep, qualifyVehicle.currentStep);
		dispatch(setQualifyVehicleData(qualifyVehicle));
	};
};

export const backToStep = (step) => {
	return async (dispatch, getState) => {
		const state = getState();
		const qualifyVehicle = cloneObject(state.qualifyVehicle);
		qualifyVehicle.currentStep = qualifyVehicle.step = step;
		dispatch(setQualifyVehicleData(qualifyVehicle));
	};
};

export const getMakesByYear = (year) => {
	return async (dispatch, getState) => {
		const state = getState();
		const qualifyAppState = cloneObject(state.app.qualifyVehicle);
		const qualifyState = cloneObject(state.qualifyVehicle);

		qualifyAppState.yearLoading = true;
		qualifyAppState.error = false;
		qualifyState.makes = [];

		try {
			dispatch({
				data: {
					qualifyVehicle: {
						yearLoading: qualifyAppState.yearLoading,
						error: qualifyAppState.error,
					},
				},
				type: actionTypes.SET_APP_DATA,
			});

			const res = await ServiceLongForm.getMakesByYear(year);
			if (res) {
				qualifyState.makes = res;
			}

			dispatch(
				setQualifyVehicleData({
					makes: qualifyState.makes,
				}),
			);
		} catch (e) {
			qualifyAppState.error = e.message;
		}

		qualifyAppState.yearLoading = false;
		dispatch({
			data: {
				qualifyVehicle: {
					yearLoading: qualifyAppState.yearLoading,
					error: qualifyAppState.error,
				},
			},
			type: actionTypes.SET_APP_DATA,
		});
	};
};

export const getModels = (makeId, year) => {
	return async (dispatch, getState) => {
		const state = getState();
		const qualifyAppState = cloneObject(state.app.qualifyVehicle);
		const qualifyState = cloneObject(state.qualifyVehicle);

		qualifyAppState.makeLoading = true;
		qualifyAppState.error = false;
		qualifyState.models = [];

		try {
			dispatch({
				data: {
					qualifyVehicle: {
						makeLoading: qualifyAppState.makeLoading,
						error: qualifyAppState.error,
					},
				},
				type: actionTypes.SET_APP_DATA,
			});

			const res = await ServiceLongForm.getModels(year, makeId);
			if (res) {
				qualifyState.models = res;
			}

			dispatch(
				setQualifyVehicleData({
					models: qualifyState.models,
				}),
			);
		} catch (e) {
			qualifyAppState.error = e.message;
		}

		qualifyAppState.makeLoading = false;
		dispatch({
			data: {
				qualifyVehicle: {
					makeLoading: qualifyAppState.makeLoading,
					error: qualifyAppState.error,
				},
			},
			type: actionTypes.SET_APP_DATA,
		});
	};
};

export const getTrimsByMMY = (makeId, modelId, year) => {
	return async (dispatch, getState) => {
		const state = getState();
		const qualifyAppState = cloneObject(state.app.qualifyVehicle);
		const qualifyState = cloneObject(state.qualifyVehicle);

		qualifyAppState.trimsLoading = true;
		qualifyAppState.error = false;
		qualifyState.trims = [];

		try {
			dispatch({
				data: {
					qualifyVehicle: {
						trimsLoading: qualifyAppState.trimsLoading,
						error: qualifyAppState.error,
					},
				},
				type: actionTypes.SET_APP_DATA,
			});

			const make = qualifyState.makes.find(
				(el) => el.id.toString() === makeId.toString(),
			);
			const model = qualifyState.models.find(
				(el) => el.id.toString() === modelId.toString(),
			);

			const res = await ServiceLongForm.getStylesByYMM(
				year,
				make.id,
				model.name,
			);
			if (res) {
				qualifyState.trims = res;
			}

			dispatch(
				setQualifyVehicleData({
					trims: qualifyState.trims,
				}),
			);
		} catch (e) {
			qualifyAppState.error = e.message;
		}

		qualifyAppState.trimsLoading = false;
		dispatch({
			data: {
				qualifyVehicle: {
					trimsLoading: qualifyAppState.trimsLoading,
					error: qualifyAppState.error,
				},
			},
			type: actionTypes.SET_APP_DATA,
		});
	};
};

export const getStyles = (styles) => {
	return async (dispatch, getState) => {
		const state = getState();
		const qualifyAppState = cloneObject(state.app.qualifyVehicle);
		const qualifyState = cloneObject(state.qualifyVehicle);

		qualifyAppState.stylesLoading = true;
		qualifyAppState.error = false;
		qualifyState.trims = [];

		try {
			dispatch({
				data: {
					qualifyVehicle: {
						stylesLoading: qualifyAppState.stylesLoading,
						error: qualifyAppState.error,
					},
				},
				type: actionTypes.SET_APP_DATA,
			});

			qualifyState.styles = styles;

			dispatch(
				setQualifyVehicleData({
					styles: qualifyState.styles,
				}),
			);
		} catch (e) {
			qualifyAppState.error = e.message;
		}

		qualifyAppState.stylesLoading = false;
		dispatch({
			data: {
				qualifyVehicle: {
					stylesLoading: qualifyAppState.stylesLoading,
					error: qualifyAppState.error,
				},
			},
			type: actionTypes.SET_APP_DATA,
		});
	};
};

export const saveQuestions = (data, cbOnError, cbOnSuccess) => {
	return async (dispatch, getState) => {
		const measurer = TimeMeasurer.createAndStart();

		const state = getState();
		const appState = cloneObject(state.app);
		const qualifyAppState = cloneObject(state.app.qualifyVehicle);
		const qualifyState = cloneObject(state.qualifyVehicle);
		const queryParamsState = cloneObject(state.queryParams);

		qualifyAppState.loading = true;
		qualifyAppState.error = false;
		let shouldResetGrowSurfToken = false;

		dispatch({
			data: {
				qualifyVehicle: {
					loading: qualifyAppState.loading,
					error: qualifyAppState.error,
				},
			},
			type: actionTypes.SET_APP_DATA,
		});
		const { xchangeData, seller, images, ...vehicleData } = data;
		const step = qualifyState.currentStep;

		try {
			let tmpVehicle;
			switch (qualifyState.currentStep) {
				case Steps.History:
					qualifyState.vehicle = {
						...qualifyState.vehicle,
						...vehicleData,
					};
					if (images) {
						qualifyState.vehicle.images = images;
					}
					if (!qualifyState.vehicle.xchangeData)
						qualifyState.vehicle.xchangeData = { answers: {} };
					qualifyState.vehicle.xchangeData.answers = {
						...qualifyState.vehicle.xchangeData.answers,
						...xchangeData.answers,
					};

					qualifyState.currentStep = Steps.Exterior;
					break;
				case Steps.Exterior:
					qualifyState.vehicle.xchangeData.answers = {
						...qualifyState.vehicle.xchangeData.answers,
						...xchangeData.answers,
					};
					qualifyState.currentStep = Steps.Mechanical;
					break;
				case Steps.Mechanical:
					qualifyState.vehicle.xchangeData.answers = {
						...qualifyState.vehicle.xchangeData.answers,
						...xchangeData.answers,
					};
					qualifyState.currentStep = Steps.Additional;
					break;
				case Steps.Additional:
					dispatch({
						data: {
							qualifyVehicle: {
								loading: qualifyAppState.loading,
								error: qualifyAppState.error,
								retrievingOffers: true,
							},
						},
						type: actionTypes.SET_APP_DATA,
					});

					qualifyState.vehicle = {
						...qualifyState.vehicle,
						...vehicleData,
					};

					qualifyState.vehicle.xchangeData.answers = {
						...qualifyState.vehicle.xchangeData.answers,
						...xchangeData.answers,
					};

					const seatMaterial =
						qualifyState.vehicle.xchangeData.answers[
							'seatMaterial'
						] ??
						qualifyState.vehicle.trims.find(
							(el) => el.styleId === qualifyState.vehicle.styleId,
						)?.seatMaterial;
					qualifyState.vehicle.xchangeData.answers = {
						...qualifyState.vehicle.xchangeData.answers,
						seatMaterial,
					};

					qualifyState.seller = { ...qualifyState.seller, ...seller };

					const evaluationData = await ServiceLongForm.getPrice({
						...qualifyState.vehicle,
						zip: qualifyState.entryData.zip,
						mileage: qualifyState.entryData.mileage,
					});

					qualifyState.matchingClients =
						evaluationData.matchingClients.filter(
							(el) => el.disqualified === false,
						);

					// qualifyState.matchingClients = [];

					qualifyState.price = {
						min: evaluationData.vehicle.widgetPriceMin,
						max: evaluationData.vehicle.widgetPriceMax,
					};

					if (
						qualifyState.entryData.scenario ===
						Scenario.MakeModelYear
					) {
						if (qualifyState.price.min && qualifyState.price.max) {
							qualifyState.currentStep = Steps.OffersRange;
							qualifyState.step = Steps.OffersRange;
							break;
						} else {
							qualifyState.currentStep =
								Steps.NoRangeCouldBeCalculated;
							qualifyState.step = Steps.NoRangeCouldBeCalculated;
							break;
						}
					} else if (qualifyState.vehicle.vin) {
						const vehicle = cloneObject(qualifyState.vehicle);
						delete vehicle.images;
						const promises = [];
						const clients = [];
						const matchingClients = [];

						qualifyState.matchingClients.forEach((el) => {
							if (
								[
									PrimaryNamesType.Cargurus,
									PrimaryNamesType.Echopark,
									PrimaryNamesType.Carvana,
									PrimaryNamesType.Carmax,
									PrimaryNamesType.Vroom,
									PrimaryNamesType.SellYourCar,
									PrimaryNamesType.Peddle,
									PrimaryNamesType.Cashforcars,
									PrimaryNamesType.Vincue,
									PrimaryNamesType.NorthWestMotorsport,
									PrimaryNamesType.Givemethevin,
									PrimaryNamesType.WhipFlip,
								].includes(el.primaryName)
							) {
								if (!clients.includes(el.primaryName)) {
									clients.push(el.primaryName);
								}

								let result = {
									clientId: el.clientId,
									locationId: el.id,
									name: el.primaryName,
								};

								matchingClients.push(result);
							}
							if (el.offerType === 'InstantOfferConfigurator') {
								clients.push(el.primaryName);
								matchingClients.push({
									clientId: el.clientId,
									locationId: el.id,
									name: el.primaryName,
									type: 5,
								});
							}
						});

						let providersData;
						let offersData;

						if (clients.length) {
							const offersVehicleData = cloneObject(vehicle);
							prepareDamageAnswers(
								vehicle.xchangeData.answers,
								offersVehicleData.xchangeData.answers,
							);
							const msSessionData =
								queryParamsState.otherData &&
								queryParamsState.otherData.id &&
								queryParamsState.otherData.nonce
									? queryParamsState.otherData
									: null;
							promises.push(
								ServiceLongForm.getOffers({
									vehicle: {
										...offersVehicleData,
										mileage: qualifyState.entryData.mileage,
										licensePlate:
											qualifyState.entryData.licensePlate,
										licenseState:
											qualifyState.entryData.licenseState,
									},
									seller: { zip: qualifyState.entryData.zip },
									matchingClients: matchingClients,
									clients: clients,
									msSessionData: msSessionData,
								}),
							);

							promises.push(ServiceLongForm.getProviders());
							const results = await Promise.all(promises);
							offersData = results[0];
							providersData = results[1];
						} else {
							providersData =
								await ServiceLongForm.getProviders();
						}

						const iocMatchingClients =
							qualifyState.matchingClients?.filter((client) => {
								let isOk = false;
								if (
									client.offerType ===
									OfferTypes.InstantOfferConfigurator
								) {
									isOk = true;
									client.primaryName =
										client.name.toLowerCase();
								}
								return isOk;
							});
						if (iocMatchingClients.length) {
							const locationIds = iocMatchingClients.map(
								(client) => client.id,
							);
							const iocStores = await ServiceLongForm.getStores(
								locationIds,
							);
							const iocProvidersData =
								iocMatchingClients?.map((client) => {
									const iocDefaultProviderData =
										providersData.find(
											(el) =>
												el.key === PrimaryNamesType.IOC,
										);
									const iocStore = iocStores.find(
										(el) => +el.locationId === +client.id,
									);
									const iocProviderData = {
										...iocDefaultProviderData,
										key: client.primaryName,
										card: {
											...iocDefaultProviderData.card,
											logo: iocStore
												? iocStore.useCustomAdvancedSettings
													? iocStore.logo
													: iocStore.company.logo
												: null,
										},
									};
									return iocProviderData;
								}) || [];

							if (iocProvidersData.length) {
								providersData = [
									...providersData,
									...iocProvidersData,
								];
							}
						}

						qualifyState.providersData = providersData;

						qualifyState.matchingClients.forEach((client) => {
							client.status = 'open';
							client.offer = { price: 0 };
						});
						if (offersData?.offers) {
							for (let offersKey in offersData.offers) {
								const offer = offersData.offers[offersKey];
								qualifyState.matchingClients.forEach(
									(client) => {
										const isClientMatchOffer = client?.name?.replace(/\s/g, '')?.toLowerCase()?.startsWith(offersKey)
										if (!!isClientMatchOffer) {
											client.offer = offer;
											if (offer && offer?.isAcceptable === 0 && offer?.error?.code === 'no_offer') {
												client.status = 'disqualified'
											} else if (!offer || !offer.price) {
												client.status =
													offer?.isAcceptable === 0 && client.offerType  !== 'InstantOfferConfigurator'
                                                        ? 'disqualified'
														: 'open';
											} else {
												client.status =
													offer.isAcceptable === 1
														? 'offerMade'
														: 'disqualified';
											}
										}
									},
								);
							}
						}

						const offers = OfferHelper.getOffers(
							qualifyState.matchingClients,
						);
						const offerCount = offers ? offers.length : 0;

						if (offerCount === 0) {
							qualifyState.currentStep = Steps.AllDQd;
							qualifyState.step = Steps.AllDQd;
						} else {
							qualifyState.currentStep = Steps.Offers;
							qualifyState.step = Steps.Offers;
						}
					}

					if (qualifyState.matchingClients.length === 0) {
						qualifyState.currentStep = Steps.AllDQd;
						qualifyState.step = Steps.AllDQd;
						break;
					}

					// await PeddleLowOffersLogic.process(qualifyState);
					await CarvanaAffiliateProgram.process(qualifyState);

					const delayTime = 14000 - measurer.endAndGetResult();
					if (delayTime > 0) {
						await delay(delayTime);
					}
					break;
				case Steps.OffersRange:
					qualifyState.seller = { ...qualifyState.seller, ...seller };
					qualifyState.currentStep = Steps.OffersRangeThankYou;
					qualifyState.step = Steps.OffersRangeThankYou;
					qualifyState.seller = { ...qualifyState.seller, ...seller };
					tmpVehicle = cloneObject(qualifyState.vehicle);
					delete tmpVehicle.images;
					tmpVehicle.mileage = qualifyState.entryData.mileage;
					tmpVehicle.licensePlate =
						qualifyState.entryData.licensePlate;
					tmpVehicle.licenseState =
						qualifyState.entryData.licenseState;
					prepareDamageAnswers(
						qualifyState.vehicle.xchangeData.answers,
						tmpVehicle.xchangeData.answers,
					);
					if (state.app.growSurfToken) {
						tmpVehicle.growSurfToken = ServiceGrowSurf.token2String(
							state.app.growSurfToken,
						);
					}
					console.log('OffersRange', state.app.growSurfToken);

					qualifyState.matchingClients = cloneObject(
						qualifyState.matchingClients,
					).filter((el) => !el.isAffiliateProgram);
					await ServiceWidget.postData({
						seller: {
							name: [seller.firstName, seller.lastName].join(' '),
							zip: qualifyState.entryData.zip,
							phone: seller.phone,
							email: seller.email,
						},
						vehicle: tmpVehicle,
						extra: queryParamsState.otherData,
						matchingClients: qualifyState.matchingClients,
						formUUID: qualifyState.formUUID,
					});
					// await ServiceLongForm.onOfferRangeCalculated(qualifyState)

					break;
				case Steps.ExitNoClients:
					qualifyState.seller = { ...qualifyState.seller, ...seller };
					qualifyState.currentStep = Steps.ExitNoClientsThankYou;
					qualifyState.step = Steps.ExitNoClientsThankYou;
					await ServiceLongForm.onNoDealers(qualifyState);
					break;
				case Steps.Offers:
					qualifyState.currentStep = qualifyState.step =
						Steps.CreateAccount;
					if (data.providerId) {
						const provider = qualifyState.matchingClients.find(
							(el) => el.id === data.providerId,
						);
						if (provider) {
							provider.status = 'offerSelected';
						}
					}
					break;
				case Steps.CreateAccount:
					qualifyState.seller = { ...qualifyState.seller, ...seller };
					tmpVehicle = cloneObject(qualifyState.vehicle);
					delete tmpVehicle.images;
					tmpVehicle.mileage = qualifyState.entryData.mileage;
					tmpVehicle.licensePlate =
						qualifyState.entryData.licensePlate;
					tmpVehicle.licenseState =
						qualifyState.entryData.licenseState;
					prepareDamageAnswers(
						qualifyState.vehicle.xchangeData.answers,
						tmpVehicle.xchangeData.answers,
					);
					if (state.app.growSurfToken) {
						tmpVehicle.growSurfToken = ServiceGrowSurf.token2String(
							state.app.growSurfToken,
						);
					}

					qualifyState.matchingClients = cloneObject(
						qualifyState.matchingClients,
					).map((mc) => {
						const mcEdited = mc;
						delete mcEdited.offer.badges;
						return mcEdited;
					});

					qualifyState.matchingClients = cloneObject(
						qualifyState.matchingClients,
					).filter((el) => !el.isAffiliateProgram);
					const result = await ServiceWidget.postData({
						seller: {
							name: [seller.firstName, seller.lastName].join(' '),
							zip: qualifyState.entryData.zip,
							phone: seller.phone,
							email: qualifyState.seller.email,
							password: seller.password,
						},
						vehicle: tmpVehicle,
						extra: queryParamsState.otherData,
						matchingClients: qualifyState.matchingClients,
						formUUID: qualifyState.formUUID,
					});
					if (result.isExistingCarwiserAccount) {
						qualifyState.currentStep = Steps.AccountExistThankYou;
						qualifyState.step = Steps.AccountExistThankYou;
					} else {
						qualifyState.currentStep = Steps.CreateAccountThankYou;
						qualifyState.step = Steps.CreateAccountThankYou;
					}
					shouldResetGrowSurfToken = true;

					break;
				case Steps.NoRangeCouldBeCalculated:
					qualifyState.seller = { ...qualifyState.seller, ...seller };
					tmpVehicle = cloneObject(qualifyState.vehicle);
					delete tmpVehicle.images;
					tmpVehicle.mileage = qualifyState.entryData.mileage;
					prepareDamageAnswers(
						qualifyState.vehicle.xchangeData.answers,
						tmpVehicle.xchangeData.answers,
					);
					if (state.app.growSurfToken) {
						tmpVehicle.growSurfToken = ServiceGrowSurf.token2String(
							state.app.growSurfToken,
						);
					}

					console.log(
						'NoRangeCouldBeCalculated',
						state.app.growSurfToken,
					);
					await ServiceWidget.postData({
						seller: {
							name: [seller.firstName, seller.lastName].join(' '),
							zip: qualifyState.entryData.zip,
							phone: seller.phone,
							email: seller.email,
						},
						vehicle: tmpVehicle,
						extra: queryParamsState.otherData,
						formUUID: qualifyState.formUUID,
					});
					qualifyState.currentStep = Steps.ExitNoClientsThankYou;
					qualifyState.step = Steps.ExitNoClientsThankYou;
					break;
				default:
					throw Error('Unknown case');
			}

			const result = isStepGreater(
				qualifyState.currentStep,
				qualifyState.step,
			);
			if (result) {
				qualifyState.step = qualifyState.currentStep;
			}

			dispatch({
				data: {
					qualifyVehicle: {
						loading: qualifyAppState.loading,
						error: qualifyAppState.error,
					},
				},
				type: actionTypes.SET_APP_DATA,
			});

			if (
				qualifyState.seller &&
				qualifyState.seller.hasOwnProperty('password')
			) {
				delete qualifyState.seller.password;
				delete qualifyState.seller.repassword;
			}

			dispatch(setQualifyVehicleData(qualifyState));

			const stateData = cloneObject(qualifyState);
			delete stateData.vehicle.images;

			await FormStatHelper.submitFormData({
				formData: {
					vehicle: stateData.vehicle,
					matchingClients: stateData.matchingClients,
					extra: queryParamsState.otherData,
					entryData: qualifyState.entryData,
					seller: qualifyState.seller,
					step: qualifyState.step,
					growSurfToken: appState.growSurfToken,
				},
				formStepName: step,
				formUUID: qualifyState.formUUID,
				stepData: data,
			});
			if (shouldResetGrowSurfToken) {
				ServiceGrowSurf.create().resetReferrerId();
				updateSub2Item('globalState', 'app', undefined);
				dispatch(resetGrowSurfToken());
			}
		} catch (e) {
			console.log(e);
			qualifyAppState.error = e.message;
			if (cbOnError) cbOnError(e.message);
		}

		qualifyAppState.loading = false;
		dispatch({
			data: {
				qualifyVehicle: {
					loading: qualifyAppState.loading,
					error: qualifyAppState.error,
					retrievingOffers: false,
				},
			},
			type: actionTypes.SET_APP_DATA,
		});
	};
};

export const prepareAnswersFromState = (formData, answersData, cb) => {
    const answers= {}
    const questions = cloneObject(formData.controls)

    for (const key in questions) {
        if (questions[key]?.skip || questions[key]?.group === 'vehicle') {
            continue
        }
        if (questions[key]?.type === 'boolean') {
            answers[key] = answersData[key] ? true : false
        }
        else {
            answers[key] = answersData[key]
        }
    }

    const cbAnswers = cb(answersData, answers)

    if (answersData['tireDamageAll']) {
        answers['tireDamageRPS'] = true
        answers['tireDamageRDS'] = true
        answers['tireDamageFPS'] = true
        answers['tireDamageFDS'] = true
    }

    if (answersData['wheelDamageAll']) {
        answers['wheelDamageFDS'] = true
        answers['wheelDamageFPS'] = true
        answers['wheelDamageRDS'] = true
        answers['wheelDamageRPS'] = true
    }

    return {...answers, ...cbAnswers}
}

export const prepareDamageAnswers = (originalData, answers) => {
    if (originalData['noDamage']) {
        answers['undercarriageDamaged'] = false
        answers['fireDamage'] = false
        answers['floodDamaged'] = false
        answers['visibleDefectsHailDamage'] = false
    }
    else {
        if (answers['undercarriageDamaged'] && answers['undercarriageDamaged'] !== 'no') {
            answers['undercarriageDamaged'] = true
        }
        else {
            answers['undercarriageDamaged'] = false
        }
        if (answers['fireDamage'] && answers['fireDamage'] !== 'no') {
            answers['fireDamage'] = true
        }
        else {
            answers['fireDamage'] = false
        }
        if (answers['floodDamaged'] && answers['floodDamaged'] !== 'no') {
            answers['floodDamaged'] = true
        }
        else {
            answers['floodDamaged'] = false
        }
    }

    return answers
}

export const getVehicleData = (data, onError, onSuccess) => {
    return async (dispatch, getState) => {
        const state = getState()
        const appState = cloneObject(state.app);
        const qualifyAppState = cloneObject(state.app.qualifyVehicle)
        const qualifyState = cloneObject(state.qualifyVehicle)

		if (data.zip) {
			data.zip = ZipFormatter.format(data.zip);
		}

        let vehicle = {}
        let step = Steps.EntryData

        qualifyAppState.loading = true
        qualifyAppState.error = false

        dispatch({
            data: {qualifyVehicle: {loading: qualifyAppState.loading, error: qualifyAppState.error}},
            type: actionTypes.SET_APP_DATA
        })

        qualifyState.growSurfToken = appState.growSurfToken;
        qualifyState.entryData = cloneObject(data);

        qualifyState.step = step
        qualifyState.currentStep = step

        qualifyState.formUUID = uuidv4()
        qualifyState.vehicle = cloneObject(vehicle);
        qualifyState.matchingClients = []

        try {
            switch (data.scenario) {
                case Scenario.LicensePlate:
                    const blackBookRes = await BlackBookHelper.getVinByLicensePlate(data.licenseState, data.licensePlate)
                    if (blackBookRes.vin) {
                        data.vin = blackBookRes.vin
                        vehicle = await ServiceLongForm.getByVin(data.vin)
                        if (onSuccess) onSuccess(data.vin)
                    }
                    vehicle.validVin = true
                    break;
                case Scenario.Vin:
                    vehicle = await ServiceLongForm.getByVin(data.vin)
                    vehicle.validVin = true
                    break;
                case Scenario.MakeModelYear:
                    const selectedTrim = qualifyState.trims.find(el => el.name === data.trim)
                    if (selectedTrim) {
                        vehicle = await ServiceLongForm.getVehicleDescriptionByStyle(data.styleId)
                    }
                    vehicle.validVin = false
                    vehicle.vin = data.vin
                    break;
            }

            if (vehicle.trims.length === 1) {
                vehicle.styleId = vehicle.trims[0].styleId
                vehicle.trim = vehicle.trims[0].trim
            }

            for (const trim of vehicle.trims) {
                if (trim.exteriorColors.length === 0) {
                    trim.exteriorColors = defaultColors
                }
                if (trim.interiorColors.length === 0) {
                    trim.interiorColors = defaultColors
                }
            }

            if (vehicle.trims[0].styleId) {
                try {
                    const images = await ServiceLongForm.getImageByStyle(vehicle.trims[0].styleId)
                    vehicle.images = images
                } catch (e) {
                    console.log(e)
                    vehicle.images = null
                }
            }

            const matchingClientsResult = await ServiceLongForm.getMatchingClients({
                ...vehicle,
                zip: data.zip,
                mileage: data.mileage
            })

            qualifyState.vehicle = cloneObject(vehicle);

            step = Steps.History
            qualifyState.step = step
            qualifyState.currentStep = step

            qualifyState.matchingClients = matchingClientsResult ? matchingClientsResult.matchingClients : []
            if (!qualifyState.matchingClients.length) {
                step = Steps.ExitNoClients
                qualifyState.step = step
                qualifyState.currentStep = step

                await CarvanaAffiliateProgram.process(qualifyState)
            }
        } catch (e) {
            qualifyAppState.error = e.message
            if (onError) onError(e.message)
        }

        dispatch(setQualifyVehicleData(qualifyState))

        const stateData = {
            entryData: qualifyState.entryData,
            vehicle: qualifyState.vehicle,
            growSurfToken: qualifyState.growSurfToken,
            matchingClients: qualifyState.matchingClients,
            step: qualifyState.step,
            currentStep: qualifyState.currentStep,
            formUUID: qualifyState.formUUID,
        }

        try {
            delete stateData?.currentStep
            delete stateData?.vehicle?.images
            await FormStatHelper.submitFormData({
                formData: stateData,
                formStepName: Steps.EntryData,
                stepData: data,
                formUUID: qualifyState.formUUID,
            })
        } catch (e) {
            qualifyAppState.error = e.message
            console.log(e)

        }
        qualifyAppState.loading = false

        dispatch({
            data: {qualifyVehicle: {loading: qualifyAppState.loading, error: qualifyAppState.error}},
            type: actionTypes.SET_APP_DATA
        })
    }
}

export const getStyleDescription = (styleId) => {
    return async (dispatch, getState) => {
        const state = getState()
        const qualifyAppState = cloneObject(state.app.qualifyVehicle)
        const qualifyState = cloneObject(state.qualifyVehicle)

        qualifyAppState.makeLoading = true
        qualifyAppState.error = false

        try {
            const vehicle = await ServiceLongForm.getVehicleDescriptionByStyle(styleId)
            if (vehicle && vehicle.trims[0]) {
                const trim = vehicle.trims[0]
                trim.styleId = String(trim.styleId)
                qualifyState.vehicle.trims = qualifyState.vehicle.trims.map((el) => el.styleId === styleId ? trim : el)

                dispatch(setQualifyVehicleData({
                    vehicle: qualifyState.vehicle,
                }))
            }
        } catch (e) {
            qualifyAppState.error = e.message
        }

        qualifyAppState.makeLoading = false
        dispatch({
            data: {qualifyVehicle: {makeLoading: qualifyAppState.makeLoading, error: qualifyAppState.error}},
            type: actionTypes.SET_APP_DATA
        })
    }
}

const isStepGreater = (step1, step2) => {
    if (step2 === Steps.History && [Steps.Exterior, Steps.Mechanical, Steps.Additional].includes(step1))
        return true
    if (step2 === Steps.Exterior && [Steps.Mechanical, Steps.Additional].includes(step1))
        return true
    if (step2 === Steps.Mechanical && [Steps.Additional].includes(step1))
        return true
    return false
}

export const recoveryQualifyVehicleData = (data) => {
    return async (dispatch, getState) => {
        dispatch(setQualifyVehicleData(data))
    }
}

export const continueQualifyVehicle = () => {
    return async (dispatch, getState) => {
        const state = getState()

        const qualifyAppState = cloneObject(state.app.qualifyVehicle)
        const qualifyState = cloneObject(state.qualifyVehicle)

        dispatch(setQualifyVehicleData({
            ...qualifyState,
            currentStep: qualifyState.step,
        }))

        dispatch({
            data: qualifyAppState,
            type: actionTypes.SET_APP_DATA
        })
    }
}

export const clearLocalStorage = () => {
    clearAllItems()
}

export const saveQueryStringParamsToState = (params) => {
    return async (dispatch, getState) => {
        const state = getState()
        const qualifyAppState = cloneObject(state.app.qualifyVehicle)
        const qualifyState = cloneObject(state.qualifyVehicle)

        qualifyAppState.loading = true
        qualifyAppState.error = false

        dispatch({
            data: {qualifyVehicle: {loading: qualifyAppState.loading, error: qualifyAppState.error}},
            type: actionTypes.SET_APP_DATA
        })

        qualifyState.queryParams = params
        dispatch(setQualifyVehicleData({
            queryParams: qualifyState.queryParams,
        }))

        qualifyAppState.loading = false
        dispatch({
            data: {qualifyVehicle: {loading: qualifyAppState.loading, error: qualifyAppState.error}},
            type: actionTypes.SET_APP_DATA
        })
    }
}
