import {validateResponse} from "../shared/utility";
import widget from "../utils/transport/http/widget";
import {DATA_VERSION} from "../shared/dataVersion";


export default class ServiceWidget {
    static validateResponse(response) {
        return [200, 201].includes(response.status) && response.data && !response.data.error
    }

    static postData = async (data) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        let res
        data['dataVersion'] = DATA_VERSION
        try {
            res = await widget.post(`/vehicles`, data, {
                headers: headers,
            })
            if (validateResponse(res)) {
                return res.data
            } else {
                throw new Error('Account Creation Error')
            }
        } catch (e) {
            const message = e.response?.data?.message ?? 'We are unable to create an account for you'
            throw new Error(message);
        }
    }

}