import React, { useEffect } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import './index.css'
import MyVinModal from '../MyVinModal'
import InputField from '../InputField'
import DropDownField from '../DropDownField'
import { Scenario } from '../../../constants'
import { generateTag, inputChangedHandler } from '../../../shared/utility'

function LicenseOrVinSwitcher(props) {
	const {formState, setFormState} = props

	const licensePlateField = formState.controls.licensePlate
	const licenseStateField = formState.controls.licenseState
	const vinField = formState.controls.vin
	const mileageField = formState.controls.mileage
	const zipField = formState.controls.zip

	return (
		<div className="license-or-vin-switcher-wrapper">
			<ToggleButtonGroup color="primary" value={formState.controls.scenario.value} exclusive onChange={(element, value) => {
				if (value) {
					inputChangedHandler(formState, setFormState, value, 'scenario')
					// setFormState(prev => {
					//     return {...prev, controls: {...prev.controls, scenario: {...prev.controls.scenario, value}}}
					// })
				}
			}} className="toggleButton">
				<ToggleButton
					id={generateTag('license-plate', {suffix: 'btn'})}
					className="toggle-button"
					value={Scenario.LicensePlate}>
					LICENSE PLATE
				</ToggleButton>
				<ToggleButton
					id={generateTag('vin', {suffix: 'btn'})}
					className="toggle-button"
					value={Scenario.Vin}>
					VIN
				</ToggleButton>
			</ToggleButtonGroup>
			{formState.controls.scenario.value === Scenario.LicensePlate && (
				<>
					<div className="toggle-text">Use your License Plate to find your vehicle</div>
					<div className="form-wrapper">
						<div className={!licensePlateField.valid ? 'line-wrapper error' : 'line-wrapper'}>
							<InputField
								id={generateTag('license-plate', {suffix: 'field'})}
								field={licensePlateField} formState={formState} setFormState={setFormState} toUpperCase={true}
							/>
							<div className="DropDown-state-wrapper">
								<DropDownField
									id={generateTag('license-state', {suffix: 'field'})}
									field={licenseStateField} formState={formState} setFormState={setFormState}
								/>
							</div>
						</div>
						<InputField
							id={generateTag('mileage', {suffix: 'field'})}
							field={mileageField} formState={formState} setFormState={setFormState}
						/>
						<InputField
							id={generateTag('zip', {suffix: 'field'})}
							field={zipField} formState={formState} setFormState={setFormState}
						/>
					</div>
				</>
			)}
			{formState.controls.scenario.value === Scenario.Vin && (
				<>
					<div className="toggle-text">Use your VIN to get details about your car</div>
					<div className="MyVinModal-wrapper">
						<MyVinModal></MyVinModal>
					</div>
					<div className="form-wrapper">
						<InputField
							id={generateTag('vin', {suffix: 'field'})}
							field={vinField} formState={formState} setFormState={setFormState} toUpperCase={true}
						/>
						<InputField
							id={generateTag('mileage', {suffix: 'field'})}
							field={mileageField} formState={formState} setFormState={setFormState}
						/>
						<InputField
							id={generateTag('zip', {suffix: 'field'})}
							field={zipField} formState={formState} setFormState={setFormState}
						/>
					</div>
				</>
			)}
		</div>
	)
}

export default LicenseOrVinSwitcher