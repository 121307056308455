import validate from 'validate.js'
import _ from 'underscore'
import moment from "moment";
import { MainWrapperIdName } from '../constants'
const cloneDeep = require('lodash.clonedeep')

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const validateResponse = (response) => {
    return ([200, 201].includes(response.status) && !response.data.error) ? true : false
}
/* Function to validate value according to rules passed
returns null or validation error message
 */
export const checkValidity = (value, rules) => {
    if (!rules) {
        return null;
    }
    validate.extend(validate.validators.datetime, {
        // The value is guaranteed not to be null or undefined but otherwise it
        // could be anything.
        parse: function (value, options) {
            return +moment(value).utc();
        },
        format: function (value, options) {
            const format = options.format
            return moment(value).format(format);
        }
    });
    return validate.single(value, rules) ?? null;
}

const validateField = (formState, value, controlName) => {
    let errorMessage;
    if (!_.isEmpty(formState.controls[controlName].validation)) {
        if (typeof value === 'string') {
            value = value.trim()
        }

        if (value && controlName === 'vin') {
            const reg = new RegExp('[0-9]{5}$');
            if (!reg.test(value)) {
                errorMessage = [];
                errorMessage[0] = "is not valid"
                return errorMessage
            }
            if (formState.controls[controlName].existingVins && formState.controls[controlName].existingVins.includes(value.toLowerCase())) {
                errorMessage = [];
                errorMessage[0] = "already exists"
                return errorMessage
            }
        }

        if ((formState.controls[controlName].validation.email || formState.controls[controlName].validation.length || formState.controls[controlName].validation.numericality || formState.controls[controlName].validation.datetime) && (value === '' || value === null)) {
            if (!formState.controls[controlName].validation.presence || formState.controls[controlName].validation.presence.allowEmpty === true) {
                return
            }
        }

        if (formState.controls[controlName].requiredIf) {
            const fieldName = formState.controls[controlName].requiredIf
            if (formState.controls[fieldName].value) {
                formState.controls[controlName].validation.presence.allowEmpty = false
            } else {
                if (!value)
                    return
                formState.controls[controlName].validation.presence.allowEmpty = true
            }
        }

        const validationSingle = {...formState.controls[controlName].validation}
        'equality' in validationSingle && delete validationSingle.equality
        errorMessage = checkValidity(value, validationSingle)

        const validationEquality = formState.controls[controlName].validation.equality
        if ([undefined, null].includes(errorMessage) && validationEquality) {
            const compareToField = validationEquality
            const compareToFieldAttr = typeof compareToField === 'string' ? compareToField : compareToField.attribute;
            const constraints = {
                [controlName]: {
                    equality: compareToField
                }
            }
            errorMessage = validate({
                [controlName]: value,
                [compareToFieldAttr]: formState.controls[compareToFieldAttr].value
            }, constraints)

            if (errorMessage && errorMessage[controlName]) {
                errorMessage = errorMessage[controlName]
            }
        }
    }

    errorMessage = Array.isArray(errorMessage) ? errorMessage.filter(error => !['function'].includes(typeof error)).join(', ') : errorMessage

    return errorMessage
}

export const checkField = (inField, value, formState) => {
    const field = cloneObject(inField)

    if (field.hasOwnProperty('inputFilter')) {
        const regExp = new RegExp(field.inputFilter, 'g')
        value = value.replace(regExp, '')
    }

    let errorMessage = validateField(formState, value, field.key)

    field.value = value
    field.valid = !errorMessage
    field.touched = true

    if (errorMessage && field.hasOwnProperty('label')) {
        errorMessage = field.label + ' ' + errorMessage
    }

    field.helperText = errorMessage

    return field
}

export const inputChangedHandler = (formState, setFormState, value, controlName, dependencyControls) => {
    const updatedState = cloneObject(formState)

    if (Array.isArray(dependencyControls)) {
        dependencyControls.forEach((el) => {
            updatedState.controls[el].value = updatedState.controls[el]?.hasOwnProperty('defaultValue') ? updatedState.controls[el]['defaultValue'] : ''
            updatedState.controls[el].touched = false
            updatedState.controls[el].valid = true
            updatedState.controls[el].helperText = ''
        })
    }

    updatedState.controls[controlName] = checkField(updatedState.controls[controlName], value, formState)

    const isFormValid = Object.entries(updatedState.controls).every(
        element => element[1].valid
    );

    updatedState.valid = isFormValid;
    updatedState.touched = true;

    setFormState(prevFormState => {
        return {
            ...prevFormState,
            ...updatedState,
        }
    });
}

export const validateForm = (formState, setFormState, excludeFields = [], updateState = true) => {
    const updatedState = cloneObject(formState)
    for (let key in updatedState.controls) {
        if (excludeFields.find((item) => item === key)) {
            continue
        }
        const field = updatedState.controls[key]

        if (typeof field.value === 'string') {
            field.value = field.value.trim()
        }

        let errorMessage = validateField(formState, field.value, key)

        field.valid = !errorMessage
        field.touched = true

        if (errorMessage && field.hasOwnProperty('label')) {
            errorMessage = field.label + ' ' + errorMessage
        }

        field.helperText = errorMessage
    }
    const isFormValid = Object.entries(updatedState.controls).filter(element => !excludeFields.includes(element[0])).every(element => element[1].valid)
    updatedState.valid = isFormValid
    updatedState.touched = true
    if (updateState) {
        setFormState({...formState, ...updatedState})
    }
    return isFormValid
}

export const prepareFormData = (formData, state = null) => {
    const formDataUpdated = cloneObject(formData)
    for (let key in formDataUpdated.controls) {
        const field = formDataUpdated.controls[key]
        field.key = key
        if (state && state.hasOwnProperty(key)) {
            field.value = state[key] ?? null
            if (!field.value && field.hasOwnProperty('defaultValue')) {
                field.value = field.defaultValue
            }
            if (field.value) {
                field.valid = true
            }
            field.touched = field.value ? true : false
        }
        else {
            field.touched = false
        }
        field.helperText = null
    }
    return formDataUpdated
}

export const setErrorForField = (formState , key, errorMsg) => {
    const formDataUpdated = cloneObject(formState)
    const field = formDataUpdated.controls[key]
    field.helperText = errorMsg
    field.valid = false

    return formDataUpdated;
}

export const setFieldValue = (formState , key, value) => {
    const formDataUpdated = cloneObject(formState)
    const field = formDataUpdated.controls[key]
    field.value = value

    let errorMessage = validateField(formState, field.value, key);

    field.valid = !errorMessage;
    field.touched = true;

    if (errorMessage && field.hasOwnProperty('label')) {
        errorMessage = field.label + ' ' + errorMessage
    }

    field.helperText = errorMessage;
    return formDataUpdated;
}

export const getFormData = (formData) => {
    const data = {};
    for (let key in formData.controls) {
        data[key] = formData.controls[key].value;
    }
    return data;
}

export const cloneObject = (obj) => {
    return cloneDeep(obj);
}

export const getQueryStringParams = () => {
    const url = new URL(window.location.href)
    const queryString = new Map()

    let search = url.search
    if (search.length && search[0] === '?') {
        search = search.slice(1, search.length)
    }

    const pairs = search.split('&')
    for (let pair of pairs) {
        let [name, value] = pair.split('=')
        if (!name || !value) {
            continue
        }
        queryString.set(name, value)
    }

    const carData = {}
    const sellerData = {}
    const otherData = {}

    const carDataRegExp = new RegExp('carData_([a-zA-Z]+)$')
    const sellerDataRegExp = new RegExp('sellerData_([a-zA-Z]+)$')
    const otherDataRegExp = new RegExp('otherData_([a-zA-Z]+)$')

    for (let key of queryString.keys()) {
        const carDataKey = carDataRegExp.exec(key)
        if (carDataKey && carDataKey[1] && queryString.has(key)) {
            const newKey = carDataKey[1]
            const rawNewValue = queryString.get(key)
            const newValue = rawNewValue
            carData[newKey] = newValue
        }
        const sellerDataKey = sellerDataRegExp.exec(key)
        if (sellerDataKey && sellerDataKey[1] && queryString.has(key)) {
            const newKey = sellerDataKey[1]
            const rawNewValue = queryString.get(key)
            const newValue = rawNewValue
            sellerData[newKey] = newValue
        }
        const otherDataKey = otherDataRegExp.exec(key)
        if (otherDataKey && otherDataKey[1] && queryString.has(key)) {
            const newKey = otherDataKey[1]
            const rawNewValue = queryString.get(key)
            const newValue = rawNewValue
            otherData[newKey] = newValue
        }
    }

    return {carData, sellerData, otherData}
}

export const getExcludeFields = (formState, scenario) => {
    const excludeFields = []
    for (let controlsKey in formState.controls) {
        const control = formState.controls[controlsKey]
        if(!control.includeFilter.includes(scenario)) {
            excludeFields.push(control.key)
        }
    }
    return excludeFields
}

export const isScenarioValid = (formState, scenario) => {
    for (let controlsKey in formState.controls) {
        const control = formState.controls[controlsKey]
        if(control.includeFilter.includes(scenario)) {
            if(!control.touched || !control.valid)
                return false
        }
    }
    return true
}

export const getDependencyControls = (field, formState) => {
    const dependencyControls = []
    if(!field.groupValidation) return dependencyControls
    for (let controlsKey in formState.controls) {
        const controlField =  formState.controls[controlsKey]
        if(controlField.group === field.groupValidation) {
            dependencyControls.push(controlField.key)
        }
    }
    return dependencyControls
}

export const phoneFormatter = (phoneNumber, regex = /(\d{3})(\d{3})(\d{4})/giu, replaceValue = "($1) $2-$3") => {
    return phoneNumber.replace(regex, replaceValue)
}

export const camelToSnakeCase = (inString) => {
    return inString
    .replaceAll(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
}

export const sanitizeString = (inString) => {
    return inString
        .replaceAll(/\s\-\s/gi, '-')
        .replaceAll(/\//gi, '-')
        .replaceAll(/[^\w\s\-]/gi, '')
        .replaceAll(/\s+/gi, '-')
        .toLowerCase()
}

export const generateTag = (name, options = null) => {
    const mainPrefix = options?.mainPrefix ? `${options.mainPrefix}` : MainWrapperIdName
    const prefix = options?.prefix ? `-${camelToSnakeCase(options.prefix)}` : ''

    const tag = `-${sanitizeString(camelToSnakeCase(name))}`

    const snippet = options?.snippet ? `-${options.snippet}` : ''
    const suffix = options?.suffix ? `-${options.suffix}` : ''

    return `${mainPrefix}${prefix}${tag}${snippet}${suffix}`.toLowerCase()
}