import './index.css';
import { WidgetComponent } from './utils/widget/WidgetComponent';

let widget;

export const init = (cfg) => {
  if (!widget) {
    widget = new WidgetComponent();
    widget.init(cfg);
  } else {
    throw new Error('Widget is already inited');
  }
}

export const close = () => {
  widget && widget.close();
}

export const open = () => {
  widget && widget.open();
}