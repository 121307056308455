import './index.css';
import Footer from '../../components/ui/Footer'
import Logo from '../../components/ui/Logo';
import Header from '../../components/ui/Header';
import CircularProgressButton from '../../components/ui/CircularProgress';
import VinOrYearSwitcher from '../../components/ui/VinOrYearSwitcher';
import LicenseOrVinSwitcher from '../../components/ui/LicenseOrVinSwitcher';
import { validationMileage, validationVin } from '../../shared/validationRules'
import {
    checkField,
    cloneObject, generateTag,
    getExcludeFields,
    getFormData,
    inputChangedHandler,
    isScenarioValid,
    prepareFormData,
    setErrorForField,
    setFieldValue,
    validateForm,
} from '../../shared/utility'
import React, { useEffect, useState } from 'react'
import { MainWrapperIdName, Scenario, States, Steps } from '../../constants'
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import DropDownField from "../../components/ui/DropDownField";
import {getYears} from "../../shared/YearHelper";
import InputField from "../../components/ui/InputField";
import HeaderWithTitle from "../../components/ui/HeaderWithTitle";
import { getAppEventHandlers, getAppWidgetProps } from '../../utils/transport/http/widget';
import useLocalState from '../../hooks/useLocalState'

let formData = {
    controls: {
        scenario: {
            value: Scenario.LicensePlate,
            valid: true,
            touched: false,
            includeFilter: [Scenario.Vin, Scenario.LicensePlate, Scenario.MakeModelYear]
        },
        vin: {
            label: 'VIN',
            value: '',
            validation: validationVin,
            inputFilter: '[^A-Za-z0-9]',
            valid: true,
            touched: false,
            helperText: null,
            includeFilter: [Scenario.Vin]
        },
        year: {
            label: 'Year',
            value: '',
            values: getYears(),
            validation: {presence: {allowEmpty: false}},
            valid: true,
            touched: false,
            helperText: null,
            includeFilter: [Scenario.MakeModelYear]
        },
        make: {
            label: 'Make',
            value: '',
            validation: {presence: {allowEmpty: false}},
            valid: true,
            touched: false,
            helperText: null,
            includeFilter: [Scenario.MakeModelYear]
        },
        model: {
            label: 'Model',
            value: '',
            validation: {presence: {allowEmpty: false}},
            valid: true,
            touched: false,
            helperText: null,
            includeFilter: [Scenario.MakeModelYear]
        },
        trim: {
            label: 'Trim',
            value: '',
            validation: {presence: {allowEmpty: false}},
            valid: true,
            touched: false,
            helperText: null,
            includeFilter: [Scenario.MakeModelYear]
        },
        styleId: {
            label: 'Style',
            value: '',
            validation: {presence: {allowEmpty: false}},
            valid: true,
            touched: false,
            helperText: null,
            includeFilter: [Scenario.MakeModelYear]
        },
        licensePlate: {
            label: window.innerWidth > 395 ? 'License Plate' : 'Plate #',
            value: '',
            validation: {presence: {allowEmpty: false}, length: {maximum: 7, minimum: 5}},
            valid: true,
            touched: false,
            helperText: null,
            includeFilter: [Scenario.LicensePlate]
        },
        licenseState: {
            label: 'State',
            value: '',
            values: States,
            validation: {presence: {allowEmpty: false}},
            valid: true,
            touched: false,
            helperText: null,
            includeFilter: [Scenario.LicensePlate]
        },
        mileage: {
            label: 'Current Mileage',
            value: '',
            validation: validationMileage,
            valid: true,
            touched: false,
            helperText: null,
            includeFilter: [Scenario.Vin, Scenario.LicensePlate, Scenario.MakeModelYear]
        },
        zip: {
            label: 'Zip Code',
            value: '',
            validation: {presence: {allowEmpty: false}, length: {maximum: 5}},
            inputFilter: '[^0-9]',
            valid: true,
            touched: false,
            helperText: null,
            includeFilter: [Scenario.Vin, Scenario.LicensePlate, Scenario.MakeModelYear]
        },
    },
    loading: false,
    valid: false,
    touched: false,
    generalError: null
}

function isExistCarDataQueryParams(params) {
    return params?.carData && Object.keys(params.carData).length !== 0
}

function SecondBlock(props) {
    const yearField = props.formState.controls.year;
    const makeField = props.formState.controls.make
    const modelField = props.formState.controls.model
    const trimField = props.formState.controls.trim
    const styleIdField = props.formState.controls.styleId
    const mileageField = props.formState.controls.mileage
    const zipField = props.formState.controls.zip

    const queryParams = props.queryParams

    useEffect(() => {
        // console.log('props.qualifyVehicle.years')
        if (!isExistCarDataQueryParams(queryParams)) {
            return
        }

        for (let item of yearField.values) {
            const runChange =
                Number(item.id) === Number(queryParams.carData.year)
                // || Number(item.id) === Number(props.formState.controls.year.value)
            if (runChange) {
                const year = String(item.id);
                onChangeYear(year)
                break
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.qualifyVehicle.years])

    useEffect(() => {
        // console.log('props.qualifyVehicle.years')
        if (!isExistCarDataQueryParams(queryParams)) {
            return
        }

        for (let item of props.qualifyVehicle.makes) {
            const runChange =
                String(item.name).replace(/\s/g, '').toLowerCase() === String(queryParams.carData.make).replace(/\s/g, '').toLowerCase()
                // || Number(item.id) === Number(props.formState.controls.make.value)
            if (runChange) {
                const make = String(item.id);
                onChangeMake(make)
                break
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.qualifyVehicle.makes])

    useEffect(() => {
        // console.log('props.qualifyVehicle.models')
        if (!isExistCarDataQueryParams(queryParams)) {
            return
        }

        for (let item of props.qualifyVehicle.models) {
            const runChange =
                String(item.name).replace(/\s/g, '').toLowerCase() === String(queryParams.carData.model).replace(/\s/g, '').toLowerCase()
                // || Number(item.id) === Number(props.formState.controls.model.value)
            if (runChange) {
                const model = String(item.id);
                onChangeModel(model)
                break
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.qualifyVehicle.models])

    useEffect(() => {
        // console.log('props.qualifyVehicle.trims')
        if (!isExistCarDataQueryParams(queryParams)) {
            return
        }

        for (let item of props.qualifyVehicle.trims) {
            const runChange =
                String(item.name).replace(/\s/g, '').toLowerCase() === String(queryParams.carData.trim).replace(/\s/g, '').toLowerCase()
                // || String(item.id).toLowerCase() === String(props.formState.controls.trim.value).toLowerCase()
            if (runChange) {
                const trim = String(item.id);
                onChangeTrim(trim)
                break
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.qualifyVehicle.trims])

    useEffect(() => {
        // console.log('props.qualifyVehicle.styles')
        if (!isExistCarDataQueryParams(queryParams)) {
            return
        }

        for (let item of props.qualifyVehicle.styles) {
            const runChange =
                String(item.name).replace(/\s/g, '').toLowerCase() === String(queryParams.carData.style).replace(/\s/g, '').toLowerCase()
                // || Number(item.id) === Number(props.formState.controls.styleId.value)
            if (runChange) {
                const style = String(item.id);
                onChangeStyle(style)
                break
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.qualifyVehicle.styles])

    const onChangeYear = (value) => {
        inputChangedHandler(props.formState, props.setFormState, value, yearField.key, [makeField.key, modelField.key, trimField.key, styleIdField.key]);
        if (value) {
            props.onGetMakesByYear(value);
        }
    }

    const onChangeMake = (value) => {
        inputChangedHandler(props.formState, props.setFormState, value.toString(), makeField.key, [modelField.key, trimField.key, styleIdField.key])
        if (value && yearField.value) {
            props.onGetModels(value, yearField.value)
        }
    }

    const onChangeModel = (value) => {
        inputChangedHandler(props.formState, props.setFormState, value.toString(), modelField.key, [trimField.key, styleIdField.key])
        if (value && yearField.value && makeField.value) {
            props.onGetTrims(makeField.value, value, yearField.value)
        }
    }

    const onChangeTrim = (value) => {
        inputChangedHandler(props.formState, props.setFormState, value.toString(), trimField.key, [styleIdField.key])
        if (value) {
            const trim = props.qualifyVehicle.trims.find(el => el.id === value)
            const trimStyles = trim ? trim.children : []
            props.onGetStyles(trimStyles)
        }
    }

    const onChangeStyle = (value) => {
        inputChangedHandler(props.formState, props.setFormState, value.toString(), styleIdField.key)
    }

    useEffect(() => {
        if (props.qualifyVehicle.trims.length === 1) {
            const value = props.qualifyVehicle.trims[0].id
            onChangeTrim(value)
        }
    }, [props.qualifyVehicle.trims])

    useEffect(() => {
        if (props.qualifyVehicle.styles.length === 1) {
            const value = props.qualifyVehicle.styles[0].id
            onChangeStyle(value)
        }
    }, [props.qualifyVehicle.styles])

    return (
        <div>
            <DropDownField id={`${MainWrapperIdName}-year-field`} field={yearField} formState={props.formState} setFormState={props.setFormState}
                           onChange={event => onChangeYear(event.target.value)}/>
            <DropDownField id={`${MainWrapperIdName}-make-field`} field={makeField} formState={props.formState} setFormState={props.setFormState}
                           items={props.qualifyVehicle.makes} onChange={event => onChangeMake(event.target.value)}
                           isDisabled={yearField.value == ''} />
            <DropDownField id={`${MainWrapperIdName}-model-field`} field={modelField} formState={props.formState} setFormState={props.setFormState}
                           items={props.qualifyVehicle.models} onChange={event => onChangeModel(event.target.value)}
                           isDisabled={makeField.value == ''} />
            <DropDownField id={`${MainWrapperIdName}-trim-field`} field={trimField} formState={props.formState} setFormState={props.setFormState}
                           items={props.qualifyVehicle.trims} onChange={event => onChangeTrim(event.target.value)}
                           isDisabled={modelField.value == ''} />
            <DropDownField id={`${MainWrapperIdName}-styleid-field`} field={styleIdField} formState={props.formState} setFormState={props.setFormState}
                           items={props.qualifyVehicle.styles}
                           isDisabled={trimField.value == ''} />
            <InputField id={`${MainWrapperIdName}-mileage-field`} field={mileageField} formState={props.formState} setFormState={props.setFormState} />
            <InputField id={`${MainWrapperIdName}-zip-field`} field={zipField} formState={props.formState} setFormState={props.setFormState} />
        </div>
    )
}

const Home = (props) => {
    const initialData = prepareFormData(formData, {scenario: formData.controls.scenario.value})
    const [formState, setFormState] = useState(initialData);
    // const [formState, setFormState] = useLocalState(Steps.EntryData, initialData);

    const appEventHandlers = getAppEventHandlers();
    useEffect(() => {
        if (appEventHandlers.onTheFirstStepOpen) {
            appEventHandlers.onTheFirstStepOpen();
        }
    }, [appEventHandlers, appEventHandlers.onTheFirstStepOpen]);
    const appWidgetProps = getAppWidgetProps();

    useEffect(() => {
        if (!props.queryParams || !props.queryParams.carData) {
            return
        }

        const qParamsCarData = props.queryParams.carData
        const updatedFormState = cloneObject(formState)

        if (qParamsCarData.licensePlate) {
            updatedFormState.controls.licensePlate = checkField(
                updatedFormState.controls.licensePlate,
                String(qParamsCarData.licensePlate),
                updatedFormState
            )
        }
        if (qParamsCarData.licenseState) {
            updatedFormState.controls.licenseState = checkField(
                updatedFormState.controls.licenseState,
                String(qParamsCarData.licenseState).toUpperCase(),
                updatedFormState
            )
        }

        if (qParamsCarData.vin) {
            updatedFormState.controls.vin = checkField(
                updatedFormState.controls.vin,
                String(qParamsCarData.vin).toUpperCase(),
                updatedFormState
            )
        }

        if (qParamsCarData.mileage) {
            updatedFormState.controls.mileage = checkField(
                updatedFormState.controls.mileage,
                String(qParamsCarData.mileage),
                updatedFormState
            )
        }
        if (qParamsCarData.zip) {
            updatedFormState.controls.zip = checkField(
                updatedFormState.controls.zip,
                String(qParamsCarData.zip),
                updatedFormState
            )
        }

        if (qParamsCarData.year) {
            updatedFormState.controls.year = checkField(
                updatedFormState.controls.year,
                String(qParamsCarData.year),
                updatedFormState
            )
        }
        if (qParamsCarData.year && qParamsCarData.make) {
            updatedFormState.controls.make = checkField(
                updatedFormState.controls.make,
                String(qParamsCarData.make),
                updatedFormState
            )
        }
        if (qParamsCarData.year && qParamsCarData.make && qParamsCarData.model) {
            updatedFormState.controls.model = checkField(
                updatedFormState.controls.model,
                String(qParamsCarData.model),
                updatedFormState
            )
        }

        if (qParamsCarData.vin) {
            updatedFormState.controls.scenario = checkField(
                updatedFormState.controls.scenario,
                String(Scenario.Vin),
                updatedFormState
            )
        } else
        if (qParamsCarData.year) {
            updatedFormState.controls.scenario = checkField(
                updatedFormState.controls.scenario,
                String(Scenario.MakeModelYear),
                updatedFormState
            )
        } else {
            updatedFormState.controls.scenario = checkField(
                updatedFormState.controls.scenario,
                String(Scenario.LicensePlate),
                updatedFormState
            )
        }

        setFormState(prevFormState => {
            return {
                ...prevFormState,
                ...updatedFormState,
            }
        })
    }, [props.queryParams])

    const submitHandler = async (event) => {
        event.preventDefault();
        const excludedFields = getExcludeFields(formState, formState.controls.scenario.value)
        if (validateForm(formState, setFormState, excludedFields)) {
            const data = getFormData(formState)
            data.scenario = formState.controls.scenario.value
            let onError
            let onSuccess
            if (formState.controls.scenario.value === Scenario.Vin) {
                onError = (errorMsg) => {
                    const error = (
                        <span>
                            We couldn’t find your vehicle. Please try again or
                            <span
                                style={{
                                    color: '#2BACE2',
                                    fontWeight: 700,
                                    cursor: 'pointer'
                                }}
                                onClick={() => inputChangedHandler(formState, setFormState, Scenario.MakeModelYear, 'scenario')}
                                key={'error'}
                            >
                                &nbsp;Get An Estimate&nbsp;
                            </span>
                            using your Year, Make, & Model instead.
                        </span>
                    )
                    const updatedFromState = setErrorForField(formState, 'vin', error)
                    setFormState(updatedFromState)
                }
            }

            if (formState.controls.scenario.value === Scenario.LicensePlate) {
                onError = (errorMsg) => {
                    const error = 'We couldn’t find your vehicle. Please try again or enter your VIN instead.'
                    const updatedFormState = setErrorForField(formState, 'licensePlate', error)
                    setFormState(updatedFormState)
                }

                onSuccess = (value) => {
                    const updatedFormState = setFieldValue(formState, 'vin', value)
                    setFormState(updatedFormState)
                }
            }

            props.onNext(data, onError, onSuccess)
        }
    }

    const homeHeaderWrapper = appWidgetProps.hideMainLogoOnTheFirstStep ? null : (
        <React.Fragment>
            <div className="logo-wrapper">
                <Logo/>
            </div>
            <Header/>
        </React.Fragment>
    );

    return (
        <div className="home-wrapper">
            <HeaderWithTitle />
            <div className="home-header-wrapper">
                {homeHeaderWrapper}
            </div>
            <VinOrYearSwitcher
                formState={formState}
                setFormState={setFormState}
                firstBlock={
                    <LicenseOrVinSwitcher
                        formState={formState}
                        setFormState={setFormState}
                    />
                }
                secondBlock={
                    <SecondBlock
                        formState={formState}
                        setFormState={setFormState}
                        {...props}
                    />
                }
            />
            <CircularProgressButton
                id={generateTag(formState.controls.scenario.value, {prefix: 'continue', suffix: 'btn'})}
                text="Continue"
                isDisabled={!isScenarioValid(formState, formState.controls.scenario.value)}
                isLoading={props.loading}
                onClick={(event) => submitHandler(event)}
            />
            <div className="footer-wrapper">
                <Footer/>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        qualifyVehicle: state.qualifyVehicle,
        queryParams: state.queryParams,
        loading: state.app.qualifyVehicle.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onNext: (data, cbOnError, cbOnSuccess) => dispatch(actions.getVehicleData(data, cbOnError, cbOnSuccess)),
        onGetMakesByYear: (year) => dispatch(actions.getMakesByYear(year)),
        onGetModels: (makeId, year) => dispatch(actions.getModels(makeId, year)),
        onGetTrims: (makeId, modelId, year) => dispatch(actions.getTrimsByMMY(makeId, modelId, year)),
        onGetStyles: (styles) => dispatch(actions.getStyles(styles)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
